import React from "react";
import CongratulationsMain from "../../components/CongratulationsMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";

const CongratulationsPage = () => {
  return (
    <React.Fragment>
      <Header />
      <CongratulationsMain />
      <Footer />
    </React.Fragment>
  );
};

export default CongratulationsPage;
