import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  actionAddOrganisationApiCall,
  actionGetOrganisationDetailApiCall,
  actionGetSuggestedAutoCitiesApiCall,
} from "../../Redux/Actions";
import MainLayout from "../layouts/MainLayout";
import classes from "./addorganisationmain.module.scss";
import FileUpload from "../../assets/icons/FileUploadIcon.svg";
import Validate from "../../utils/Validation";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";

const AddOrganisationMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [organisationDetails, setOrganisationDetails] = useState({
    name: "",
    address: "",
    postcode: "",
    city: "",
    website: "",
    phone: "",
    primary_contact: "",
    email: "",
    shortDescription: "",
    streetname: "",
    number: "",
    addition: "",
    zipcode_id: "",
  });
  const [file, setFile] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [bannerFile, setBannerFile] = useState();
  const [bannerImageUrl, setBannerImageUrl] = useState();
  const [error, setError] = useState();
  const loading = useSelector((state) => state.CommonReducer.loading);
  const getSuggestedInfo = useSelector((state) => state.CommonReducer.cityInfo);
  const [description, setDescription] = useState("");
  const organisationDetail = useSelector(
    (state) => state.CommonReducer.organisationDetail
  );

  useEffect(() => {
    if (params && params.id) {
      let data = {
        id: params.id,
      };
      let body = { data, navigate, title: "viewOrg" };
      dispatch(actionGetOrganisationDetailApiCall(body));
    }
  }, [params]);

  useEffect(() => {
    if (params.id) {
      const orgData = {
        name: organisationDetail.name,
        address: organisationDetail.address,
        postcode: organisationDetail.postcode,
        city: organisationDetail.city,
        website: organisationDetail.website,
        phone: organisationDetail.phone,
        primary_contact: organisationDetail.primary_contact,
        email: organisationDetail.email,
        shortDescription: organisationDetail.short_description,
        number:
          organisationDetail.agency_address &&
          organisationDetail.agency_address[0]?.house_no,
        addition:
          organisationDetail.agency_address &&
          organisationDetail.agency_address[0]?.addition,
        streetname:
          organisationDetail.agency_address &&
          organisationDetail.agency_address[0]?.street,
        zipcode_id:
          organisationDetail.agency_address &&
          organisationDetail.agency_address[0]?.zipcode_id,
      };
      setOrganisationDetails(orgData);
      setDescription(organisationDetail.description);

      setImageUrl(organisationDetail.logo_image);
      setBannerImageUrl(organisationDetail.banner_image);
      setFile(organisationDetail.logo_image);
      setBannerFile(organisationDetail.banner_image);
    }
  }, [organisationDetail]);

  useEffect(() => {
    if (getSuggestedInfo) {
      let setSuggestInfo = {
        ...organisationDetails,
        city: getSuggestedInfo?.rows && getSuggestedInfo?.rows[0]?.city.name,
        streetname: getSuggestedInfo?.rows && getSuggestedInfo?.rows[0]?.street,
        zipcode_id: getSuggestedInfo?.rows && getSuggestedInfo?.rows[0]?.id,
      };
      setOrganisationDetails(setSuggestInfo);
    }
  }, [getSuggestedInfo]);

  useEffect(() => {
    if (
      organisationDetails.number &&
      organisationDetails.postcode &&
      !error?.postcode
    ) {
      let columnData = {
        data: {
          HouseNumber: organisationDetails.number,
          postCode: organisationDetails.postcode,
        },
        navigate,
      };
      dispatch(actionGetSuggestedAutoCitiesApiCall(columnData));
    }
  }, [organisationDetails.number, organisationDetails.postcode]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setOrganisationDetails({
      ...organisationDetails,
      [name]: value,
    });
    let error = Validate(name, value);
    setError(error);
  };
  const handleFileChange = (e) => {
    e.preventDefault();
    let file;
    if (e.dataTransfer && e.dataTransfer.files.length > 0) {
      file = e.dataTransfer.files[0];
    } else if (e.target.files && e.target.files.length > 0) {
      file = e.target.files[0];
    }
    if (file?.size > 2 * 1024 * 1024) {
      toast.error("image can be of a max of 2 MB");
      return;
    }
    const src = URL.createObjectURL(file);
    setFile(file);
    setImageUrl(src);
    let error = Validate("upload", file);
    setError(error);
  };

  const handleBannerFileChange = (e) => {
    e.preventDefault();
    let file;
    if (e.dataTransfer && e.dataTransfer.files.length > 0) {
      file = e.dataTransfer.files[0];
    } else if (e.target.files && e.target.files.length > 0) {
      file = e.target.files[0];
    }
    if (file?.size > 2 * 1024 * 1024) {
      toast.error("image can be of a max of 2 MB");
      return;
    }
    const src = URL.createObjectURL(file);
    setBannerFile(file);
    setBannerImageUrl(src);
    let error = Validate("banner", file);
    setError(error);
  };
  const AddOrganisationClickHandler = () => {
    let data = {
      name: organisationDetails.name,
      address: organisationDetails.address,
      postcode: organisationDetails.postcode,
      city: organisationDetails.city,
      website: organisationDetails.website,
      phone: organisationDetails.phone,
      primary_contact: organisationDetails.primary_contact,
      email: organisationDetails.email,
      shortDescription: organisationDetails.shortDescription,
      description: description,
      number: organisationDetails.number, //number is the house_number
      housenumber_addition: organisationDetails.addition,
      street: organisationDetails.streetname,
      zipcode_id: organisationDetails.zipcode_id,
    };
    if (params.id) {
      data.agency_id = params.id;
    }
    let validateData = {
      name: organisationDetails.name,
      postcode: organisationDetails.postcode,
      city: organisationDetails.city,
      website: organisationDetails.website,
      phone: organisationDetails.phone,
      primary_contact: organisationDetails.primary_contact,
      email: organisationDetails.email,
      logo: file,
      banner: bannerFile,
      number: organisationDetails.number,
    };
    let error = Validate("", "", true, validateData);
    setError(error);
    if (Object.values(error).every((x) => x == null || x == "")) {
      let body = {
        data,
        navigate,
        file,
        bannerFile,
        id: params.id && params.id,
      };
      dispatch(actionAddOrganisationApiCall(body));
    }
  };
  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  const editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "numberedList",
        "bulletedList",
        "|",
        "undo",
        "redo",
      ],
      styles: {
        height: "500px",
      },
    },
    language: "en",
  };
  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.ConnectionContainer}>
          <div className={classes.TitleWrap}>
            <h1>
              {location.pathname == "/add-an-organisation"
                ? t("add_organization")
                : t("edit_an_organization")}
            </h1>
          </div>
          <div className={classes.OrganisationFromWrap}>
            <div className={classes.divWrap}>
              <h6>{t("name_of_organisation")}</h6>
              <input
                type="text"
                name="name"
                value={organisationDetails.name}
                onChange={handleChange}
                maxLength={100}
                placeholder={t("placeholder_of_name_of_organisation")}
                disabled={
                  location.pathname == "/add-an-organisation" ? false : true
                }
              />
              {error?.name && (
                <span className={`${classes.errorText}`}>{error.name}</span>
              )}
            </div>
            <div className={classes.divWrapContainer}>
              <div className={classes.divWrap}>
                <h6>{t("house_number")}</h6>
                <input
                  type="text"
                  name="number"
                  value={organisationDetails.number}
                  onChange={handleChange}
                  placeholder={t("house_number")}
                />
                {error?.houseNumber && (
                  <span className={`${classes.errorText}`}>
                    {error.houseNumber}
                  </span>
                )}
              </div>
              <div className={classes.divWrap}>
                <h6>{t("postal_code")}</h6>
                <input
                  type="text"
                  name="postcode"
                  value={organisationDetails.postcode}
                  onChange={handleChange}
                  maxLength={6}
                  placeholder={t("placeholder_of_postal_code")}
                />
                {error?.postcode && (
                  <span className={`${classes.errorText}`}>
                    {error.postcode}
                  </span>
                )}
              </div>
            </div>
            <div className={classes.divWrapContainer}>
              <div className={classes.divWrap}>
                <h6>{t("city")}</h6>
                <input
                  type="text"
                  name="city"
                  value={organisationDetails.city}
                  onChange={handleChange}
                  placeholder={t("placeholder_of_city")}
                  disabled
                />
                {error?.city && (
                  <span className={`${classes.errorText}`}>{error.city}</span>
                )}
              </div>
              <div className={classes.divWrap}>
                <h6>{t("streetname")}</h6>
                <input
                  type="text"
                  name="streetname"
                  value={organisationDetails.streetname}
                  onChange={handleChange}
                  maxLength={20}
                  disabled
                />
              </div>
            </div>
            <div className={classes.divWrap}>
              <h6>{t("addition")}</h6>
              <input
                type="text"
                name="addition"
                value={organisationDetails.addition}
                onChange={handleChange}
              />
            </div>
            <div className={classes.divWrap}>
              <h6>{t("website")}</h6>
              <input
                type="text"
                name="website"
                value={organisationDetails.website}
                onChange={handleChange}
                placeholder={t("placeholder_of_website")}
              />
              {error?.website && (
                <span className={`${classes.errorText}`}>{error.website}</span>
              )}
            </div>
            <div className={classes.divWrapContainer}>
              <div className={classes.divWrap}>
                <h6>{t("phonenumber")}</h6>
                <input
                  type="text"
                  name="phone"
                  value={organisationDetails.phone}
                  onChange={handleChange}
                  maxLength="10"
                  minLength="8"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={handleKeyPressForNumber}
                  placeholder={t("placeholder_of_phonenumber")}
                />
                {error?.phone && (
                  <span className={`${classes.errorText}`}>{error.phone}</span>
                )}
              </div>

              <div className={classes.divWrap}>
                <h6>{t("primary_contact")}</h6>
                <input
                  type="text"
                  name="primary_contact"
                  value={organisationDetails.primary_contact}
                  onChange={handleChange}
                  placeholder={t("placeholder_of_primary_contact")}
                />
                {error?.primary_contact && (
                  <span className={`${classes.errorText}`}>
                    {error.primary_contact}
                  </span>
                )}
              </div>
            </div>
            <div className={classes.divWrap}>
              <h6>{t("contact_email_of_organisation")}</h6>
              <input
                type="text"
                name="email"
                value={organisationDetails.email}
                onChange={handleChange}
                placeholder={t("placeholder_of_contact_email_of_organisation")}
                disabled={
                  location.pathname == "/add-an-organisation" ? false : true
                }
              />
              {error?.email && (
                <span className={`${classes.errorText}`}>{error.email}</span>
              )}
            </div>
            <div className={classes.divWrap}>
              <h6>{t("upload_logo")}</h6>
              <div
                className={classes.uploadMain}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleFileChange}
              >
                <input
                  type="file"
                  id="upload"
                  accept=".jpg,.jpeg,.png,.webp,.svg"
                  hidden
                  onChange={handleFileChange}
                />
                <label htmlFor="upload">
                  <div className={classes.PhotoUpload}>
                    <img
                      className={imageUrl ? classes.ImgWarp : classes.LogoWrap}
                      src={imageUrl ? imageUrl : FileUpload}
                    />
                  </div>
                </label>
                {error?.logo && (
                  <span className={`${classes.errorText}`}>{error.logo}</span>
                )}
              </div>
            </div>
            <div className={classes.divWrap}>
              <h6>{t("upload_banner")}</h6>
              <div
                className={classes.uploadMain}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleBannerFileChange}
              >
                <input
                  type="file"
                  id="banner"
                  accept=".jpg,.jpeg,.png,.webp,.svg"
                  hidden
                  onChange={handleBannerFileChange}
                />
                <label htmlFor="banner">
                  <div className={classes.PhotoUpload}>
                    <img
                      className={
                        bannerImageUrl ? classes.ImgWarp : classes.LogoWrap
                      }
                      src={bannerImageUrl ? bannerImageUrl : FileUpload}
                    />
                  </div>
                </label>
                {error?.banner && (
                  <span className={`${classes.errorText}`}>{error.banner}</span>
                )}
              </div>
            </div>
            <div className={classes.divWrap}>
              <h6>{t("short_description")}</h6>
              <textarea
                type="text"
                name="shortDescription"
                value={organisationDetails.shortDescription}
                onChange={handleChange}
                maxLength={100}
                placeholder={t("short_description")}
              />
            </div>
            <div className={classes.divWrap}>
              <h6>{t("full_description")}</h6>
              <CKEditor
                name="description"
                editor={ClassicEditor}
                config={editorConfig}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
                data={description || ""}
              />
            </div>
            <div className={classes.divWrap}>
              <button
                className={classes.saveButton}
                onClick={() => {
                  AddOrganisationClickHandler();
                }}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : location.pathname == "/add-an-organisation" ? (
                  t("add_organisation")
                ) : (
                  t("edit_organisation")
                )}
              </button>
            </div>
          </div>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default AddOrganisationMain;
