import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import classes from "./viewsingleorganisationmain.module.scss";
import defaultImage from "../../assets/images/defaultImage.png";
import pinkPlus from "../../assets/icons/pinkPlus.svg";
import crosIcon from "../../assets/icons/crosIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetOrganisationDetailApiCall,
  actionRemoveOrganisationEmployeeApiCall,
} from "../../Redux/Actions";
import ConfirmationModal from "../layouts/ConfirmationModal";
import { AiFillStar } from "react-icons/ai";
import Loader from "../Loader";

const ViewSingleOrganisationMain = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organisationEmployeeList = useSelector(
    (state) => state.CommonReducer.organisationEmployeeList
  );
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState();
  const [employeeName, setEmployeeName] = useState("");
  const loading = useSelector((state) => state.CommonReducer.loading);

  useEffect(() => {
    window.scrollTo(0, 0);

    let data = {
      id: params.id,
    };
    let body = { data, navigate, title: "employeelist" };
    dispatch(actionGetOrganisationDetailApiCall(body));
  }, []);

  const RemoveEmployeeHandler = () => {
    let data = {
      agency_id: params.id,
      employee_id: id,
    };
    let body = { data, navigate };
    dispatch(actionRemoveOrganisationEmployeeApiCall(body));
  };
  return (
    <React.Fragment>
      <MainLayout>
        {loading === true ? (
          <Loader />
        ) : (
          <div className={classes.SingleOrganisationContainer}>
            <div className={classes.TitleWrap}>
              <h1>
                {organisationEmployeeList?.name},
                {organisationEmployeeList?.city}
              </h1>
            </div>
            {organisationEmployeeList?.agency_members?.length > 0 ? (
              <div className={classes.EmployeeListContainer}>
                <div className={classes.Employee}>
                  <p className={classes.MainTitle}>
                    {organisationEmployeeList?.agency_members?.length > 0 &&
                      t("employees")}
                  </p>

                  {organisationEmployeeList?.agency_members?.length > 0 &&
                    organisationEmployeeList?.agency_members?.map((item) => {
                      return (
                        <div className={classes.OrganizationContainer}>
                          <div className={classes.Container}>
                            <p className={classes.MainTitle}>
                              {item?.full_name ? item?.full_name : "-"}
                            </p>
                            {item?.model?.user_id ===
                              organisationEmployeeList?.created_by && (
                              <AiFillStar />
                            )}
                          </div>
                          {item?.model?.user_id !==
                            organisationEmployeeList?.created_by && (
                            <div className={classes.CrosIconContainer}>
                              <img
                                src={crosIcon}
                                onClick={() => {
                                  setId(item.model.user_id);
                                  setShowModel(true);
                                  setEmployeeName(
                                    item?.full_name ? item?.full_name : ""
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
                <div className={classes.OrgImage}>
                  <div className={classes.OrgImageWrap}>
                    <img
                      src={
                        organisationEmployeeList?.logo_image
                          ? organisationEmployeeList?.logo_image
                          : defaultImage
                      }
                    />
                  </div>
                </div>
              </div>
            ) : (
              t("employee_not_added_yet")
            )}
            <div className={classes.IconWrapper}>
              <div
                className={classes.IconContainer}
                onClick={() =>
                  navigate("/add-employee", { state: { id: params.id } })
                }
              >
                <img src={pinkPlus} alt="Add an employee" />
                <p>{t("add_an_employee")}</p>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
      <ConfirmationModal
        showmodel={showModel}
        handleClose={() => setShowModel(false)}
        size={"sm"}
        onClickYesButton={RemoveEmployeeHandler}
        title={`${t("are_you_sure_you_want_to_remove")} ${employeeName}?`}
      />
    </React.Fragment>
  );
};

export default ViewSingleOrganisationMain;
