import React, { useState } from "react";
import classes from "./suppliercardselectable.module.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import NoDataFound from "../../NoDataFound";
import { SUPPLIER_BUDGETS } from "../../../Constants";
import { useTranslation } from "react-i18next";
import PriceFormatter from "../../../utils/PriceFormatter";

const SupplierCardSelectable = ({
  region,
  suppliers,
  changeSupplier,
  handleDeleteSupplier,
  changeBudget,
}) => {
  const location = useLocation().pathname;
  const platform = localStorage.getItem("Platform");
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={classes.SupplierListingWrap}>
        <div className={classes.SupplierScrollContainer}>
          <div className={classes.SupplierGridScroll}>
            <div className={classes.SupplierTitlesWrap}>
              <div className={classes.InputFieldBox}>
                <p>{t("banners_title_suppliers")}</p>
              </div>
              <div className={classes.InputFieldBox}>
                <p>{t("banners_title_cpm")}</p>
              </div>
            </div>
            {suppliers && suppliers.length > 0
              ? suppliers.map((item, index) => (
                  <div>
                    <div className={classes.SupplierWrap}>
                      <div className={classes.InputFieldBox}>
                        <input
                          type="checkbox"
                          id={`${region}_${item.id}`}
                          name={`${region}[]`}
                          className={classes.radioCustom}
                          value={item.id}
                          onChange={(event) => {
                            changeSupplier(event, region, item);
                          }}
                          defaultChecked={true}
                        />
                        <label
                          className={classes.radioCustomLabel}
                          htmlFor={`${region}_${item.id}`}
                        >
                          <span>{" " + t(item.name)}</span>
                        </label>
                      </div>
                      <div className={classes.InputFieldBox}>
                        <input
                          type="text"
                          name={`supplier_cpm_${item.id}`}
                          value={`€ ${PriceFormatter(
                            item.price_tables.map((ptItem) =>
                              ptItem.price_lines
                                .map((plItem) => parseFloat(plItem.price))
                                .reduce((accumulator, currentValue) => {
                                  return accumulator + currentValue;
                                }, 0)
                            ),
                            2
                          ).replace(",00", ",-")}`}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={classes.SupplierCard}></div>
                  </div>
                ))
              : t("banner_choose_region_first")}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SupplierCardSelectable;
