import axios from "axios";
import { isAndroidWeb, isDesktop, isIosWeb } from "../utils/getSystem";
import APIUrl from "./APIUrl";
class API {
  instance;
  constructor() {
    this.instance = axios.create({
      baseURL: APIUrl.backendUrl,
    });
  }

  APICALL(methods, url, body, header) {
    let system = "";

    if (isAndroidWeb()) {
      system = "Android Web";
    } else if (isIosWeb()) {
      system = "iOS Web";
    } else if (isDesktop()) {
      system = "Desktop Computer";
    }
    const headerType =
      header == "header"
        ? {
            system: system,
            "Content-Type": "application/json",
            localization: localStorage.getItem("language"),
          }
        : header == "formHeader"
        ? {
            system: system,
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
            localization: localStorage.getItem("language"),
          }
        : header == "formHeaderWithoutToken"
        ? {
            system: system,
            "Content-Type": "multipart/form-data",
            localization: localStorage.getItem("language"),
          }
        : {
            system: system,
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
            localization: localStorage.getItem("language"),
          };
    const config = {
      headers: headerType,
    };
    try {
      let response;

      if (methods == "post") {
        response = this.instance.post(
          `${APIUrl.backendUrl}/${url}`,
          body,
          config
        );
      } else if (methods == "get") {
        response = this.instance.get(`${APIUrl.backendUrl}/${url}`, config);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
}
const apiInstance = new API();

export const LoginAPICall = async (body) => {
  return await apiInstance.APICALL("post", "login", body, "header");
};

export const RegisterAPICall = async (body) => {
  return await apiInstance.APICALL("post", "register", body, "header");
};

export const ForgotPasswordAPICall = async (body) => {
  return await apiInstance.APICALL("post", "forgotPassword", body, "header");
};

export const OtpAPICall = async (body) => {
  return await apiInstance.APICALL("post", "checkOtp", body, "header");
};

export const ResetPasswordApiCall = async (body) => {
  return await apiInstance.APICALL("post", "resetPassword", body, "header");
};

export const LatestProperyListingHomeApiCall = async (body) => {
  return await apiInstance.APICALL("post", "getProperty", body, "authHeader");
};

export const EditAccountInfoApiCall = async (body) => {
  return await apiInstance.APICALL("post", "edit-profile", body, "authHeader");
};

export const LogoutApiCall = async () => {
  return await apiInstance.APICALL("get", "logout", "authHeader");
};

export const MakePropertyFavApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "make-property-fav",
    body,
    "authHeader"
  );
};

export const MyListingApiCall = async (body) => {
  return await apiInstance.APICALL("post", "myProperty", body, "authHeader");
};

export const MyOrganisationListingApiCall = async () => {
  return await apiInstance.APICALL(
    "get",
    "my-organisations",
    null,
    "authHeader"
  );
};

export const AddOrganisationApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "add-organisation-step-one",
    body,
    "authHeader"
  );
};

export const AddOrganisationLogoApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "add-organisation-step-two",
    body,
    "formHeader"
  );
};

export const AddPropertyStepOneApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "addPropertyStepOne",
    body,
    "authHeader"
  );
};

export const AddPropertyStepTwoApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "addPropertyStepTwo",
    body,
    "authHeader"
  );
};

export const AddPropertyStepThreeApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "addPropertyStepThree",
    body,
    "authHeader"
  );
};

export const SearchOrganisationApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "search-organisation",
    body,
    "authHeader"
  );
};

export const JoinAnOrganisationApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "join-organisation",
    body,
    "authHeader"
  );
};

export const RemoveJoinAnOrganisationApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `remove-joined-organisation/${body.id}`,
    null,
    "authHeader"
  );
};

export const FavouritePropertyListingApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `fav-property-list`,
    body,
    "authHeader"
  );
};

export const ViewSinglePropertyDetailsApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `property-details`,
    body,
    "authHeader"
  );
};

export const ViewSinglePropertyDetailApiCall = async (body) => {
  return await apiInstance.APICALL("post", `property-detail`, body, "header");
};

export const ViewBannerDetailsApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `banner-campaign/${body.id}`,
    "authHeader"
  );
};

export const UpdatePropertyVisibleApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `updatePropertyVisible`,
    body,
    "authHeader"
  );
};
export const SavedFilterApiCall = async () => {
  return await apiInstance.APICALL("get", "saved-filter", "authHeader");
};

export const AddSavedFilterApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "add-saved-filter",
    body,
    "authHeader"
  );
};

export const AddPropertyApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "addPropertyWeb",
    body,
    "formHeader"
  );
};
export const AddPropertyApiCallV1 = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "addPropertyWebV1",
    body,
    "formHeader"
  );
};

export const AddBannerApiCall = async (body) => {
  return await apiInstance.APICALL("post", "addBannerWeb", body, "authHeader");
};
export const UpdateBannerApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "updateBannerWeb",
    body,
    "authHeader"
  );
};

export const BannerCampaignsApiCall = async () => {
  return await apiInstance.APICALL(
    "get",
    "banner-campaigns",
    null,
    "authHeader"
  );
};

export const BannerTemplatesApiCall = async () => {
  return await apiInstance.APICALL(
    "get",
    "banner-templates",
    null,
    "authHeader"
  );
};

export const UpdateImageApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "updateUserImage",
    body,
    "formHeader"
  );
};

export const GetLanguagesApiCall = async () => {
  return await apiInstance.APICALL("get", "lang-list", "header");
};

export const ChangeLanguageApiCall = async (body) => {
  return await apiInstance.APICALL("post", "change-lang", body, "authHeader");
};

export const GetOrganisationDetailApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `organization-detail/${body}`,
    "authHeader"
  );
};

export const GetBannerApiCall = async () => {
  return await apiInstance.APICALL("get", `getBanner`, "authHeader");
};

export const GetAutoSuggestCityApiCall = async (query) => {
  return await apiInstance.APICALL(
    "get",
    `auto-suggest-city?house_no=${query.data.HouseNumber}&zipcode=${query.data.postCode}`,
    "authHeader"
  );
};
export const RemoveImagesApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "remove-property-image",
    body,
    "authHeader"
  );
};

export const RemovePropertyPriceApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "remove-property-price",
    body,
    "authHeader"
  );
};

export const GetPropertyTypeApiCall = async (selectedLanguage) => {
  return await apiInstance.APICALL(
    "get",
    `translation-list/${selectedLanguage}/property_type`,
    "authHeader"
  );
};

export const GetApplicableOptionsApiCall = async (selectedLanguage) => {
  return await apiInstance.APICALL(
    "get",
    `translation-list/${selectedLanguage}/applicable_options`,
    "authHeader"
  );
};

export const GetCitiesApiCall = async (selectedLanguage) => {
  return await apiInstance.APICALL(
    "get",
    `translation-list/${selectedLanguage}/cities`,
    "authHeader"
  );
};

export const GetRentPrefixApiCall = async (selectedLanguage) => {
  return await apiInstance.APICALL(
    "get",
    `translation-list/${selectedLanguage}/rent_prefix`,
    "authHeader"
  );
};

export const GetRentPostfixApiCall = async (selectedLanguage) => {
  return await apiInstance.APICALL(
    "get",
    `translation-list/${selectedLanguage}/rent_postfix`,
    "authHeader"
  );
};

export const GetSellPrefixApiCall = async (selectedLanguage) => {
  return await apiInstance.APICALL(
    "get",
    `translation-list/${selectedLanguage}/sell_prefix`,
    "authHeader"
  );
};

export const GetSellPostfixApiCall = async (selectedLanguage) => {
  return await apiInstance.APICALL(
    "get",
    `translation-list/${selectedLanguage}/sell_postfix`,
    "authHeader"
  );
};

export const GetApprovedOrganisationsApiCall = async () => {
  return await apiInstance.APICALL(
    "get",
    "approved-organisations",
    "authHeader"
  );
};

export const AddOrganisationEmployeeApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "organization-employee",
    body,
    "formHeader"
  );
};

export const GetEmployeeJobTitleApiCall = async (selectedLanguage) => {
  return await apiInstance.APICALL(
    "get",
    `translation-list/${selectedLanguage}/job_title`,
    "header"
  );
};

export const RemoveOrganisationEmployeeApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `remove-organisation-employee?agency_id=${body.agency_id}&employee_id=${body.employee_id}`,
    null,
    "authHeader"
  );
};

export const GetSiteSettingsApiCall = async () => {
  return await apiInstance.APICALL("get", `get-site-settings`, "authHeader");
};

export const ContactUsInfoApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "conatct-us-info",
    body,
    "authHeader"
  );
};

export const DeleteMyPropertyApiCall = async (id) => {
  return await apiInstance.APICALL(
    "post",
    `remove-property/${id}`,
    {},
    "authHeader"
  );
};

export const DeletePropertyBannerApiCall = async (id) => {
  return await apiInstance.APICALL(
    "post",
    `remove-banner/${id}`,
    {},
    "authHeader"
  );
};

export const CreateOrgAccountWithoutLoginApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `add-organisation-step-one-without-login`,
    body,
    "formHeaderWithoutToken"
  );
};

export const CreateOrgAccountStep2ApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `add-organisation-step-two-without-login`,
    body,
    "formHeaderWithoutToken"
  );
};

export const VerificationApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `verify-org-user?token=${body}`,
    "header"
  );
};

export const MolliePaymentApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `create-test-payment`,
    body,
    "header"
  );
};

export const SearchOrganisationWithoutLoginApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `search-organisation-without-login`,
    body,
    "header"
  );
};

export const SubscriptionListApiCall = async (body, title) => {
  return await apiInstance.APICALL(
    "get",
    `get-all-subscriptions?${title}=${body}`,
    "authheader"
  );
};

export const CreateUserSubsciptionApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `create-user-subscription`,
    body,
    localStorage.getItem("authToken") ? "authheader" : "header"
  );
};

export const GetSubcriptionWithStatusApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `get-subscription-with-status?subscriptionId=${body}`,
    "header"
  );
};
export const SelfOrganisationListingForSubscriptionApiCall = async (body) => {
  return await apiInstance.APICALL(
    "get",
    `my-created-organisation/${body}`,
    "header"
  );
};

export const GetCityListApiCall = async (body) => {
  return await apiInstance.APICALL("get", `get-cities`, "header");
};

export const GetTranslationListApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `translation-list/en`,
    body,
    "header"
  );
};

export const getCityInfoApiCall = async (search) => {
  return await apiInstance.APICALL(
    "get",
    `city-info?types=city,municipality,neighbourhood,district,province,country_part&search=${search}`,
    "authheader"
  );
};

export const GetAgencyBannerRecommendationsApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `get-agency-banner-recommendations`,
    body,
    localStorage.getItem("authToken") ? "authheader" : "header"
  );
};

export const GetAgencyBannerClickCountApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `get-agency-clicks-count`,
    body,
    localStorage.getItem("authToken") ? "authheader" : "header"
  );
};
export const GetSuppliersApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    `get-suppliers`,
    body,
    localStorage.getItem("authToken") ? "authheader" : "header"
  );
};

export const MakeBannerStopApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "make-banner-stop",
    body,
    "authHeader"
  );
};

export const GetBannerIdApiCall = async (body) => {
  return await apiInstance.APICALL("get", `banner-image/${body.id}`, "header");
};

export const GetPropertyAllDataApiCall = async (body) => {

  return await apiInstance.APICALL(
    "post",
    `getPropertyAllData`,
    body,
    "header"
  );
};

export const MyInvoicesDataApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "invoices",
    body,
    "authHeader"
  );
};

export const GetInvoiceDataApiCall = async (body) => {
  return await apiInstance.APICALL("post", "invoice", body, "authHeader");
};

export const MakePaymentApiCall = async (body) => {
  return await apiInstance.APICALL(
    "post",
    "make-payment",
    body,
    "authHeader"
  )
}
export default apiInstance;
