import { t } from "i18next";
const Validate = (name, value, isSubmit = false, obj = {}, passwordData) => {
  let data = {};
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errors = {};
  if (!isSubmit) {
    data = { [name]: value };
  } else {
    data = obj;
  }
  Object.keys(data).forEach((item) => {
    switch (item) {
      case "name":
        if (data[item].length > 0) {
          if (!data[item].trim()) {
            errors.name = t("name_invalid");
          }
        } else {
          errors.name = t("name_required");
        }
        break;
      case "address":
        if (data[item].length > 0) {
        } else {
          errors.address = t("address_required");
        }
        break;
      case "postcode":
        if (data[item].length > 0) {
          if (data[item].length < 6 || !/^\d{4}[a-zA-Z]{2}$/.test(data[item])) {
            errors.postcode = t("postalcode_invalid");
          }
        } else {
          errors.postcode = t("postalcode_required");
        }
        break;
      case "city":
        if (data[item]?.length > 0) {
          if (!new RegExp(/^[^\s<>&]*$/).test(data[item])) {
            errors.city = t("city_invalid");
          }
        } else {
          errors.city = t("city_required");
        }
        break;
      case "website":
        if (data[item].length > 0) {
          if (
            !new RegExp(
              "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
              "i"
            ).test(data[item])
          ) {
            errors.website = t("website_invalid");
          }
        } else {
          errors.website = t("website_required");
        }
        break;
      case "phone":
        if (data[item].length > 0) {
          if (data[item].length < 8) {
            errors.phone = t("phoneno_invalid");
          }
        } else {
          errors.phone = t("phoneno_required");
        }
        break;

      case "phoneno":
        if (data[item].length > 0) {
          if (data[item].length < 8) {
            errors.phoneno = t("phoneno_invalid");
          }
        }
        break;
      case "primary_contact":
        if (data[item]?.length > 0) {
        } else {
          errors.primary_contact = t("primary_contact_required");
        }
        break;
      case "logo":
        if (data[item]) {
        } else {
          errors.logo = t("logo_required");
        }
        break;
      case "banner":
        if (data[item]) {
        } else {
          errors.banner = t("banner_required");
        }
        break;
      case "email":
        if (data[item].length > 0) {
          if (
            !new RegExp(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ).test(data[item])
          ) {
            errors.email = t("email_invalid");
          }
        } else {
          errors.email = t("email_required");
        }
        break;
      case "property_for":
        if (data[item].length > 0) {
        } else {
          errors.property_for = t("listing_required");
        }
        break;
      case "typeOfProperty":
        if (data[item].length > 0) {
        } else {
          errors.typeOfProperty = t("type_of_property_required");
        }
        break;
      case "property_type":
        if (data[item].length > 0) {
        } else {
          errors.property_type = t("type_of_property_required");
        }
        break;
      case "propertyType":
        if (data[item]) {
        } else {
          errors.propertyType = t("type_of_property_required");
        }
        break;
      case "propertyAvailableDate":
        if (data[item].length > 0) {
        } else {
          errors.propertyAvailableDate = t("property_available_required");
        }
        break;
      case "bedrooms":
        if (data[item] >= 0) {
        } else {
          errors.bedrooms = t("room_required");
        }
        break;
      // case "applicableOptions":
      //   if (data[item].length > 0) {
      //   } else {
      //     errors.applicableOptions = t("applicable_option_required");
      //   }
      //   break;
      case "constructionYear":
        if (data[item]) {
          if (!new RegExp(/^\d{4}$/).test(data[item])) {
            errors.constructionYear = t("construction_year_invalid");
          }
        } else {
          errors.constructionYear = t("construction_year_required");
        }
        break;
      case "livableArea":
        if (data[item].length > 0) {
          if (!new RegExp(/^[0-9]+(\.[0-9]+)?$/).test(data[item])) {
            errors.livableArea = t("property_size_invalid");
          }
        } else {
          errors.livableArea = t("property_size_required");
        }
        break;
      case "homePlotArea":
        if (data[item].length > 0) {
          if (!new RegExp(/^[0-9]+(\.[0-9]+)?$/).test(data[item])) {
            errors.homePlotArea = t("plot_size_invalid");
          }
        }
      // else {
      //     errors.homePlotArea = t("plot_size_required");
      //   }
      //   break;
      // case "energyClass":
      //   if (data[item].length > 0) {
      //   } else {
      //     errors.energyClass = t("energy_label_required");
      //   }
      //   break;
      // case "quote":
      //   if (data[item].length > 0) {
      //   } else {
      //     errors.quote = t("quote_required");
      //   }
      //   break;
      case "description":
        if (data[item].length > 0) {
          errors.description = "";
        } else {
          errors.description = t("full_description_required");
        }
        break;
      case "images":
        if (data[item].length > 0) {
        } else {
          errors.images = t("image_required");
        }
        break;
      case "password":
        if (data[item].length > 0) {
          if (data[item].length < 8) {
            errors.password = t("minimum_eight_characters_for_password");
          } else {
            errors.password = "";
          }
        } else {
          errors.password = t("password") + " " + t("required");
        }
        break;
      case "confirmPassword":
        if (data[item].length > 0) {
          if (isSubmit == false) {
            if (passwordData != data["confirmPassword"]) {
              errors.confirmPassword = t(
                "password_and_confirm_password_does_not_match"
              );
            } else {
              errors.confirmPassword = "";
            }
          } else {
            if (data["password"] != data["confirmPassword"]) {
              errors.confirmPassword = t(
                "password_and_confirm_password_does_not_match"
              );
            } else {
              errors.confirmPassword = "";
            }
          }
        } else {
          errors.confirmPassword = t("confirm_password_required");
        }
        break;
      case "sell_prefix":
        if (data[item].length === 0) {
          errors.sell_prefix = t("price_info_required");
        }
        break;
      case "sell_price":
        if (
          data["sell_prefix"] === "FIXED_PRICE" ||
          data["sell_prefix"] === "ASKING_PRICE"
        ) {
          if (data[item].length === 0) {
            errors.sell_price = t("price_required");
          }
        }
        break;
      case "sell_postfix":
        if (
          data["sell_prefix"] === "FIXED_PRICE" ||
          data["sell_prefix"] === "ASKING_PRICE"
        ) {
          if (data[item].length === 0) {
            errors.sell_postfix = t("price_type_required");
          }
        }
        break;
      case "rent_prefix":
        if (data[item].length === 0) {
          errors.rent_prefix = t("price_info_required");
        }
        break;
      case "rent_price":
        if (
          data["rent_prefix"] === "FIXED_PRICE" ||
          data["rent_prefix"] === "ASKING_PRICE"
        ) {
          if (data[item].length === 0) {
            errors.rent_price = t("price_required");
          }
        }
        break;
      case "rent_postfix":
        if (
          data["rent_prefix"] === "FIXED_PRICE" ||
          data["rent_prefix"] === "ASKING_PRICE"
        ) {
          if (data[item].length === 0) {
            errors.rent_postfix = t("price_type_required");
          }
        }
        break;
      case "employeeName":
        if (data[item].length > 0) {
          if (!new RegExp(/^[^\d]+$/).test(data[item]) || !data[item].trim()) {
            errors.employeeName = t("name_invalid");
          }
        } else {
          errors.employeeName = t("name_required");
        }
        break;
      case "employeeImage":
        if (data[item]?.length > 0) {
        } else {
          errors.employeeImage = t("image_required");
        }
        break;
      case "contactName":
        if (data[item]?.length > 0) {
          if (!data[item].trim()) {
            errors.contactName = t("name_invalid");
          }
        } else {
          errors.contactName = t("name_required");
        }
        break;
      case "orgEmail":
        if (data[item].length > 0) {
          if (!new RegExp(EMAIL_REGEX).test(data[item])) {
            errors.orgEmail = t("email_invalid");
          }
        } else {
          errors.orgEmail = t("email_required");
        }
        break;
      case "number":
        if(data[item]?.length <= 0 ) {
          errors.houseNumber = t("house_num_required")
        }
        break;
      case "HouseNumber":
        if(data[item]?.length <= 0 ) {
          errors.houseNumber_1 = t("house_num_required")
        }
        break;
      case "contactEmail":
        if (data[item].length > 0) {
          if (!new RegExp(EMAIL_REGEX).test(data[item])) {
            errors.contactEmail = t("email_invalid");
          }
        } else {
          errors.contactEmail = t("email_required");
        }
        break;
      default:
        break;
    }
  });
  return errors;
};
export default Validate;
