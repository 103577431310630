import React from "react";
import classes from "./vieworgmain.module.scss";
import { useTranslation } from "react-i18next";
import EmailIcon from "../../assets/icons/EmainIcon.svg";
import ContactIcon from "../../assets/icons/ContactIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionGetOrganisationDetailApiCall } from "../../Redux/Actions";
import defaultImage from "../../assets/images/defaultImage.png";
import MainContainer from "../layouts/MainContainer";
import { AiOutlineGlobal } from "react-icons/ai";
import TitleProvider from "../TitleProvider";

const ViewOrgMain = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organisationDetail = useSelector(
    (state) => state.CommonReducer.organisationDetail
  );
  useEffect(() => {
    let data = {
      id: params.id,
    };
    let body = { data, navigate, title: "viewOrg" };
    dispatch(actionGetOrganisationDetailApiCall(body));
  }, []);
  return (
    <>
      <div className={classes.ViewOrgMain}>
        <TitleProvider title={organisationDetail?.name} />
        <div className={classes.OrgImgWrap}>
          <img
            src={
              organisationDetail?.banner_image
                ? organisationDetail?.banner_image
                : defaultImage
            }
            alt={organisationDetail?.original_banner_name}
          />
        </div>
        <MainContainer>
          <div className={classes.OrgContent}>
            <div className={classes.OrgLogoWrap}>
              <img
                src={
                  organisationDetail?.logo_image
                    ? organisationDetail?.logo_image
                    : defaultImage
                }
                alt={organisationDetail?.original_logo_name}
              />
            </div>
            <div className={classes.OrgContentWrap}>
              <h1>{organisationDetail?.name}</h1>
              <p>{organisationDetail?.short_description}</p>
              <div className={classes.OrgInfoWrap}>
                {organisationDetail?.email && (
                  <div className={classes.OrgInfo}>
                    <img src={EmailIcon} alt="Email" />
                    <a href={`mailto:${organisationDetail?.email}`}>
                      {organisationDetail?.email}
                    </a>
                  </div>
                )}
                {organisationDetail?.phone && (
                  <div className={classes.OrgInfo}>
                    <img src={ContactIcon} alt="Phone" />
                    <a href={`tel:${organisationDetail?.phone}`}>
                      {organisationDetail?.phone}
                    </a>
                  </div>
                )}
                {organisationDetail?.website && (
                  <div className={classes.OrgInfo}>
                    <AiOutlineGlobal />
                    <a
                      href={`https://${organisationDetail?.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {organisationDetail?.website}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.OrgMainInfo}>
            <div className={classes.InfoWrap}>
              <p>{organisationDetail?.name}</p>
              <p>{organisationDetail?.address}</p>
              <p>
                {organisationDetail?.postcode} {organisationDetail?.city}
              </p>
              <a href={`tel:${organisationDetail?.phone}`}>
                {organisationDetail?.phone}
              </a>
              <br />
              <a href={`mailto:${organisationDetail?.email}`}>
                {organisationDetail?.email}
              </a>
            </div>
            {organisationDetail?.description && (
              <div className={classes.DescriptionWrap}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: organisationDetail?.description,
                  }}
                ></p>
              </div>
            )}
          </div>
          <div className={classes.EmployeInfo}>
            <h6>{t("employees")}</h6>
            <div className={classes.EmployeInfoWrap}>
              {organisationDetail?.agency_members?.length > 0 &&
                organisationDetail?.agency_members
                  .filter((item) => item.user_type === "Employee")
                  .map((item) => (
                    <div className={classes.SingleEmployeInfo}>
                      <div className={classes.EmployeImg}>
                        <img src={item.url_original_file} />
                      </div>
                      <div className={classes.EmployeDetails}>
                        <p className={classes.EmployeName}>{item.full_name}</p>
                        <p>{item.model.job_title}</p>
                        <p>{item.phone}</p>
                        <p>{item.email}</p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          <div className={classes.Goback}>
            <span onClick={() => window.history.back()}>
              {"<"}
              {t("goback")}
            </span>
          </div>
        </MainContainer>
      </div>
    </>
  );
};

export default ViewOrgMain;
