export const isAndroidWeb = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.includes("android") &&
    !userAgent.includes("mobile") &&
    userAgent.includes("chrome")
  );
};

export const isIosWeb = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    (userAgent.includes("iphone") || userAgent.includes("ipad")) &&
    !userAgent.includes("mobile") &&
    userAgent.includes("safari")
  );
};

export const isDesktop = () => {
  return !isAndroidWeb() && !isIosWeb();
};
