import React from "react";
import classes from "./needhelpmain.module.scss";
import MainContainer from "../../layouts/MainContainer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bannerClickHandler from "../../../utils/bannerClickHandler";

const NeedHelpMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const saveAgencyBannerRecommendations =
    useSelector(
      (state) => state.CommonReducer.saveAgencyBannerRecommendations
    ) || {};

  const HomeLeft = saveAgencyBannerRecommendations?.["Home Left"];
  const HomeRight = saveAgencyBannerRecommendations?.["Home Right"];

  return (
    <React.Fragment>
      <MainContainer>
        <div className={classes.NeedHelpSection}>
          <div className={classes.HelpWrap}>
            <div className={classes.NeedHelpImageSection}>
              {HomeLeft && (
                <div className={classes.leftImg}>
                  <div
                    className={classes.NeedImageWrap}
                    onClick={() => {
                      bannerClickHandler(
                        HomeLeft?.agency_banner_id,
                        HomeLeft?.id,
                        HomeLeft?.agency_banners?.link_target,
                        HomeLeft?.agency_banners?.redirection_link,
                        navigate,
                        dispatch
                      );
                    }}
                  >
                    {HomeLeft?.url_original_file ? (
                      <img
                        src={HomeLeft?.url_original_file}
                        alt={HomeLeft?.original_file_name}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{ __html: HomeLeft.iframe }}
                        style={{ height: "100%", width: "100%" }}
                      ></div>
                    )}
                  </div>
                </div>
              )}
              {HomeRight && (
                <div className={classes.rigthImg}>
                  <div
                    className={classes.NeedImageWrap}
                    onClick={() => {
                      bannerClickHandler(
                        HomeRight?.agency_banner_id,
                        HomeRight?.id,
                        HomeRight?.agency_banners?.link_target,
                        HomeRight?.agency_banners?.redirection_link,
                        navigate,
                        dispatch
                      );
                    }}
                  >
                    {HomeRight?.url_original_file ? (
                      <img
                        fluid={true}
                        src={HomeRight?.url_original_file}
                        alt={HomeRight?.original_file_name}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{ __html: HomeRight.iframe }}
                        style={{ height: "100%", width: "100%" }}
                      ></div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </MainContainer>
    </React.Fragment>
  );
};

export default NeedHelpMain;
