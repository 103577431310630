import React from "react";
import ErrorMain from "../../components/ErrorMain";
const ErrorPage = () => {
	return (
		<React.Fragment>
			<ErrorMain />
		</React.Fragment>
	);
};

export default ErrorPage;
