import React from "react";
import CreateAccountMain from "../../../components/auth/createAccountMain";
import Footer from "../../../components/layouts/Footer/Index";
import Header from "../../../components/layouts/Header/Index";

const CreateAccountPage = () => {
  return (
    <>
      <Header />
      <CreateAccountMain />
      <Footer />
    </>
  );
};

export default CreateAccountPage;
