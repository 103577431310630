const FooterRedirectionTab = (item) => {
  if (item?.cms_page) {
    return `/cms-page/${item?.cms_page?.data?.attributes?.Slug}`;
  } else if (item["__component"] === "menu.external-link") {
    return item?.Link;
  } else if (item["__component"] === "menu.project-link") {
    return `/${item?.Link}`;
  }
};

export default FooterRedirectionTab;
