import React from "react";
import classes from "./searchorganisationmain.module.scss";
import TemplateLayout from "../layouts/TemplateLayout";
import { useTranslation } from "react-i18next";
import AreaIcon from "../../assets/icons/AreaIcon.svg";
import ZipCodeIcon from "../../assets/icons/ZipCodeIcon.svg";
import NameIcon from "../../assets/icons/NameIcon.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetHomeBannerApiCall,
  actionSaveSearchOrganisationWithoutLogin,
  actionSearchOrganisationWithoutLoginApiCall,
} from "../../Redux/Actions";
import defaultImage from "../../assets/images/defaultImage.png";
import Loader from "../Loader";
import { useRef } from "react";
import Pagination from "../Pagination";
import { useEffect } from "react";
const SearchOrganisationMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [selectedSearchOrgOption, setSelectedSearchOrgOption] = useState(
    params.get("area")
      ? "area"
      : params.get("name")
      ? "name"
      : params.get("zipcode")
      ? "zipcode"
      : "area"
  );
  const [selectedSearchOrgValue, setSelectedSearchOrgValue] = useState(
    params.get("area")
      ? params.get("area")
      : params.get("name")
      ? params.get("name")
      : params.get("zipcode")
      ? params.get("zipcode")
      : ""
  );
  const saveSearchOrganisationWithoutLogin = useSelector(
    (state) => state.CommonReducer.saveSearchOrganisationWithoutLogin
  );
  const loading = useSelector((state) => state.CommonReducer.loading);
  const count = useSelector(
    (state) => state.CommonReducer.saveSearchOrganisationWithoutLogin?.count
  );
  const saveHomeBanner = useSelector(
    (state) => state.CommonReducer.saveHomeBanner
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    params.get("page") ? params.get("page") : 1
  );
  const [pageNumber, setPageNumber] = useState();
  const [activePage, setActivePage] = useState(
    params.get("page") ? params.get("page") : 1
  );
  const [perPage, setPerPage] = useState(
    params.get("perPage") ? params.get("perPage") : 20
  );
  const [sortBy, setSortBy] = useState(
    params.get("sort") ? params.get("sort") : "ASC"
  );
  const [orgImage, setOrgImage] = useState("");
  const [visibleImage, setVisibleImage] = useState(false);
  const myRef = useRef();
  useEffect(() => {
    dispatch(actionGetHomeBannerApiCall({ navigate }));
  }, []);
  useEffect(() => {
    saveHomeBanner?.rows?.length > 0 &&
      saveHomeBanner?.rows?.find((item, index) => {
        if (item.position === "Search Organisation") {
          setOrgImage(item.url_original_file);
        }
      });
  }, [saveHomeBanner]);
  useEffect(() => {
    const pageNumbers = [];
    let totalPages = Math.ceil(count / perPage);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    setTotalPages(totalPages);
    setPageNumber(pageNumbers);
  }, [count, perPage]);
  useEffect(() => {
    if (selectedSearchOrgOption && selectedSearchOrgValue) {
      searchHandler();
    }
  }, [currentPage, perPage, sortBy]);
  const handleSearchOption = (option) => {
    setActivePage(1);
    setCurrentPage(1);
    setSelectedSearchOrgValue("");
    dispatch(actionSaveSearchOrganisationWithoutLogin(""));
    setSelectedSearchOrgOption(option);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
  };
  const searchHandler = () => {
    let data = {
      page: currentPage ? currentPage : 1,
      per_page: perPage ? perPage : 20,
      name: "",
      city: "",
      zip_code: "",
      sort: sortBy,
    };
    if (selectedSearchOrgOption == "area") {
      data = {
        ...data,
        city: selectedSearchOrgValue,
      };
    } else if (selectedSearchOrgOption == "zipcode") {
      data = {
        ...data,
        zip_code: selectedSearchOrgValue,
      };
    } else {
      data = {
        ...data,
        name: selectedSearchOrgValue,
      };
    }
    const queryParams = [
      `page=${currentPage}`,
      `perPage=${perPage}`,
      selectedSearchOrgOption == "area" ? `area=${selectedSearchOrgValue}` : "",
      selectedSearchOrgOption == "zipcode"
        ? `zipcode=${selectedSearchOrgValue}`
        : "",
      selectedSearchOrgOption == "name" ? `name=${selectedSearchOrgValue}` : "",
      `sort=${sortBy}`,
    ];
    const queryString = queryParams.filter(Boolean).join("&");
    let body = { data, navigate, queryString };
    dispatch(actionSearchOrganisationWithoutLoginApiCall(body));
  };
  const handleKeyPress = (event) => {
    const selectionStart = event.target.selectionStart;
    if (selectionStart === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <TemplateLayout
        image={orgImage}
        title={t("organisations")}
        visible={visibleImage}
      >
        <form>
          <div className={classes.SearchOrganisationMain}>
            <div className={classes.SearchOrganisationWrap}>
              <h1>{t("search_organisation")}</h1>
              <span>{t("based_on")}</span>
              <div className={classes.SearchBased}>
                <div
                  className={
                    selectedSearchOrgOption === "area"
                      ? classes.SelectionBoxActive
                      : classes.SelectionBox
                  }
                  onClick={() => handleSearchOption("area")}
                >
                  <img src={AreaIcon} alt="Area" />
                  <p>{t("area")}</p>
                </div>
                <div
                  className={
                    selectedSearchOrgOption === "zipcode"
                      ? classes.SelectionBoxActive
                      : classes.SelectionBox
                  }
                  onClick={() => handleSearchOption("zipcode")}
                >
                  <img src={ZipCodeIcon} alt="Zipcode" />
                  <p>{t("zipcode")}</p>
                </div>
                <div
                  className={
                    selectedSearchOrgOption === "name"
                      ? classes.SelectionBoxActive
                      : classes.SelectionBox
                  }
                  onClick={() => handleSearchOption("name")}
                >
                  <img src={NameIcon} alt="Name" />
                  <p>{t("name_small")}</p>
                </div>
              </div>
              <div className={classes.Inpotbox}>
                {selectedSearchOrgOption == "area" ? (
                  <p>{t("enter_area_of_organisation")}</p>
                ) : selectedSearchOrgOption == "zipcode" ? (
                  <p>{t("enter_zipcode_of_organisation")}</p>
                ) : (
                  <p>{t("enter_name_of_organisation")}</p>
                )}
                <input
                  type="text"
                  name="selectedSearchOrgValue"
                  value={selectedSearchOrgValue}
                  onChange={(e) => setSelectedSearchOrgValue(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <button
                disabled={
                  selectedSearchOrgValue != "" && selectedSearchOrgOption !== ""
                    ? false
                    : true
                }
                className={
                  selectedSearchOrgValue != "" && selectedSearchOrgOption !== ""
                    ? classes.btnActive
                    : classes.btnInActive
                }
                onClick={(e) => {
                  e.preventDefault();
                  setVisibleImage(true);
                  setCurrentPage(1);
                  setActivePage(1);
                  searchHandler();
                }}
              >
                {t("search")}
              </button>
            </div>
            {saveSearchOrganisationWithoutLogin &&
            saveSearchOrganisationWithoutLogin?.rows?.length > 0 ? (
              <div className={classes.PropertyCardSection} ref={myRef}>
                <div className={classes.DropDownWrap}>
                  <div className={classes.DropDown}>
                    <p>{t("sort_by")}:</p>
                    <select
                      value={sortBy}
                      onChange={(e) => {
                        setActivePage(1);
                        setCurrentPage(1);
                        setSortBy(e.target.value);
                      }}
                    >
                      <option>{t("desc")}</option>
                      <option>{t("asc")}</option>
                    </select>
                  </div>
                  <div className={classes.DropDown}>
                    <p>{t("results_per_page")}:</p>
                    <select
                      value={perPage}
                      onChange={(e) => {
                        setActivePage(1);
                        setCurrentPage(1);
                        setPerPage(e.target.value);
                      }}
                    >
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </select>
                  </div>
                </div>
                <div className={classes.CardSection}>
                  {loading === true ? (
                    <Loader />
                  ) : (
                    saveSearchOrganisationWithoutLogin &&
                    saveSearchOrganisationWithoutLogin?.rows?.length > 0 && (
                      <>
                        {saveSearchOrganisationWithoutLogin?.rows?.map(
                          (item) => {
                            return (
                              <>
                                <div
                                  className={classes.CardMain}
                                  onClick={() => {
                                    navigate(
                                      `/view-organisation/${item?.city
                                        .toLowerCase()
                                        .replace(/ /g, "-")
                                        .replace(/[^\w-]+/g, "")}/${item?.name
                                        .toLowerCase()
                                        .replace(/ /g, "-")
                                        .replace(/[^\w-]+/g, "")}/${item?.id}`
                                    );
                                  }}
                                >
                                  <div className={classes.CardImg}>
                                    <img
                                      src={
                                        item.logo_image
                                          ? item.logo_image
                                          : defaultImage
                                      }
                                    />
                                  </div>
                                  <div className={classes.CardContent}>
                                    <p className={classes.name}>{item.name}</p>
                                    <p>
                                      {item.address
                                        ? item.address
                                        : `${item.street} ${item.number}`}
                                      {", "}
                                      {item.city}
                                    </p>
                                    {item.agency_members.length > 0 && (
                                      <p>
                                        <span>
                                          {item.agency_members.length}
                                        </span>{" "}
                                        {t("employees")}
                                      </p>
                                    )}
                                    {item.agency_property.length > 0 && (
                                      <p>
                                        <span>
                                          {item.agency_property.length}
                                        </span>{" "}
                                        {t("listings")}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                        {!loading && (
                          <div className={classes.Pagination}>
                            <div className={classes.PageWrap}>
                              {currentPage > 1 && currentPage <= totalPages && (
                                <div
                                  className={
                                    activePage !== 1
                                      ? classes.NextPageActive
                                      : classes.NextPageInActive
                                  }
                                  onClick={() => {
                                    if (activePage !== 1) {
                                      setCurrentPage(currentPage - 1);
                                      setActivePage(currentPage - 1);
                                    }
                                  }}
                                >
                                  {"<"} <span>{t("prev_page")}</span>
                                </div>
                              )}
                              {pageNumber?.length >= 1 && (
                                <span className={classes.pageText}>
                                  {t("page")}
                                </span>
                              )}
                              {pageNumber?.length > 0 && (
                                <Pagination
                                  activePage={activePage}
                                  pageNumber={pageNumber}
                                  handlePageChange={handlePageChange}
                                  classespage={classes.page}
                                  classesActive={classes.Active}
                                  classesInactive={classes.InActive}
                                  myRef={myRef}
                                />
                              )}
                              {pageNumber?.length > 8 && (
                                <div className={classes.page}>
                                  {" "}
                                  <span className={classes.pageText}>
                                    {t("of")}
                                  </span>
                                  <span
                                    onClick={() => {
                                      handlePageChange(pageNumber.length);
                                    }}
                                    className={
                                      activePage === pageNumber.length
                                        ? classes.Active
                                        : classes.InActive
                                    }
                                  >
                                    {pageNumber.length}
                                  </span>
                                </div>
                              )}
                            </div>
                            {currentPage < totalPages && (
                              <div
                                className={
                                  activePage !== pageNumber.length - 1
                                    ? classes.NextPageActive
                                    : classes.NextPageInActive
                                }
                                onClick={() => {
                                  if (activePage !== pageNumber.length) {
                                    setCurrentPage(+currentPage + 1);
                                    setActivePage(+currentPage + 1);
                                  }
                                }}
                              >
                                <span>{t("next_page")}</span> {">"}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )
                  )}
                </div>
              </div>
            ) : (
              saveSearchOrganisationWithoutLogin?.count <= 0 &&
              t("no_results_found")
            )}
          </div>
        </form>
      </TemplateLayout>
    </React.Fragment>
  );
};
export default SearchOrganisationMain;
