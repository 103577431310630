import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { actionGetBannersApiCall } from "../../Redux/Actions";
import bannerClickHandler from "../../utils/bannerClickHandler";

const BannerImage = () => {
  const paramsId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let data = { id: paramsId };
    let body = {
      navigate,
      data,
    };
    dispatch(actionGetBannersApiCall(body));
  }, []);
  const bannerData =
    useSelector((state) => state.CommonReducer.saveBanner) || {};
  return (
    <>
      {bannerData && bannerData.url_original_file ? (
        <img
          onClick={() =>
            bannerClickHandler(
              bannerData?.agency_banner_id,
              bannerData?.id,
              bannerData?.agency_banners?.link_target,
              bannerData?.agency_banners?.redirection_link,
              navigate,
              dispatch
            )
          }
          src={bannerData.url_original_file}
        />
      ) : (
        <h2 style={{ textAlign: "center" }}>No Banner Found</h2>
      )}
    </>
  );
};

export default BannerImage;
