import React from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import classes from "./congratulationmain.module.scss";
import instasimpleIcon from "../../assets/icons/instasimple.svg";
import facebooksimpleIcon from "../../assets/icons/facebooksimple.svg";

const CongratulationsMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.CongratulationsContainer}>
          <div className={classes.CongratulationsHeading}>
            <h1>{t("congratulations")}</h1>
          </div>
          <div className={classes.CongratulationsDescription}>
            <p className={classes.soonText}>{t("your_listing_is_ready")}</p>
            <p>
              {t(
                "if_you_like_to_share_your_listing_on_social_media_do_so_below"
              )}
            </p>
          </div>
          <div className={classes.SocialIcon}>
            <div className={classes.IconContainer}>
              <img src={instasimpleIcon} alt="Instagram" />
              <p>{t("newz_marketing_media")}</p>
            </div>
            <div className={classes.IconContainer}>
              <img src={facebooksimpleIcon} alt="Facebook" />
              <p>{t("newz_marketing_media")}</p>
            </div>
          </div>
          <div className={classes.ReturnBtn}>
            <button>{t("return_to_my_account")}</button>
          </div>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default CongratulationsMain;
