import React from "react";
import { useLocation } from "react-router-dom";
function Pagination({
  activePage,
  pageNumber,
  handlePageChange,
  classespage,
  myRef,
  classesActive,
  classesInactive,
}) {
  const pathName = useLocation().pathname;
  const pagination = () => {
    let ui = [];
    for (
      let i = activePage <= 4 ? 1 : activePage - 4;
      i <= (activePage <= 4 ? 8 : activePage + 4);
      i++
    ) {
      if (i <= pageNumber.length) {
        ui.push(
          <div className={classespage}>
            <span
              onClick={() => {
                handlePageChange(i);
              }}
              className={activePage == i ? classesActive : classesInactive}
            >
              {i}
            </span>
          </div>
        );
      }
    }
    if (!pathName.includes("/view-organisation")) {
      myRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    return ui;
  };

  return pagination();
}

export default Pagination;
