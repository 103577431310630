import React, { useEffect, useRef, useState } from "react";
import classes from "./viewlistingphotos.module.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../layouts/Header/Index";
import Footer from "../../layouts/Footer/Index";
import { ImCross } from "react-icons/im";
import MainContainer from "../../layouts/MainContainer";
const ViewListingPhotos = () => {
  const { state } = useLocation();
  const singlePropertyDetails = useSelector(
    (state) => state.CommonReducer.singlePropertyDetails
  );
  const [indexImage, setIndexImage] = useState();
  const carouselRef = useRef(null);
  useEffect(() => {
    let index = singlePropertyDetails.image_list.findIndex(
      (item) => item.id === state.id
    );
    setIndexImage(index);
    carouselRef.current.goToSlide(index);
  }, [state.id]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <div>
      <Header />
      <div className={classes.ViewImageWrap}>
        <div className={classes.CrosIcon}>
          <ImCross onClick={() => window.history.back()} />
        </div>
        <MainContainer>
          <div className={classes.ViewListingImgWarp}>
            <Carousel
              ref={carouselRef}
              swipeable={true}
              draggable={true}
              responsive={responsive}
              ssr={true}
              infinite={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              transitionDuration={500}
              removeArrowOnDeviceType={["mobile"]}
            >
              {singlePropertyDetails.image_list.map((item, index) => {
                return (
                  <div className={classes.ImageWrap}>
                    <img src={item.url_normalized_file} />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </MainContainer>
      </div>
      <Footer />
    </div>
  );
};
export default ViewListingPhotos;
