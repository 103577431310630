import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import classes from "./addemployeemain.module.scss";
import EditPhoto from "../../assets/icons/EditIcon.svg";
import defaultImage from "../../assets/images/defaultImage.png";
import Validate from "../../utils/Validation";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddOrganisationEmployeeApiCall,
  actionGetEmployeeJobTitleApiCall,
} from "../../Redux/Actions";

const AddEmployeeMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [employeeDetails, setEmployeeDetails] = useState({
    employeeName: "",
    empJobTitle: "",
    phone: "",
    email: "",
  });
  const [employeeImagePreviewUrl, setEmployeeImagePreviewUrl] = useState();
  const [employeeImage, setEmployeeImage] = useState();
  const [error, setError] = useState();
  const saveEmployeeJobTitle = useSelector(
    (state) => state.CommonReducer.saveEmployeeJobTitle
  );
  const platform = localStorage.getItem("Platform");

  useEffect(() => {
    dispatch(actionGetEmployeeJobTitleApiCall({ navigate }));
  }, []);
  const handleChange = (e) => {
    let { name, value } = e.target;
    setEmployeeDetails({
      ...employeeDetails,
      [name]: value,
    });
    let error = Validate(name, value);
    setError(error);
  };
  const handleFileChange = (e) => {
    const files = e.target.files[0];
    const src = URL.createObjectURL(e.target.files[0]);
    setEmployeeImagePreviewUrl(src);
    setEmployeeImage(files);
    let error = Validate("employeeImage", src);
    setError(error);
  };

  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  const addEmployeeHandler = () => {
    let validateData = {
      employeeName: employeeDetails?.employeeName,
      empJobTitle: employeeDetails?.empJobTitle,
      phone: employeeDetails?.phone,
      email: employeeDetails?.email,
      employeeImage: employeeImagePreviewUrl,
    };
    let error = Validate("", "", true, validateData);
    setError(error);
    if (Object.values(error).every((x) => x == null || x == "")) {
      let formData = new FormData();
      formData.append("name", employeeDetails.employeeName);
      if (platform != "PROD") {
        formData.append("job_title", employeeDetails.empJobTitle);
      }
      formData.append("phone_no", employeeDetails.phone);
      formData.append("email", employeeDetails.email);
      formData.append("file", employeeImage);
      formData.append("agency_id", state.id);
      let body = {
        formData,
        navigate,
        agency_id: state.id,
      };
      dispatch(actionAddOrganisationEmployeeApiCall(body));
    }
  };

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.AddEmployeeContainer}>
          <div className={classes.AddEmployeeHeading}>
            <h1>{t("add_an_employee")}</h1>
          </div>
          <div className={classes.AddEmployeeDescription}>
            <div className={classes.InputContainer}>
              <p>{t("name_of_employee")}</p>
              <input
                type="text"
                placeholder={t("viktor_bakker")}
                name="employeeName"
                value={employeeDetails.employeeName}
                onChange={handleChange}
                maxLength={50}
              />
              {error?.employeeName && (
                <span className={`${classes.errorText}`}>
                  {error.employeeName}
                </span>
              )}
            </div>
            {platform != "PROD" && (
              <div className={classes.InputContainer}>
                <p>{t("job_title")}</p>
                <select
                  placeholder={t("salesperson")}
                  name="empJobTitle"
                  value={employeeDetails.empJobTitle}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    {`${t("select")}`}
                  </option>
                  {saveEmployeeJobTitle?.length > 0 &&
                    saveEmployeeJobTitle.map((option) => (
                      <option key={option.id} value={option?.value}>
                        {option?.translation}
                      </option>
                    ))}
                </select>
                {error?.empJobTitle && (
                  <span className={`${classes.errorText}`}>
                    {error.empJobTitle}
                  </span>
                )}
              </div>
            )}
            <div className={classes.PhoneAndEmailContainer}>
              <div className={classes.InputContainer}>
                <p>{t("phone_number")}</p>
                <input
                  type="text"
                  placeholder={t("zero_to_eight")}
                  name="phone"
                  value={employeeDetails.phone}
                  onChange={handleChange}
                  maxLength="10"
                  minLength="8"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={handleKeyPressForNumber}
                />
                {error?.phone && (
                  <span className={`${classes.errorText}`}>{error.phone}</span>
                )}
              </div>
              <div className={classes.InputContainer}>
                <p>{t("email")}</p>
                <input
                  type="text"
                  placeholder={t("viktor_lamberink")}
                  name="email"
                  value={employeeDetails.email}
                  onChange={handleChange}
                />
                {error?.email && (
                  <span className={`${classes.errorText}`}>{error.email}</span>
                )}
              </div>
            </div>
          </div>
          <div className={classes.UploadPictureEmployee}>
            <p>{t("upload_picture_of_employee")}</p>
            <div className={classes.UploadImgWrap}>
              <div className={classes.ImageWrap}>
                <img
                  src={
                    employeeImagePreviewUrl
                      ? employeeImagePreviewUrl
                      : defaultImage
                  }
                />
              </div>
              <input
                type="file"
                id="UploadPhoto"
                hidden
                onChange={handleFileChange}
                accept=".jpg, .jpeg, .png, .mv ,.webp,.svg"
              />
              <label htmlFor="UploadPhoto">
                <img src={EditPhoto} alt="Upload photo" />
              </label>
            </div>
            {error?.employeeImage && (
              <span className={`${classes.errorText}`}>
                {error.employeeImage}
              </span>
            )}
          </div>
          <div className={classes.AddEmployeeBtn}>
            <button onClick={() => addEmployeeHandler()}>
              {t("add_employee")}
            </button>
          </div>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default AddEmployeeMain;
