import React from "react";
import ComingSoonMain from "../../components/ComingSoonMain";

const ComingSoonPage = (props) => {
  return (
    <React.Fragment>
      <ComingSoonMain data={props} />
    </React.Fragment>
  );
};

export default ComingSoonPage;
