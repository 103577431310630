import classes from './displaycounter.module.scss';
import { useTranslation } from "react-i18next";
import React from 'react'; // Import React

export const DisplayCounter = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    return (
        <div className={classes.ContainerRoom}>
            <p>{t(props.heading)}</p>
            <input
                type="text"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onKeyDown={props.onKeyDown}
                maxLength={2}
                ref={ref}
                style={{ width: props.width }}
            />
            <span
                className={classes.BedroomRnge}
                onClick={() => {
                    if (props.value > 0) {
                        props.setAddPropertyData((prevState) => ({
                            ...prevState,
                            [props.name]: Number(props.value) - 1,
                        }));
                    }
                }}
            >
                -
            </span>
            <span
                className={classes.BedroomRnge}
                onClick={() => {
                    props.setAddPropertyData((prevState) => ({
                        ...prevState,
                        [props.name]: Number(props.value) + 1,
                    }));
                }}
            >
                +
            </span>
        </div>
    );
});