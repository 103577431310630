import React from "react";
import EditBannerMain from "../../components/EditBannerMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";

const EditBannerPage = () => {
	return (
		<React.Fragment>
			<Header />
			<EditBannerMain />
			<Footer />
		</React.Fragment>
	);
};

export default EditBannerPage;
