import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import MyBannersMain from "../../components/MyBannersMain";

const MyBannersPage = () => {
  return (
    <React.Fragment>
      <Header />
      <MyBannersMain />
      <Footer />
    </React.Fragment>
  );
};

export default MyBannersPage;
