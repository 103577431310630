import React from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import MolliePaymentLayout from "../layouts/MolliePaymentLayout";
import classes from "./editlistingmain.module.scss";
const EditListingMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.EditListingContainer}>
          <div className={classes.EditListHeading}>
            <h1>{t("mollie_payment")}</h1>
          </div>
          <MolliePaymentLayout />
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default EditListingMain;
