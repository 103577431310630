import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import MyListingLayoutTest from "../layouts/MyListingLayoutTest";

import classes from "./addlistingtest.module.scss";

const AddListingMainTest = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.AddListingContainer}>
          <div className={classes.AddListHeading}>
            <h1>{t("add_listing")}</h1>
          </div>
          <MyListingLayoutTest />
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default AddListingMainTest;
