import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import SearchOrganisationMain from "../../components/SearchOrganisationMain";
import TitleProvider from "../../components/TitleProvider";
import { useTranslation } from "react-i18next";

const SearchOrganisationPage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TitleProvider title={t("search_organisation")} />
      <Header />
      <SearchOrganisationMain />
      <Footer />
    </React.Fragment>
  );
};

export default SearchOrganisationPage;
