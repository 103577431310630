import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import OrganisationMain from "../../components/OrganisationMain";

const AddedOrganisationPage = () => {
  return (
    <React.Fragment>
      <Header />
      <OrganisationMain />
      <Footer />
    </React.Fragment>
  );
};

export default AddedOrganisationPage;
