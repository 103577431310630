import React from "react";
import { useState } from "react";
import classes from "./header.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoMain from "../../../assets/images/brandLogo.svg";
import LogoWhite from "../../../assets/images/LogoWhite.svg";
import PersonIson from "../../../assets/icons/PersonIcon.svg";
import PersonWhiteIson from "../../../assets/icons/PersonWhiteIcon.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  actionFooterStrapiApiCall,
  actionLogoutApiCall,
  actionResetState,
  actionSaveSearchOrganisationWithoutLogin,
} from "../../../Redux/Actions";
import HeaderContainer from "../HeaderContainer";
import { useRef } from "react";
import FooterRedirectionTab from "../../../utils/FooterRedirectionTab";
import { useEffect } from "react";

const Header = () => {
  const [clicked, setClicked] = useState(false);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = localStorage.getItem("authToken");
  const loginInformation = useSelector(
    (state) => state.CommonReducer.loginInfo
  );
  const saveHeaderStrapi = useSelector(
    (state) => state.CommonReducer.saveHeaderStrapi
  );
  const listRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const handleClick = () => {
    setClicked(!clicked);
    setShow(false);
  };
  React.useEffect(() => {
    dispatch(actionFooterStrapiApiCall({ navigate, title: "header" }));
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <React.Fragment>
      <div
        className={
          location == "/home" || location == "/"
            ? classes.NoboxShadow
            : classes.HeaderWrap
        }
      >
        <HeaderContainer>
          <div className={classes.HeaderMain}>
            <div
              className={classes.HeaderLeftSide}
              onClick={() => navigate("/home")}
            >
              <div className={classes.LogoWrap}>
                <img
                  src={
                    location == "/home" || location == "/"
                      ? LogoWhite
                      : LogoMain
                  }
                  alt="home"
                />
              </div>
            </div>
            <div className={classes.HeaderRightSide} ref={listRef}>
              <nav className={classes.NavLinks}>
                <ul
                  className={
                    clicked
                      ? `${classes.NavList}`
                      : `${classes.NavList} ${classes.close}`
                  }
                >
                  {saveHeaderStrapi?.data?.length > 0 &&
                    saveHeaderStrapi?.data?.map((item) => {
                      return (
                        item?.attributes?.Items?.length > 0 &&
                        item?.attributes?.Items?.map((val) => {
                          if (val?.ShowInDesktop && screenWidth > 768) {
                            return (
                              <li>
                                <Link
                                  to={FooterRedirectionTab(val)}
                                  target={
                                    item["__component"] === "menu.external-link"
                                      ? "_blank"
                                      : "_self"
                                  }
                                  onClick={() =>
                                    dispatch(
                                      actionSaveSearchOrganisationWithoutLogin(
                                        ""
                                      )
                                    )
                                  }
                                >
                                  {val?.Title.toLowerCase().includes(
                                    "search"
                                  ) && location == "/search-organisation"
                                    ? ""
                                    : val?.Title}
                                </Link>
                              </li>
                            );
                          } else if (val?.ShowInMobile && screenWidth < 768) {
                            return (
                              <li>
                                <Link
                                  to={FooterRedirectionTab(val)}
                                  target={
                                    item["__component"] === "menu.external-link"
                                      ? "_blank"
                                      : "_self"
                                  }
                                  onClick={() =>
                                    dispatch(
                                      actionSaveSearchOrganisationWithoutLogin(
                                        ""
                                      )
                                    )
                                  }
                                >
                                  {val?.Title.toLowerCase().includes(
                                    "search"
                                  ) && location == "/search-organisation"
                                    ? ""
                                    : val?.Title}
                                </Link>
                              </li>
                            );
                          } else if (
                            (val?.ShowInMobile && val?.ShowInDesktop) ||
                            (val?.ShowInMobile === null &&
                              val?.ShowInDesktop === null)
                          ) {
                            return (
                              <li>
                                <Link
                                  to={FooterRedirectionTab(val)}
                                  target={
                                    item["__component"] === "menu.external-link"
                                      ? "_blank"
                                      : "_self"
                                  }
                                  onClick={() =>
                                    dispatch(
                                      actionSaveSearchOrganisationWithoutLogin(
                                        ""
                                      )
                                    )
                                  }
                                >
                                  {val?.Title.toLowerCase().includes(
                                    "search"
                                  ) && location == "/search-organisation"
                                    ? ""
                                    : val?.Title}
                                </Link>
                              </li>
                            );
                          }
                        })
                      );
                    })}
                </ul>
                <ul className={classes.NavAuthList}>
                  {location !== "/login" && location !== "/create-account" && (
                    <li
                      className={classes.LoginLink}
                      onClick={() => navigate("/my-listing")}
                    >
                      <img
                        src={
                          location == "/home" || location == "/"
                            ? PersonWhiteIson
                            : PersonIson
                        }
                        alt="profile"
                      />
                      <p>
                        {!auth
                          ? t("login")
                          : loginInformation?.full_name
                          ? loginInformation?.full_name
                          : loginInformation?.email}
                      </p>
                    </li>
                  )}
                  <li className={classes.menu_icon} onClick={handleClick}>
                    {clicked ? <GrClose /> : <GiHamburgerMenu />}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </HeaderContainer>
      </div>
    </React.Fragment>
  );
};

export default Header;
