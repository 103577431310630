import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import ViewSingleOrganisationMain from "../../components/ViewSingleOrganisationMain";

const ViewSingleOrganisationPage = () => {
  return (
    <React.Fragment>
      <Header />
      <ViewSingleOrganisationMain />
      <Footer />
    </React.Fragment>
  );
};

export default ViewSingleOrganisationPage;
