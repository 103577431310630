import React, { useRef, useState } from "react";
import classes from "./vieworganisationmain.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  actionGetAgencyBannerClickCountApiCall,
  actionGetOrganisationDetailApiCall,
  actionLatestPropertyListingHomeApiCall,
  actionSaveLatestPropertyListingHome,
  actionSaveMyPropertyListing,
  actionSaveOrganisationDetail,
  actionSavePropertyType,
} from "../../Redux/Actions";
import defaultImage from "../../assets/images/defaultImage.png";
import MainContainer from "../layouts/MainContainer";
import EmailIcon from "../../assets/icons/EmainIcon.svg";
import ContactIcon from "../../assets/icons/ContactIcon.svg";
import { useTranslation } from "react-i18next";
import PropartySearchBar from "../layouts/PropartySearchBar";
import BuildBg from "../../assets/images/BuildBg.svg";
import PropertyCardMain from "../layouts/PropertyCardDesign";
import Loader from "../Loader";
import Pagination from "../Pagination";
import RedirectionTab from "../../utils/RedirectionTab";
import { AiOutlineGlobal } from "react-icons/ai";
const ViewOrganisationMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saveAgencyBannerRecommendations =
    useSelector(
      (state) => state.CommonReducer.saveAgencyBannerRecommendations
    ) || {};
  const organisationDetail = useSelector(
    (state) => state.CommonReducer.organisationDetail
  );
  console.log(organisationDetail,"organisssss")
  const { t } = useTranslation();
  const loading = useSelector((state) => state.CommonReducer.loading);
  const myPropertyListingData = useSelector(
    (state) => state?.CommonReducer?.myPropertyListingData?.rows
  );
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const OrgID = useParams();
  const count = useSelector(
    (state) => state.CommonReducer.myPropertyListingData?.count
  );
  const myRef = useRef();
  const [localLoading, setLocalLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    params.get("page") ? params.get("page") : 1
  );
  const [pageNumber, setPageNumber] = useState();
  const [activePage, setActivePage] = useState(
    params.get("page") ? params.get("page") : 1
  );
  const ITEMS_PER_PAGE = 4;
  const bannerSizes = ["160*600", "300*600"];
  // useEffect(() => {
  //   dispatch(actionSavePropertyType(""));
  //   let data = {
  //     id: OrgID.id,
  //   };
  //   let body = { data, navigate, title: "viewOrg" };
  //   dispatch(actionGetOrganisationDetailApiCall(body));
  // }, [OrgID.id]);

 useEffect(() => {
  // Function to fetch organization details
  const fetchOrganisationDetails = () => {
    window.scrollTo(0, 0);
    // Immediately clear previous organization details
    dispatch(actionSaveOrganisationDetail(""));
    // Immediately show the loader
    setLocalLoading(true);
    // Fetch new organization details
    const data = { id: OrgID.id };
    const body = { data, navigate, title: "viewOrg" };
    dispatch(actionGetOrganisationDetailApiCall(body));
  };

  // Initial fetch when OrgID.id changes
  fetchOrganisationDetails();

  // Handle refetch when the window gains focus
  const handleFocus = () => {
    fetchOrganisationDetails();
  };

  window.addEventListener("focus", handleFocus);

  // Cleanup event listener on component unmount
  return () => {
    window.removeEventListener("focus", handleFocus);
  };
}, [OrgID.id, navigate, dispatch]);

useEffect(() => {
  // Update loading state when data is loaded
  if (loading === false && organisationDetail && Object.keys(organisationDetail).length > 0) {
    // Ensure all details are loaded before hiding the loader
    const timer = setTimeout(() => {
      setLocalLoading(false);
    }, 1000); // Adjust delay as needed

    // Cleanup timeout on component unmount or when data changes
    return () => clearTimeout(timer);
  }
}, [loading, organisationDetail]);
  useEffect(() => {
    let agencyData = {
      agency_id: Number(OrgID.id),
      page: currentPage ? currentPage : params.get("page"),
      area: params.get("address") && params.get("address"),
      distance: params.get("distance") && params.get("distance"),
      minPrice: params.get("minPrice") && params.get("minPrice"),
      maxPrice: params.get("maxPrice") && params.get("maxPrice"),
      per_page: 4,
    };
    dispatch(actionLatestPropertyListingHomeApiCall(agencyData));
    let Address = params.get("address");
    let Distance = params.get("distance");
    let minPrice = params.get("minPrice");
    let maxPrice = params.get("maxPrice");
    const queryParams = [
      `page=${currentPage}`,
      Address ? `address=${Address}` : "",
      Distance ? `distance=${Distance}` : "",
      minPrice ? `minPrice=${minPrice}` : "",
      maxPrice ? `maxPrice=${maxPrice}` : "",
    ];
    const queryString = queryParams.filter(Boolean).join("&");
    navigate(
      `/view-organisation/${OrgID.city}/${OrgID.orgname}/${OrgID.id}?${queryString}`
    );
  }, [currentPage]);

  useEffect(() => {
    const pageNumbers = [];
    let totalPages = Math.ceil(count / ITEMS_PER_PAGE);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    setTotalPages(totalPages);
    setPageNumber(pageNumbers);
  }, [count]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
  };
  const BannerClickHandler = (
    agency_banner_id,
    agency_banner_size_id,
    link_target,
    redirection_link
  ) => {
    let RedirectionLinkData = {
      link: redirection_link,
      link_target: link_target,
    };
    RedirectionTab(RedirectionLinkData, navigate);
    let ClickData = {
      agency_banner_id: agency_banner_id,
      agency_banner_size_id: agency_banner_size_id,
    };
    let data = JSON.stringify(ClickData);
    const body = {
      navigate,
      data,
    };
    dispatch(actionGetAgencyBannerClickCountApiCall(body));
  };



  return (
    <>
      <div className={classes.OrgMainContainer}>
        {localLoading ?  <div className={classes.LoaderMain}>
          <Loader />
        </div> : 
        <>
         <div className={classes.OrgBannerContainer}>
          <img
            src={
              organisationDetail?.banner_image
                ? organisationDetail?.banner_image
                : defaultImage
            }
            alt={organisationDetail?.original_banner_name}
          />
        </div>
        <MainContainer>
          <div className={classes.OrgContainer}>
            <div className={classes.OrgInfoContainer}>
              <div className={classes.OrgContent}>
                <div className={classes.OrgLogoWrap}>
                  <img
                    src={
                      organisationDetail?.logo_image
                        ? organisationDetail?.logo_image
                        : defaultImage
                    }
                    alt={organisationDetail?.original_logo_name}
                  />
                </div>
                <div className={classes.OrgContentWrap}>
                  <h1>{organisationDetail?.name}</h1>
                  <p>{organisationDetail?.short_description}</p>
                  <div className={classes.OrgInfoWrap}>
                    {organisationDetail?.email && (
                      <div className={classes.OrgInfo}>
                        <img src={EmailIcon} alt="Email" />
                        <a href={`mailto:${organisationDetail?.email}`}>
                          {organisationDetail?.email}
                        </a>
                      </div>
                    )}
                    {organisationDetail?.phone && (
                      <div className={classes.OrgInfo}>
                        <img src={ContactIcon} alt="Phone" />
                        <a href={`tel:${organisationDetail?.phone}`}>
                          {organisationDetail?.phone}
                        </a>
                      </div>
                    )}
                    {organisationDetail?.website && (
                  <div className={classes.OrgInfo}>
                    <AiOutlineGlobal />
                    <a
                      href={organisationDetail?.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                     {organisationDetail?.website.startsWith("https://") ? organisationDetail?.website.slice(8) : organisationDetail?.website}  
                    </a>
                  </div>
                )}
                  </div>
                </div>
              </div>
              {organisationDetail?.description && (
                <div className={classes.DescriptionWrap}>
                  <h6>{t("description")}</h6>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: organisationDetail?.description,
                    }}
                  ></p>
                </div>
              )}
              {organisationDetail?.agency_members?.length > 0 &&
                organisationDetail?.agency_members.filter(
                  (item) => item.user_type === "Employee"
                )?.length > 0 && (
                  <div className={classes.EmployeInfo}>
                    <h6>{t("employees")}</h6>
                    <div className={classes.EmployeInfoWrap}>
                      {organisationDetail?.agency_members
                        .filter((item) => item.user_type === "Employee")
                        .map((item) => (
                          <div className={classes.SingleEmployeInfo}>
                            <div className={classes.EmployeImg}>
                              <img src={item.url_original_file} />
                            </div>
                            <div className={classes.EmployeDetails}>
                              <p className={classes.EmployeName}>
                                {item.full_name}
                              </p>
                              <p>{item.model.job_title}</p>
                              <p>{item.phone}</p>
                              <p>{item.email}</p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
            </div>
            <div className={classes.OrgMapContainer}>
  <div className={classes.MapContainer}>
    <iframe
      src={`https://maps.google.com/maps?q=${organisationDetail?.agency_address?.find(address => address.set_as_main_address)?.city}&t=&z=17&ie=UTF8&iwloc=&output=embed`}
      width="350px"
      height="250px"
    ></iframe>
  </div>
  {organisationDetail?.agency_address?.map((address, index) => (
    address?.set_as_main_address && (
      <div className={classes.OrgAddresContainer}>
        <h6>{t("info")}</h6>
        <p>{address.street} {address.house_no}</p>
        <p>{address.zipcode}</p>
        <p>{address.city}</p>
        {organisationDetail?.created_by?.phone && (
          <>
            <a href={`tel:${organisationDetail?.created_by?.phone}`}>
              {organisationDetail?.created_by?.phone}
            </a>
            <br />
          </>
        )}
        {organisationDetail?.created_by?.email && (
          <a href={`mailto:${organisationDetail?.created_by?.email}`}>
            {organisationDetail?.email}
          </a>
        )}
      </div>
    )
  ))}
</div>
          </div>
        </MainContainer>
        </>
         }
        <div className={classes.TopImageContainer}>
          <img src={BuildBg} className={classes.BackgroundTop} />
        </div>
        <MainContainer>
          <h6>{t("organisation_listings")}</h6>
        </MainContainer>
        <div className={classes.SearchMainContainer}>
          <MainContainer>
            <div className={classes.SearchBar}>
              <PropartySearchBar
                page={params.get("page") ? params.get("page") : 1}
                address={params.get("address") && params.get("address")}
                distance={params.get("distance") && params.get("distance")}
                minPrice={params.get("minPrice") && params.get("minPrice")}
                maxPrice={params.get("maxPrice") && params.get("maxPrice")}
              />
            </div>
          </MainContainer>
        </div>
        <MainContainer>
          <div className={classes.PropertyAndAdContainer}>
            <div className={classes.propertyList}>
              {localLoading ? (
                <Loader />
              ) : (
                <>
                  <PropertyCardMain
                    searchSplit={{
                      start: 0,
                      end: myPropertyListingData?.length,
                    }}
                    // noData={true}
                  />
                  <div className={classes.Pagination}>
                    <div className={classes.PageWrap}>
                      {currentPage > 1 && currentPage <= totalPages && (
                        <div
                          className={
                            activePage !== 1
                              ? classes.NextPageActive
                              : classes.NextPageInActive
                          }
                          onClick={() => {
                            if (activePage !== 1) {
                              setCurrentPage(currentPage - 1);
                              setActivePage(currentPage - 1);
                            }
                          }}
                        >
                          {"<"} <span>{t("prev_page")}</span>
                        </div>
                      )}
                      {pageNumber?.length >= 1 && (
                        <span className={classes.pageText}>{t("page")}</span>
                      )}
                      {pageNumber?.length > 0 && (
                        <Pagination
                          activePage={activePage}
                          pageNumber={pageNumber}
                          handlePageChange={handlePageChange}
                          classespage={classes.page}
                          classesActive={classes.Active}
                          classesInactive={classes.InActive}
                          myRef={myRef}
                        />
                      )}
                      {pageNumber?.length > 8 && (
                        <div className={classes.page}>
                          {" "}
                          <span className={classes.pageText}>{t("of")}</span>
                          <span
                            onClick={() => {
                              handlePageChange(pageNumber.length);
                            }}
                            className={
                              activePage === pageNumber.length
                                ? classes.Active
                                : classes.InActive
                            }
                          >
                            {pageNumber.length}
                          </span>
                        </div>
                      )}
                    </div>
                    {currentPage < totalPages && (
                      <div
                        className={
                          activePage !== pageNumber.length - 1
                            ? classes.NextPageActive
                            : classes.NextPageInActive
                        }
                        onClick={() => {
                          if (activePage !== pageNumber.length) {
                            setCurrentPage(+currentPage + 1);
                            setActivePage(+currentPage + 1);
                          }
                        }}
                      >
                        <span>{t("next_page")}</span> {">"}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={classes.AdsWrap}>
              {Object.keys(saveAgencyBannerRecommendations).length > 0 &&
                bannerSizes.map((size) => {
                  const banner = saveAgencyBannerRecommendations[size];
                  if (banner) {
                    return (
                      <div
                        className={classes.AdsContentWrap}
                        onClick={() => {
                          BannerClickHandler(
                            banner.agency_banner_id,
                            banner.id,
                            banner.agency_banners.link_target,
                            banner.agency_banners.redirection_link
                          );
                        }}
                      >
                        {banner.url_original_file ? (
                          <img
                            src={banner.url_original_file}
                            className={classes[`Banner${banner.size}`]}
                            alt=""
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{ __html: banner.iframe }}
                          ></div>
                        )}
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </MainContainer>
      </div>
    </>
  );
};
export default ViewOrganisationMain;
