import React, { useEffect } from "react";
import AddOrganisationMain from "../../components/AddOrganisationMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";

const AddOrganisationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Header />
      <AddOrganisationMain />
      <Footer />
    </React.Fragment>
  );
};

export default AddOrganisationPage;
