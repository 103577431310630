export function seo(data = {}) {
  const { displayGraph, metaTags } = data;

  const existingMetaTags = document.querySelectorAll('meta[property^="og:"]');
  existingMetaTags.forEach((tag) => {
    tag.remove();
  });

  if (displayGraph) {
    if (metaTags?.length > 0) {
      metaTags.forEach((tag) => {
        const metaElement = document.createElement("meta");
        metaElement.setAttribute("property", tag.property);
        metaElement.setAttribute("content", tag.content);
        document.head.appendChild(metaElement);
      });
    }

    data.title = data.title || "Woningmarkt.nl";
    data.metaDescription = data.metaDescription || "";

    document.title = data.title;
    document
      ?.querySelector('meta[name="description"]')
      ?.setAttribute("content", data.metaDescription);
  } else {
    data.title = data.title || "Woningmarkt.nl";
    data.metaDescription = data.metaDescription || "";

    document.title = data.title;
    document
      ?.querySelector('meta[name="description"]')
      ?.setAttribute("content", data.metaDescription);
  }
}
