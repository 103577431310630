import { t } from "i18next";
import { useState } from "react";
const useForm = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [check, setCheck] = useState(true);
  const validate = (name, value) => {
    if (check) {
      setErrors((previousInputs) => ({
        ...previousInputs,
        [name]: "",
      }));
    } else {
      switch (name) {
        case "email":
          if (value.length > 0) {
            if (
              !new RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              ).test(value)
            ) {
              setErrors({
                ...errors,
                email: t("email_invalid"),
              });
            } else {
              setErrors({
                ...errors,
                email: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              email: t("email_required"),
            });
          }
          break;
        case "password":
          if (value.length > 0) {
            if (value.length < 8) {
              setErrors({
                ...errors,
                password: t("minimum_eight_characters_for_password"),
              });
            } else {
              setErrors({
                ...errors,
                password: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              password: t("password") + " " + t("required"),
            });
          }
          break;
        case "confirmPassword":
          if (value.length > 0) {
            if (values.password != values.confirmPassword) {
              setErrors({
                ...errors,
                confirmPassword: t(
                  "password_and_confirm_password_does_not_match"
                ),
              });
            } else {
              setErrors({
                ...errors,
                confirmPassword: "",
              });
            }
          } else {
            setErrors({
              ...errors,
              confirmPassword: t("confirm_password_required"),
            });
          }
          break;
        default:
          break;
      }
    }
  };
  const handleSubmit = async () => {
    let errorData = {
      email:
        values.email.length > 0
          ? !new RegExp(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ).test(values.email)
            ? t("email_invalid")
            : ""
          : t("email_required"),
      password:
        values.password.length > 0
          ? values.password.length < 8
            ? t("minimum_eight_characters_for_password")
            : ""
          : t("password") + " " + t("required"),
      confirmPassword:
        values.confirmPassword.length > 0 ? "" : t("confirm_password_required"),
    };
    setErrors(errorData);
    return errorData;
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (check) validate(name, value);
    setValues({
      ...values,
      [name]: value,
    });
  };
  const onFocus = (event) => {
    let { name, value } = event.target;
    setCheck(false);
    validate(name, value);
  };
  const onBlur = (event) => {
    let { name, value } = event.target;
    setCheck(true);
    validate(name, value);
  };
  return {
    values,
    errors,
    handleChange,
    onFocus,
    onBlur,
    handleSubmit,
  };
};
export default useForm;
