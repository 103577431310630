import React, { useState } from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import { useEffect } from "react";
import ViewOrganisationMain from "../../components/ViewOrganisationMain";
import viewListingTopAds from "../../assets/images/viewListingTopAds.png";
import AdvertisementLayout from "../../components/layouts/AdvertisementLayout";
import { actionGetAgencyBannerRecommendationsApiCall } from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ViewOrganisationPage = () => {
  const [AdsData, setAdsData] = useState([
    {
      id: 1,
      url_original_file: "",
      link: "",
      link_target: "",
      agency_banner_id: "",
    },
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saveAgencyBannerRecommendations = useSelector(
    (state) => state.CommonReducer.saveAgencyBannerRecommendations
  );
  useEffect(() => {
    const randomSizes = ["336*280", "160*600", "300*600"];

    let data = {
      bannerSizes: ["970*250", randomSizes],
    };
    let body = {
      navigate,
      data,
    };
    dispatch(actionGetAgencyBannerRecommendationsApiCall(body));
  }, []);
  useEffect(() => {
    if (
      saveAgencyBannerRecommendations &&
      saveAgencyBannerRecommendations["970*250"]
    ) {
      setAdsData([
        {
          id: saveAgencyBannerRecommendations["970*250"].id,
          url_original_file:
            saveAgencyBannerRecommendations["970*250"].url_original_file,
          link: saveAgencyBannerRecommendations["970*250"].agency_banners
            .redirection_link,
          link_target:
            saveAgencyBannerRecommendations["970*250"].agency_banners
              .link_target,
          agency_banner_id:
            saveAgencyBannerRecommendations["970*250"].agency_banner_id,
        },
      ]);
    }
  }, [saveAgencyBannerRecommendations]);
  return (
    <React.Fragment>
      {AdsData[0].url_original_file && (
        <AdvertisementLayout adsData={AdsData} />
      )}
      <Header />
      <ViewOrganisationMain />
      <Footer />
    </React.Fragment>
  );
};

export default ViewOrganisationPage;
