import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import AddListingMainTest from "../../components/AddListingMainTest";

const AddListingPageTest = () => {
  return (
    <React.Fragment>
      <Header />
      <AddListingMainTest />
      <Footer />
    </React.Fragment>
  );
};

export default AddListingPageTest;
