import React from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import classes from "./connectionmain.module.scss";
import instagram from "../../assets/icons/instagram.svg";
import facebook from "../../assets/icons/facebook.svg";

const ConnectionMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.ConnectionContainer}>
          <div className={classes.TitleWrap}>
            <h1>{t("connections")}</h1>
          </div>
          <p className={classes.MainTitle}>{t("social_media")}</p>
          <div className={classes.media}>
            <div className={classes.ImageContainer}>
              <img src={instagram} alt="Instagram" />
            </div>
            <p>{t("connect_with_instagram")}</p>
          </div>
          <div className={classes.media}>
            <div className={classes.ImageContainer}>
              <img src={facebook} alt="Facebook" />
            </div>
            <p>{t("connect_with_facebook")}</p>
          </div>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default ConnectionMain;
