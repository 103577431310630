import React from "react";
import classes from "./adsmain.module.scss";
import MainContainer from "../../layouts/MainContainer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bannerClickHandler from "../../../utils/bannerClickHandler";

const AdsMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const saveHomeStrapi = useSelector(
    (state) => state.CommonReducer.saveHomeStrapi
  );
  const saveAgencyBannerRecommendations =
    useSelector(
      (state) => state.CommonReducer.saveAgencyBannerRecommendations
    ) || {};
  const HomeAd1 = saveAgencyBannerRecommendations?.["Home Ad 1"];
  const HomeAd2 = saveAgencyBannerRecommendations?.["Home Ad 2"];
  const HomeAd3 = saveAgencyBannerRecommendations?.["Home Ad 3"];
  const HomeAd4 = saveAgencyBannerRecommendations?.["Home Ad 4"];

  const AdComponent = ({ adData }) => {
    const handleClick = () => {
      bannerClickHandler(
        adData?.agency_banner_id,
        adData?.id,
        adData?.agency_banners?.link_target,
        adData?.agency_banners?.redirection_link,
        navigate,
        dispatch
      );
    };
    return (
      <div className={classes.ImgWrap} onClick={handleClick}>
        {adData.url_original_file ? (
          <img src={adData.url_original_file} alt={adData.original_file_name} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: adData.iframe }}></div>
        )}
      </div>
    );
  };
  return (
    <React.Fragment>
      <MainContainer>
        <div className={classes.AdsSection}>
          <div className={classes.TextAds}>
            {saveHomeStrapi?.data?.length > 0 &&
              saveHomeStrapi?.data?.map((item) => (
                <div className={classes.AdsWrap}>
                  <h2>{item?.attributes?.Title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item?.attributes?.Description,
                    }}
                  ></p>
                </div>
              ))}
          </div>
          <div className={classes.ImgAds}>
            <div className={classes.ImgAdsWrap}>
              {HomeAd1 && <AdComponent adData={HomeAd1} />}
              {HomeAd2 && <AdComponent adData={HomeAd2} />}
              {HomeAd3 && <AdComponent adData={HomeAd3} />}
              {HomeAd4 && <AdComponent adData={HomeAd4} />}
            </div>
          </div>
        </div>
      </MainContainer>
    </React.Fragment>
  );
};

export default AdsMain;
