import React from "react";
import Modal from "react-bootstrap/Modal";

const ModalMain = (props) => {
  return (
    <Modal
      show={props.showmodel}
      onHide={props.handleClose}
      // size={props.size}
      centered
      size="lg"
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export default ModalMain;
