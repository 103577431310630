import React from "react";
import classes from "./sendcontactanddisplayads.module.scss";

import { useNavigate, useParams } from "react-router-dom";
import {
  actionContactUsInfoApiCall,
  actionGetAgencyBannerClickCountApiCall,
} from "../../Redux/Actions";
import Validate from "../../utils/Validation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BuildBg from "../../assets/images/BuildBg.svg";
import RedirectionTab from "../../utils/RedirectionTab";

const SendContactAndDisplayAds = (props) => {
  const saveAgencyBannerRecommendations =
    useSelector(
      (state) => state.CommonReducer.saveAgencyBannerRecommendations
    ) || {};
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState();
  const params = useParams();
  const dispatch = useDispatch();

  const [contactInfo, setContactInfo] = useState({
    contactName: "",
    email: "",
    phone: "",
  });
  const bannerSizes = ["336*280", "160*600", "300*600"];
  const contactInfoHandler = (e) => {
    e.preventDefault();
    let error = Validate("", "", true, contactInfo);
    setError(error);
    if (Object.values(error).every((x) => x == null || x == "")) {
      let data = {
        name: contactInfo.contactName,
        email: contactInfo.email,
        phone: contactInfo.phone,
        property_id: params.id,
      };
      let body = { data, navigate };
      dispatch(actionContactUsInfoApiCall(body));
      setContactInfo({
        contactName: "",
        email: "",
        phone: "",
      });
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setContactInfo({
      ...contactInfo,
      [name]: value,
    });
    let error = Validate(name, value);
    setError(error);
  };
  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };

  const BannerClickHandler = (
    agency_banner_id,
    agency_banner_size_id,
    link_target,
    redirection_link
  ) => {
    let RedirectionLinkData = {
      link: redirection_link,
      link_target: link_target,
    };
    RedirectionTab(RedirectionLinkData, navigate);
    let ClickData = {
      agency_banner_id: agency_banner_id,
      agency_banner_size_id: agency_banner_size_id,
    };
    let data = JSON.stringify(ClickData);
    const body = {
      navigate,
      data,
    };
    dispatch(actionGetAgencyBannerClickCountApiCall(body));
  };
  return (
    <React.Fragment>
      <div className={classes.ContactAndAdsWrap}>
        <div className={classes.ContactContainer}>
          <div className={classes.BackgroundTopContainer}>
            <img src={BuildBg} className={classes.BackgroundTop} />
          </div>
          <div className={classes.ListingOrgImg}>
            <div className={classes.FormContainer}>
              <h5>{t("are_you_interested_in_this_property")}</h5>
              <form>
                <div className={classes.Inputbox}>
                  <input
                    type="text"
                    placeholder={t("name_placeholder")}
                    name="contactName"
                    value={contactInfo.contactName}
                    onChange={handleChange}
                  />
                  {error?.contactName && (
                    <span className={`${classes.errorText}`}>
                      {error.contactName}
                    </span>
                  )}
                </div>
                <div className={classes.Inputbox}>
                  <input
                    type="email"
                    placeholder={t("email_placeholder")}
                    name="email"
                    value={contactInfo.email}
                    onChange={handleChange}
                  />
                  {error?.email && (
                    <span className={`${classes.errorText}`}>
                      {error.email}
                    </span>
                  )}
                </div>
                <div className={classes.Inputbox}>
                  <input
                    type="text"
                    placeholder={t("phone_placeholder")}
                    name="phone"
                    value={contactInfo.phone}
                    onChange={handleChange}
                    maxLength="10"
                    minLength="8"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    onKeyDown={handleKeyPressForNumber}
                  />
                  {error?.phone && (
                    <span className={`${classes.errorText}`}>
                      {error.phone}
                    </span>
                  )}
                </div>
                <button onClick={contactInfoHandler}>
                  {t("send_contact_info")}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className={classes.LeftBox}>
          {Object.keys(saveAgencyBannerRecommendations).length > 0 &&
            bannerSizes.map((size) => {
              const banner = saveAgencyBannerRecommendations[size];
              if (banner) {
                return (
                  <div
                    className={classes.AdsContentWrap}
                    onClick={() => {
                      BannerClickHandler(
                        banner.agency_banner_id,
                        banner.id,
                        banner.agency_banners.link_target,
                        banner.agency_banners.redirection_link
                      );
                    }}
                  >
                    {banner.url_original_file ? (
                      <img
                        src={banner.url_original_file}
                        className={classes[`Banner${banner.size}`]}
                        alt=""
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{ __html: banner.iframe }}
                      ></div>
                    )}
                  </div>
                );
              }
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SendContactAndDisplayAds;
