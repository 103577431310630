import React, { useState, useEffect, useRef } from "react";
import AccountMain from "../layouts/Account";
import AuthLayout from "../layouts/AuthLayout";
import classes from "./changeaccountinfomain.module.scss";
import { MdPersonOutline } from "react-icons/md";
import OffcanvasMain from "../layouts/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import {
  actionEditAccountInfoApiCall,
  actionSaveLoginInfo,
} from "../../Redux/Actions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainContainer from "../layouts/MainContainer";
import { toast } from "react-toastify";
import defaultImage from "../../assets/images/defaultImage.png";

const ChangeAccountInfoMain = () => {
  const [account, setAccount] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loginInformation = useSelector(
    (state) => state.CommonReducer.loginInfo
  );
  const loading = useSelector((state) => state.CommonReducer.loading);

  const navigate = useNavigate();
  const [file, setFile] = useState(loginInformation.url_original_file);
  const [fileValue, setFileValue] = useState();

  const [accountInfo, setAccountInfo] = useState({
    name:
      typeof loginInformation != "undefined" && loginInformation?.full_name
        ? loginInformation.full_name
        : "",
    password: loginInformation.password ? loginInformation.password : "",
    confirmPassword: loginInformation.password_confirmation
      ? loginInformation.password_confirmation
      : "",
    email: loginInformation.email,
  });

  const handleFileChange = (e) => {
    const files = e.target.files[0];
    const src = URL.createObjectURL(e.target.files[0]);
    setFile(src);
    setFileValue(files);
    dispatch(
      actionSaveLoginInfo({
        ...loginInformation,
        full_name: accountInfo.name,
        url_original_file: src,
      })
    );
  };
  const handleProfileChange = () => {
    inputRef.current?.click();
  };
  const editHandler = (e) => {
    e.preventDefault();
    let data = {
      full_name: accountInfo.name,
      password: accountInfo.password,
      password_confirmation: accountInfo.confirmPassword,
    };
    let body = { data, navigate, fileValue };
    if (accountInfo.password !== accountInfo.confirmPassword) {
      toast.error(t("password_and_confirm_password_does_not_match"));
    } else {
      if (accountInfo.password && accountInfo.confirmPassword) {
        if (
          accountInfo.password.length < 8 ||
          accountInfo.confirmPassword.length < 8
        ) {
          toast.error(t("minimum_eight_characters_for_password"));
        } else {
          dispatch(actionEditAccountInfoApiCall(body));
          dispatch(
            actionSaveLoginInfo({
              ...loginInformation,
              full_name: accountInfo.name,
              password: accountInfo.password,
              password_confirmation: accountInfo.confirmPassword,
            })
          );
        }
      } else {
        dispatch(actionEditAccountInfoApiCall(body));
        dispatch(
          actionSaveLoginInfo({
            ...loginInformation,
            full_name: accountInfo.name,
            password: accountInfo.password,
            password_confirmation: accountInfo.confirmPassword,
          })
        );
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const accountShow = () => {
    setAccount(true);
  };

  const handleClose = () => {
    setAccount(false);
  };
  const handleKeyPress = (event) => {
    const selectionStart = event.target.selectionStart;

    if (selectionStart === 0 && event.which === 32) {
      event.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <AuthLayout>
        <div className={classes.accountSection}>
          <MainContainer>
            <div className={classes.AccountWrap}>
              <div className={classes.LeftSide}>
                {screenWidth > 768 && (
                  <div className={classes.AccountMainWrap}>
                    <AccountMain />
                  </div>
                )}
                <div className={classes.BtnWrap}>
                  <button onClick={accountShow}>
                    <MdPersonOutline />
                    {t("my_account")}
                  </button>
                </div>
              </div>
              <div className={classes.RigthSide}>
                <form>
                  <div className={classes.accountInfoSection}>
                    <div className={classes.accountInfoHeading}>
                      <h2>{t("change_account_information")}</h2>
                    </div>
                    <div className={classes.accountMainWrap}>
                      <div className={classes.accountInfoContaint}>
                        <div className={classes.inputBox}>
                          <p>{t("name")}</p>
                          <input
                            type="text"
                            name="name"
                            value={accountInfo.name}
                            onChange={handleChange}
                            maxLength={200}
                            onKeyDown={handleKeyPress}
                          />
                        </div>
                        <div className={classes.inputBox}>
                          <p>{t("email")}</p>
                          <input
                            type="text"
                            name="email"
                            value={accountInfo.email}
                            disabled
                          />
                        </div>
                        <div className={classes.inputBox}>
                          <p>{t("password")}</p>
                          <input
                            type="text"
                            name="password"
                            value={accountInfo.password.replace(/\s/g, "")}
                            onChange={handleChange}
                          />
                        </div>
                        <div className={classes.inputBox}>
                          <p>{t("confirm_password")}</p>
                          <input
                            type="text"
                            name="confirmPassword"
                            value={accountInfo.confirmPassword.replace(
                              /\s/g,
                              ""
                            )}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className={classes.imgContainer}>
                        <img
                          src={file ? file : defaultImage}
                          onClick={handleProfileChange}
                        />
                        <input
                          ref={inputRef}
                          style={{ display: "none" }}
                          type="file"
                          accept=".jpg, .jpeg, .png, .mv ,.webp,.svg"
                          onChange={handleFileChange}
                        />
                        <p onClick={handleProfileChange}>
                          {t("change_profile_picture")}
                        </p>
                      </div>
                    </div>
                    <div className={classes.changeBtnWrap}>
                      <button
                        className={classes.saveChangeBtn}
                        onClick={editHandler}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          t("save_changes")
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </MainContainer>
          <OffcanvasMain showcanvas={account} handleClose={handleClose}>
            <AccountMain />
          </OffcanvasMain>
        </div>
      </AuthLayout>
    </React.Fragment>
  );
};

export default ChangeAccountInfoMain;
