import React, { useState, useEffect } from "react";
import classes from "./savedfiltermain.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionAddSavedFilterApiCall,
  actionSavedFilterApiCall,
} from "../../Redux/Actions";
import MainLayout from "../layouts/MainLayout";
const SavedFilterMain = () => {
  const state_id = localStorage.getItem("user_id");
  const [data, setData] = useState({
    user_id: state_id,
    is_sell: 1,
    is_rent: 1,
  });
  const state = useSelector((state) => state.CommonReducer.savedFilterData);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (state) {
      let a = state[0];
      setData({ ...data, ...a });
    }
  }, [state]);
  useEffect(() => {
    const data = { page: 1 };
    const body = { data, navigate };
    setData({ ...data, ["is_sell"]: 1, ["is_rent"]: 1 });
    dispatch(actionSavedFilterApiCall(body));
  }, []);

  const array = [1, 2, 3, 4, 5, 6, 7];
  const saveChanges = () => {
    const body = { data, navigate };
    dispatch(actionAddSavedFilterApiCall(body));
  };
  const searchWithFilterHandler = (e) => {
    e.preventDefault();
    const queryParams = [
      data.area ? `address=${data.area}` : "",
      data.price_from ? `minPrice=${data.price_from}` : "",
      data.price_to ? `maxPrice=${data.price_to}` : "",
      data.energy_class ? `energyClass=${data.energy_class}` : "",
      data.property_type ? `propertyType=${data.property_type}` : "",
      data.construction_year
        ? `constructionYear=${data.construction_year}`
        : "",
      data.is_sell == 1 && data.is_rent == 0 ? `listingType=sell` : "",
      data.is_sell == 0 && data.is_rent == 1 ? `listingType=rent` : "",
      data.is_sell == 1 && data.is_rent == 1 ? `listingType=both` : "",
    ];
    const queryString = queryParams.filter(Boolean).join("&");
    navigate(`/search?${queryString}`);
  };
  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.SavedFilterSection}>
          <div className={classes.TitleWrap}>
            <h1>{t("saved_filter")}</h1>
          </div>
          <div className={classes.BtnWarp}>
            <p className={classes.MainTitle}>{t("type_of_listing")}</p>
            <div className={classes.TypeOfListing}>
              <button
                className={
                  data.is_sell == 1 && data.is_rent == 0
                    ? classes.SelectedBtn
                    : classes.unselctedButton
                }
                onClick={() =>
                  setData({ ...data, ["is_sell"]: 1, ["is_rent"]: 0 })
                }
              >
                {t("buy")}
              </button>
              <button
                className={
                  data.is_sell == 0 && data.is_rent == 1
                    ? classes.SelectedBtn
                    : classes.unselctedButton
                }
                onClick={() =>
                  setData({ ...data, ["is_sell"]: 0, ["is_rent"]: 1 })
                }
              >
                {t("rent")}
              </button>
              <button
                className={
                  data.is_sell == 1 && data.is_rent == 1
                    ? classes.SelectedBtn
                    : classes.unselctedButton
                }
                onClick={() =>
                  setData({ ...data, ["is_sell"]: 1, ["is_rent"]: 1 })
                }
              >
                {t("both")}
              </button>
            </div>
          </div>
          <div className={classes.SavedFilterFormWrap}>
            <div className={classes.divWrap}>
              <p className={classes.MainTitle}>{t("area")}</p>
              <input
                placeholder={t("area_neighbourhood_address_etc")}
                name="area"
                onChange={(e) =>
                  setData({ ...data, [e.target.name]: e.target.value })
                }
                defaultValue={data.area ? data.area : ""}
              />
            </div>
            <div className={classes.divWrap}>
              <p className={classes.MainTitle}>{t("price_range")}</p>
              <div className={classes.pricerangewrapp}>
                <input
                  placeholder={t("from_minprice_savedfilter")}
                  type="number"
                  name="price_from"
                  min={1}
                  onChange={(e) =>
                    setData({ ...data, [e.target.name]: e.target.value })
                  }
                  defaultValue={data.price_from ? data.price_from : ""}
                />
                <input
                  placeholder={t("from_maxprice_savedfilter")}
                  type="number"
                  name="price_to"
                  min={1}
                  onChange={(e) =>
                    setData({ ...data, [e.target.name]: e.target.value })
                  }
                  defaultValue={data.price_to ? data.price_to : ""}
                />
              </div>
              <hr />
            </div>
            <div className={classes.PropertyselectOrption}>
              <p>{t("building_properties")}</p>
              <p className={classes.MainTitle}>{t("type_of_property")}</p>
              <div className={classes.radioContainer}>
                <div className={classes.EnergyLabel}>
                  <input
                    type="radio"
                    id="Energy"
                    name="property_type"
                    className={classes.radioCustom}
                    onChange={(e) =>
                      setData({ ...data, [e.target.name]: e.target.value })
                    }
                    value={t("detached")}
                    checked={
                      data.property_type == `${t("detached")}` ? true : false
                    }
                  />
                  <label htmlFor="Energy" className={classes.radioCustomLabel}>
                    {"  "}
                    {t("detached")}
                  </label>
                </div>
                <div className={classes.EnergyLabel}>
                  <input
                    type="radio"
                    id="Energy1"
                    name="property_type"
                    onChange={(e) =>
                      setData({ ...data, [e.target.name]: e.target.value })
                    }
                    checked={
                      data.property_type == `${t("semi_detached")}`
                        ? true
                        : false
                    }
                    value={t("semi_detached")}
                    className={classes.radioCustom}
                  />
                  <label htmlFor="Energy1" className={classes.radioCustomLabel}>
                    {"  "}
                    {t("semi_detached")}
                  </label>
                </div>
              </div>
              <div className={classes.divWrap}>
                <p className={classes.MainTitle}>
                  {t("construction_year_starting_from")}
                </p>
                <div className={classes.menuDiv}>
                  <input
                    type="text"
                    placeholder="1918"
                    name="construction_year"
                    onChange={(e) =>
                      setData({
                        ...data,
                        [e.target.name]: e.target.value.slice(0, 4),
                      })
                    }
                    defaultValue={
                      data.construction_year ? data.construction_year : ""
                    }
                    onKeyDown={handleKeyPressForNumber}
                    maxLength={4}
                  />
                </div>
              </div>
              <hr />
            </div>
            <div className={classes.PropertyselectOrption}>
              <p>{t("energy")}</p>
              <p className={classes.MainTitle}>
                {t("energy_label")} - {t("starting_from")}
              </p>
              {array.map((item, index) => {
                return (
                  <div className={classes.EnergyLabel}>
                    <input
                      type="radio"
                      id={`energy_${item}${index}`}
                      name="energy_class"
                      className={classes.radioCustom}
                      value={t(`energy_${item}`)}
                      onChange={(e) =>
                        setData({
                          ...data,
                          [e.target.name]: e.target.value,
                        })
                      }
                      checked={
                        data.energy_class == `${t(`energy_${item}`)}`
                          ? true
                          : false
                      }
                    />
                    <label
                      htmlFor={`energy_${item}${index}`}
                      className={classes.radioCustomLabel}
                    >
                      {" "}
                      {t(`energy_${item}`)}
                    </label>
                  </div>
                );
              })}
              <div className={classes.PropertyselectOrption}>
                <p className={classes.MainTitle}>{t("warm_water")}</p>
                <div className={classes.EnergyLabel}>
                  <input
                    type="radio"
                    id="Energy Warm water"
                    name="warm_water"
                    className={classes.radioCustom}
                    value={t("boiler")}
                    onChange={(e) =>
                      setData({
                        ...data,
                        [e.target.name]: e.target.value,
                      })
                    }
                    checked={data.warm_water == `${t("boiler")}` ? true : false}
                  />
                  <label
                    htmlFor="Energy Warm water"
                    className={classes.radioCustomLabel}
                  >
                    {" "}
                    {t("boiler")}
                  </label>
                </div>
                <div className={classes.EnergyLabel}>
                  <input
                    type="radio"
                    id="Energy Lorem"
                    name="warm_water"
                    className={classes.radioCustom}
                    value={t("lorem")}
                    onChange={(e) =>
                      setData({
                        ...data,
                        [e.target.name]: e.target.value,
                      })
                    }
                    checked={data.warm_water == `${t("lorem")}` ? true : false}
                  />
                  <label
                    htmlFor="Energy Lorem"
                    className={classes.radioCustomLabel}
                  >
                    {" "}
                    {t("lorem")}
                  </label>
                </div>
                <div className={classes.EnergyLabel}>
                  <input
                    type="radio"
                    id="Energy Ipsum"
                    name="warm_water"
                    className={classes.radioCustom}
                    value={t("ipsum")}
                    onChange={(e) =>
                      setData({
                        ...data,
                        [e.target.name]: e.target.value,
                      })
                    }
                    checked={data.warm_water == `${t("ipsum")}` ? true : false}
                  />
                  <label
                    htmlFor="Energy Ipsum"
                    className={classes.radioCustomLabel}
                  >
                    {" "}
                    {t("ipsum")}
                  </label>
                </div>
              </div>
              <div className={classes.SaveFilterBtnWrap}>
                <button className={classes.saveButton} onClick={saveChanges}>
                  {t("save_changes")}
                </button>
                <button
                  className={classes.searchButton}
                  onClick={searchWithFilterHandler}
                >
                  {t("search_with_filter")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};
export default SavedFilterMain;
