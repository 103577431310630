import React, { useEffect, useState } from "react";
import classes from "./thankyoumain.module.scss";
import TemplateLayout from "../layouts/TemplateLayout";
import { useTranslation } from "react-i18next";
import MainImg from "../../assets/icons/CongratulationsIcon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionGetHomeBannerApiCall } from "../../Redux/Actions";

const ThankyouMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const savePaymentSuccessBanner = useSelector(
    (state) => state.CommonReducer.saveHomeBanner
  );
  const [paymentSuccessBanner, setPaymentSuccessBanner] = useState();

  useEffect(() => {
    dispatch(actionGetHomeBannerApiCall({ navigate }));
  }, []);

  useEffect(() => {
    savePaymentSuccessBanner?.rows?.length > 0 &&
      savePaymentSuccessBanner?.rows?.find((item, index) => {
        if (item.position === "Thankyou") {
          setPaymentSuccessBanner(item.url_original_file);
        }
      });
  }, [savePaymentSuccessBanner]);
  return (
    <React.Fragment>
      <TemplateLayout title={t("thankyou")} image={paymentSuccessBanner}>
        <div className={classes.FiledPaymentSection}>
          <div className={classes.FiledPaymentContent}>
            <h1>{t("your_payment_was_successful")}</h1>
            <p>{t("congratulations_your_payment_was_successful")}</p>
            <p>{t("we_will_contact_you_as_soon_as_possible")}</p>
            {!localStorage.getItem("authToken") && (
              <p>{t("please_verify_your_email")}</p>
            )}
            <img src={MainImg} />
            <br />
            {localStorage.getItem("authToken") ? (
              <button onClick={() => navigate("/home")}>
                {t("return_home")}
              </button>
            ) : (
              <button
                onClick={() =>
                  navigate(
                    `/create-org-account-step2/${localStorage.getItem("orgId")}`
                  )
                }
              >
                {t("next_step")}
              </button>
              // <button onClick={() => navigate(`/login`)}>{t("login")}</button>
            )}
          </div>
        </div>
      </TemplateLayout>
    </React.Fragment>
  );
};

export default ThankyouMain;
