import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import classes from "./forgotpasswordmain.module.scss";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionForgotPasswordApiCall } from "../../../Redux/Actions";
import { useTranslation } from "react-i18next";
const ForgotPasswordMain = () => {
  const { handleChange, values, errors, onFocus, onBlur, handleSubmit } =
    useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loading = useSelector((state) => state.CommonReducer.loading);
  const handleOnClick = (event) => {
    handleSubmit();
    event.preventDefault();
    if (errors.email == "" && Object.keys(values).length !== 0) {
      let data = { email: values.email };
      let body = { data, navigate };
      if (values.email) {
        dispatch(actionForgotPasswordApiCall(body));
      }
    }
  };
  return (
    <AuthLayout>
      <form className={classes.ForgotSection}>
        <div className={classes.ForgotContaint}>
          <div className={classes.ForgotHeading}>
            <h2>{t("forgot_password")}</h2>
          </div>
          <div className={classes.inputSection}>
            <label htmlFor="">{t("email")}</label>
            <input
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
          {errors.email && (
            <p className={`${classes.errorText}`}>{errors.email}</p>
          )}
          <div className={classes.loginBtnWrap}>
            <button className={classes.loginBtn} onClick={handleOnClick}>
              {}
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                t("send_recovery_email")
              )}
            </button>
          </div>
          <div className={classes.createAccountWrap}>
            <p>
              {t("dont_have_an_account_yet")}
              <br />{" "}
              <span onClick={() => navigate("/create-account")}>
                {t("create_one_here")}
              </span>
            </p>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};
export default ForgotPasswordMain;
