import React from "react";
import classes from "./advertisementlayout.module.scss";
import MainContainer from "../MainContainer";
import BackImg from "../../../assets/images/AddsLaoutBuldingImg.svg";
import RedirectionTab from "../../../utils/RedirectionTab";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionGetAgencyBannerClickCountApiCall } from "../../../Redux/Actions";

const AdvertisementLayout = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      {props?.adsData?.length > 0 && (
        <div className={classes.AddsLayoutSection}>
          <div className={classes.AddLayoutContainer}>
            <MainContainer>
              <div className={classes.AddsLayoutWrap}>
                {props?.adsData?.length > 0 &&
                  props.adsData.map((item) => (
                    <div
                      className={classes.AddImgWrap}
                      style={{ height: item.height, width: item.width }}
                    >
                      {item.url_original_file ? (
                        <img
                          src={item.url_original_file}
                          onClick={() => {
                            let RedirectionLinkData = {
                              link: item.link,
                              link_target: item.link_target,
                            };
                            RedirectionTab(RedirectionLinkData, navigate);
                            let ClickData = {
                              agency_banner_id: item.agency_banner_id,
                              agency_banner_size_id: item.id,
                            };
                            let data = JSON.stringify(ClickData);
                            const body = {
                              navigate,
                              data,
                            };
                            dispatch(
                              actionGetAgencyBannerClickCountApiCall(body)
                            );
                          }}
                          alt={item.original_file_name}
                        />
                      ) : (
                        <div
                          style={{ height: item.height, width: item.width }}
                          dangerouslySetInnerHTML={{ __html: item.iframe }}
                        ></div>
                      )}
                    </div>
                  ))}
              </div>
            </MainContainer>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AdvertisementLayout;
