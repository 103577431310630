import React, { useEffect, useState } from "react";
import AuthLayout from "../AuthLayout";
import classes from "./mainlayout.module.scss";
import AccountMain from "../Account";
import { MdPersonOutline } from "react-icons/md";
import OffcanvasMain from "../Offcanvas";
import { useTranslation } from "react-i18next";
import MainContainer from "../MainContainer";

const MainLayout = ({ children }) => {
  const [account, setAccount] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const { t } = useTranslation();
  useEffect(() => {
    setScreenWidth(window.screen.width);
  }, [window.screen.width]);

  const accountShow = () => {
    setAccount(true);
  };

  const handleClose = () => {
    setAccount(false);
  };
  return (
    <React.Fragment>
      <AuthLayout>
        <MainContainer>
          <div className={classes.MainLayotWrap}>
            <div className={classes.MainLayotSection}>
              <div className={classes.LeftSide}>
                {screenWidth > 768 && (
                  <div className={classes.AccountMainWrap}>
                    <AccountMain />
                  </div>
                )}
                <div className={classes.BtnWrap}>
                  <button onClick={accountShow}>
                    <MdPersonOutline />
                    {t("my_account")}
                  </button>
                </div>
              </div>
              <div className={classes.RigthSide}>
                <div className={classes.SectionLayout}>{children}</div>
              </div>
            </div>
            <OffcanvasMain showcanvas={account} handleClose={handleClose}>
              <AccountMain />
            </OffcanvasMain>
          </div>
        </MainContainer>
      </AuthLayout>
    </React.Fragment>
  );
};

export default MainLayout;
