import React from 'react'
import classes from "./authLayout.module.scss"
const AuthLayout = ({children}) => {
  return (
    <React.Fragment>
        <div className={classes.authContainer}>
            <div className={classes.authBackground}>
                {children}
            </div>
        </div>
    </React.Fragment>
  )
}

export default AuthLayout;