import React, { useState, useEffect } from "react";
import classes from "./subscriptioncard.module.scss";
import GoldPartner from "../../../assets/icons/GoldPartner.svg";
import { useTranslation } from "react-i18next";
import NewMostPopulerMark from "../../../assets/icons/NewMostPopulerMark.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { actionGetSubscriptionApiCall } from "../../../Redux/Actions";
import PriceFormatter from "../../../utils/PriceFormatter";

const SubscriptionCard = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saveSingleSubscriptionData = useSelector(
    (state) => state.CommonReducer.saveSingleSubscriptionData
  );
  const [id, setId] = useState();
  const [discountIdData, setDiscountIdData] = useState([]);

  // useEffect(() => {
  //   props.SetDescription(
  //     saveSingleSubscriptionData?.length > 0 &&
  //       saveSingleSubscriptionData.filter(
  //         (filterDataByID) => filterDataByID.id == params.id
  //       )[0].description
  //   );
  // }, [params.id]);

  useEffect(() => {
    const data = params.id;
    const body = { navigate, data, title: "id" };
    dispatch(actionGetSubscriptionApiCall(body));
  }, []);

  const SetIdData = (val, product_id) => {
    setId(val);
    if (discountIdData?.length > 0) {
      props.DataHandler(val, product_id, discountIdData);
    } else {
      props.DataHandler(val, product_id, []);
    }
    if (id !== val) {
      discountIdData?.map((dataId) => {
        document.getElementById(`${dataId}`).checked = false;
      });
    }
    setDiscountIdData([]);
  };
  const handleCheckboxChange = (e, id, val, product_id) => {
    let data;

    if (e.target.checked) {
      if (!discountIdData.includes(id)) {
        data = [...discountIdData, id];
        setDiscountIdData((prevData) => [...prevData, id]);
      }
    } else {
      data = discountIdData.filter((itemId) => itemId !== id);
      setDiscountIdData((prevData) =>
        prevData.filter((itemId) => itemId !== id)
      );
    }
    props.DataHandler(val, product_id, data);
  };

  return (
    <>
      {saveSingleSubscriptionData?.length > 0 &&
        saveSingleSubscriptionData
          // .filter((filterDataByID) => filterDataByID.id == params.id)
          .map((item) => {
            return (
              item?.payment_option?.length > 0 &&
              item?.payment_option.map((val) => {
                return (
                  <div
                    className={classes.SubscriptionCardMain}
                    onClick={() => {
                      SetIdData(val.id, val.product_id);
                    }}
                  >
                    <button
                      className={
                        id === val.id
                          ? `${classes.PeriodicityBtn} ${classes.PeriodicityBtnActive}`
                          : classes.PeriodicityBtn
                      }
                    >{`${val?.per} ${
                      val?.per > 1 && val?.periodicity == "MONTHLY"
                        ? t("months")
                        : val?.per == 1 && val?.periodicity == "MONTHLY"
                        ? t("month")
                        : val?.per > 1 && val?.periodicity == "YEARLY"
                        ? t("years")
                        : t("year")
                    }`}</button>
                    <div className={classes.CardMain}>
                      {val?.most_popular == 1 && (
                        <div className={classes.MostPopular}>
                          <p>{t("most_popular")}</p>
                          <img src={NewMostPopulerMark} />
                        </div>
                      )}
                      <div
                        // className={classes.SubscriptionCard}
                        className={
                          id === val.id
                            ? `${classes.SubscriptionCard} ${classes.SubscriptionCardActive}`
                            : classes.SubscriptionCard
                        }
                        // className={classes.GoldPlaneWrap}
                      >
                        <div className={classes.ImageContainer}>
                          <img
                            src={
                              item?.logo_image ? item?.logo_image : GoldPartner
                            }
                            alt="Gold patner"
                          />
                        </div>
                        <h5>{val?.name}</h5>
                        <p>
                          {`€ ${PriceFormatter(val?.price / val?.per, 2)}- / `}
                          <span>
                            {val?.periodicity == "MONTHLY"
                              ? t("per_month")
                              : t("per_year")}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        id === val.id
                          ? `${classes.CardContent} ${classes.CardContentActive}`
                          : classes.CardContent
                      }
                      // className={classes.CardContent}
                    >
                      {val?.discount_detail?.length > 0 &&
                        val?.discount_detail.map((discount) => {
                          return (
                            <div className={classes.inputbox}>
                              <input
                                type="checkbox"
                                id={discount.id}
                                // name={discount?.name}

                                // value={discount?.name.toUpperCase()}
                                className={classes.radioCustom}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    discount.discounts_and_extras_id,
                                    val.id,
                                    val.product_id
                                  )
                                }
                                defaultChecked={
                                  discount?.optional ? false : true
                                }
                                disabled={discount?.optional ? false : true}
                              />
                              <label
                                className={classes.radioCustomLabel}
                                htmlFor={discount.id}
                              >
                                <span>{" " + discount?.discounts?.name}</span>
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })
            );
          })}
    </>
  );
};

export default SubscriptionCard;
