import React from "react";
import classes from "./pagenotfoundmain.module.scss";
import PageNotFoundImg from "../../assets/images/PageNotFoundImg.svg";

const PageNotFoundMain = () => {
  return (
    <React.Fragment>
      <div className={classes.PageNotFoundMain}>
        <div className={classes.PageNotFoundImgWrap}>
          <img src={PageNotFoundImg} alt="Property not found" />
        </div>
        <h1>Property not found</h1>
      </div>
    </React.Fragment>
  );
};

export default PageNotFoundMain;
