import React, { useEffect } from "react";
import LatestListingsMain from "../../components/HomeComponents/LatestListingsMain";
import NeedHelpMain from "../../components/HomeComponents/NeedHelpMain";
import PropartySearchMain from "../../components/HomeComponents/PropartySearchMain";
import OrganisationHighlightMain from "../../components/HomeComponents/OrganisationHighlightMain";
import AdsMain from "../../components/HomeComponents/AdsMain";
import Footer from "../../components/layouts/Footer/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetAgencyBannerRecommendationsApiCall,
  actionGetHomeBannerApiCall,
  actionHomeStrapiApiCall,
  actionSaveSearchBackgroundImageUrl,
  actionSaveSelctedLanguage,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languages = useSelector((state) => state.CommonReducer.languages);
  const selectedLanguage = useSelector(
    (state) => state.CommonReducer.selectedLanguage
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(actionGetHomeBannerApiCall({ navigate }));
    dispatch(actionHomeStrapiApiCall({ navigate, title: "home-pages" }));
    dispatch(actionSaveSearchBackgroundImageUrl({}));

    let data = {
      bannerSizes: [
        {
          "Home Ad 1": "260*208",
        },
        {
          "Home Ad 2": "260*208",
        },
        {
          "Home Ad 3": "260*208",
        },
        {
          "Home Ad 4": "260*208",
        },
        {
          "Home Left": "613*415",
        },
        {
          "Home Right": "613*415",
        },
        {
          "Home Middle": "1306*442",
        },
      ],
    };
    let body = {
      navigate,
      data,
    };
    dispatch(actionGetAgencyBannerRecommendationsApiCall(body));
  }, []);

  useEffect(() => {
    if (languages) {
      if (!selectedLanguage?.language) {
        dispatch(
          actionSaveSelctedLanguage(languages?.find((l) => l.language == "nl"))
        );
      }
    }
  }, [languages]);
  return (
    <React.Fragment>
      <PropartySearchMain />
      <LatestListingsMain />
      <NeedHelpMain />
      <OrganisationHighlightMain />
      <AdsMain />
      <Footer />
    </React.Fragment>
  );
};

export default HomePage;
