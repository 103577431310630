import React from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import MyListingLayout from "../layouts/MyListingLayout";
import MyListingLayoutTest from "../layouts/MyListingLayoutTest";
import classes from "./editlistingmain.module.scss";

const EditListingMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.EditListingContainer}>
          <div className={classes.EditListHeading}>
            <h1>{t("edit_listing")}</h1>
          </div>
          {/* <MyListingLayout /> */} 
          {/* insted of this we can use this component for testing purpose */}
          <MyListingLayoutTest/>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default EditListingMain;
