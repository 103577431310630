import React, { useState, useEffect } from "react";
import AuthLayout from "../layouts/AuthLayout";
import classes from "./favouritepropretymain.module.scss";
import AccountMain from "../layouts/Account";
import { MdPersonOutline } from "react-icons/md";
import OffcanvasMain from "../layouts/Offcanvas";
import PropertyCardMain from "../layouts/PropertyCardDesign";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionFavouritePropertyListingApiCall } from "../../Redux/Actions";
import MainContainer from "../layouts/MainContainer";
import Loader from "../Loader";
import { useRef } from "react";
import Pagination from "../Pagination";
const FavouritePropretyMain = () => {
  const [account, setAccount] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const count = useSelector(
    (state) => state.CommonReducer.myPropertyListingData?.count
  );
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const loading = useSelector((state) => state.CommonReducer.loading);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    params.get("page") ? params.get("page") : 1
  );
  const [pageNumber, setPageNumber] = useState();
  const [activePage, setActivePage] = useState(
    params.get("page") ? params.get("page") : 1
  );
  const myRef = useRef();
  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    const pageNumbers = [];
    let totalPages = Math.ceil(count / ITEMS_PER_PAGE);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    setTotalPages(totalPages);
    setPageNumber(pageNumbers);
  }, [count]);

  const accountShow = () => {
    setAccount(true);
  };

  const handleClose = () => {
    setAccount(false);
  };
  useEffect(() => {
    let data = { offset: 0, page: currentPage, per_page: 10 };
    let body = { data, navigate };
    dispatch(actionFavouritePropertyListingApiCall(body));
  }, [currentPage]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
  };
  return (
    <React.Fragment>
      <AuthLayout>
        <div className={classes.FavouritePropretSection}>
          <MainContainer>
            <div className={classes.FavouritePropretWrap}>
              <div className={classes.LeftSide}>
                {screenWidth > 768 && (
                  <div className={classes.AccountMainWrap}>
                    <AccountMain />
                  </div>
                )}
                <div className={classes.BtnWrap}>
                  <button onClick={accountShow}>
                    <MdPersonOutline />
                    {t("my_account")}
                  </button>
                </div>
              </div>
              <div className={classes.Rightside} ref={myRef}>
                <div className={classes.FavouriteSection}>
                  <div className={classes.TitleWrap}>
                    <h1>{t("favourites")}</h1>
                    <p>
                      {t("show")} {ITEMS_PER_PAGE} {t("results_per_page")}
                    </p>
                  </div>
                  {loading === true ? (
                    <div className={classes.LoaderWrap}>
                      <Loader />{" "}
                    </div>
                  ) : (
                    <>
                      <PropertyCardMain />
                      {!loading && (
                        <div className={classes.Pagination}>
                          <div className={classes.PageWrap}>
                            {currentPage > 1 && currentPage <= totalPages && (
                              <div
                                className={
                                  activePage !== 1
                                    ? classes.NextPageActive
                                    : classes.NextPageInActive
                                }
                                onClick={() => {
                                  if (activePage !== 1) {
                                    setCurrentPage(currentPage - 1);
                                    setActivePage(currentPage - 1);
                                  }
                                }}
                              >
                                {"<"} <span>{t("prev_page")}</span>
                              </div>
                            )}
                            {pageNumber?.length >= 1 && (
                              <span className={classes.pageText}>
                                {t("page")}
                              </span>
                            )}
                            {pageNumber?.length > 0 && (
                              <Pagination
                                activePage={activePage}
                                pageNumber={pageNumber}
                                handlePageChange={handlePageChange}
                                classespage={classes.page}
                                classesActive={classes.Active}
                                classesInactive={classes.InActive}
                                myRef={myRef}
                              />
                            )}

                            {pageNumber?.length > 8 && (
                              <div className={classes.page}>
                                {" "}
                                <span className={classes.pageText}>
                                  {t("of")}
                                </span>
                                <span
                                  onClick={() => {
                                    handlePageChange(pageNumber.length);
                                  }}
                                  className={
                                    activePage === pageNumber.length
                                      ? classes.Active
                                      : classes.InActive
                                  }
                                >
                                  {pageNumber.length}
                                </span>
                              </div>
                            )}
                          </div>
                          {currentPage < totalPages && (
                            <div
                              className={
                                activePage !== pageNumber.length - 1
                                  ? classes.NextPageActive
                                  : classes.NextPageInActive
                              }
                              onClick={() => {
                                if (activePage !== pageNumber.length) {
                                  setCurrentPage(+currentPage + 1);
                                  setActivePage(+currentPage + 1);
                                }
                              }}
                            >
                              <span>{t("next_page")}</span> {">"}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </MainContainer>
          <OffcanvasMain showcanvas={account} handleClose={handleClose}>
            <AccountMain />
          </OffcanvasMain>
        </div>
      </AuthLayout>
    </React.Fragment>
  );
};

export default FavouritePropretyMain;
