import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import StopCampaignMain from "../../components/StopCampaignMain";

const StopCampaignPage = () => {
  return (
    <React.Fragment>
      <Header />
      <StopCampaignMain />
      <Footer />
    </React.Fragment>
  );
};

export default StopCampaignPage;
