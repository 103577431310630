import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import MyInvoicesMain from "../../components/MyInvoicesMain";
import { useTranslation } from "react-i18next";
import TitleProvider from "../../components/TitleProvider";

const MyInvoicesPage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TitleProvider title={t("my_invoices")} />
      <Header />
      <MyInvoicesMain />
      <Footer />
    </React.Fragment>
  );
};

export default MyInvoicesPage;
