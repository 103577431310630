import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import ViewOrgMain from "../../components/ViewOrgMain";
import { useEffect } from "react";

const ViewOrgPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Header />
      <ViewOrgMain />
      <Footer />
    </React.Fragment>
  );
};

export default ViewOrgPage;
