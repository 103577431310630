export const TYPE_OF_PROPERTY = [
  { name: "to_buy", value: "sell", id: 101 },
  { name: "to_rent", value: "rent", id: 102 },
  { name: "both", value: "both", id: 103 },
];
export const DAYS = [
  { name: "today", id: 201 },
  { name: "since_3_days", id: 202 },
  { name: "since_5_days", id: 203 },
  { name: "since_10_days", id: 204 },
  { name: "no_preference", id: 205 },
];
export const STATUS = [
  { name: "Onder bod", id: 301 },
  { name: "Lorem ipsum", id: 302 },
  { name: "Lorem ipsum", id: 303 },
];

export const SQUARE_METERS = [
  { name: "50", id: 401 },
  { name: "75", id: 402 },
  { name: "100", id: 403 },
  { name: "150", id: 404 },
  { name: "200", id: 405 },
  { name: "250", id: 406 },
];

export const NO_OF_BEDROOMS = [
  { name: "1", id: 501 },
  { name: "2", id: 502 },
  { name: "3", id: 503 },
  { name: "4", id: 504 },
  { name: "5", id: 505 },
];

export const ENERGY_LABEL = [
  { name: "A+++", id: 601, color: "#829002" },
  { name: "A++", id: 602, color: "#829002" },
  { name: "A+", id: 603, color: "#829002" },
  { name: "A", id: 604, color: "#829002" },
  { name: "B", id: 605, color: "#9A9F00" },
  { name: "C", id: 606, color: "#BFB800" },
  { name: "D", id: 607, color: "#FFCF00" },
  { name: "E", id: 608, color: "#FAB700" },
  { name: "F", id: 609, color: "#EA631A" },
  { name: "G", id: 6010, color: "#E53B20" },
];

export const APPLICABLE_OPTIONS = [
  { name: "Backyard", id: 701 },
  { name: "Basement", id: 702 },
  { name: "Balcony", id: 703 },
  { name: "Wine cellar", id: 704 },
];

export const PROPERTIE_TYPES = [
  { name: "Detached", id: 801 },
  { name: "Semi-detached", id: 802 },
  { name: "Flat", id: 803 },
  { name: "Bungalow", id: 804 },
  { name: "Park Home", id: 805 },
];

export const BANNER_TYPES = [
  {name: "Online", id: 9901},
  {name: "Facebook", id: 9902},
  {name: "Instagram", id: 9903},
];

export const REGION_RADIUSES = [
  {name: "+ 0km", value: 0, id: 91001},
  {name: "+ 5km", value: 5, id: 91004},
  {name: "+ 10km", value: 10, id: 91005},
  {name: "+ 15km", value: 15, id: 91006},
  {name: "+ 25km", value: 25, id: 91007},
  {name: "+ 50km", value: 50, id: 91008},
  {name: "+ 100km", value: 100, id: 91009},
];

export const PRICE_TYPES = [
  { name: "k.k.", id: 901 },
  { name: "v.o.n", id: 902 },
  { name: "m2", id: 903 },
  { name: "per month", id: 904 },
  { name: "per year", id: 905 },
];

export const PRICE_INFO = [
  { name: "none(display price)", id: 1001 },
  { name: "higher price range", id: 1002 },
  { name: "public auction", id: 1003 },
  { name: "price in consultation", id: 1004 },
  { name: "price on request", id: 1005 },
  { name: "fixed price", id: 1006 },
];
export const PRIVATE_LISTING = [
  { name: "yes", id: 2001, value: 1 },
  { name: "no", id: 2002, value: 0 },
];

export const CREDIT_PERIODS = [
  { name: "per dag", value: "PER_DAY", id: 92001 },
  { name: "per week", value: "PER_WEEK", id: 92002 },
  { name: "per maand", value: "PER_MONTH", id: 92003 },
];

export const SUPPLIER_BUDGETS = [
  // { name: "€ 0,-", value: 0, id: 932001 },
  { name: "€ 20,-", value: 20, id: 93002 },
  { name: "€ 50,-", value: 50, id: 93003 },
  { name: "€ 100,-", value: 100, id: 93004 },
];
