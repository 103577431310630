import React, { useEffect } from "react";
import CMSMain from "../../components/CMSMain";
import Header from "../../components/layouts/Header/Index";
import Footer from "../../components/layouts/Footer/Index";

const CMSPage = () => {

  return (
    <React.Fragment>
      <Header />
      <CMSMain />
      <Footer />
      
    </React.Fragment>
  );
};

export default CMSPage;
