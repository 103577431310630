import React, { useEffect } from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import Loader from "../../components/Loader";
import AuthLayout from "../../components/layouts/AuthLayout";
import MainContainer from "../../components/layouts/MainContainer";
import classes from "./paymentverification.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetSubscriptionWithStatusApiCall,
  actionVerificationApiCall,
} from "../../Redux/Actions";

import { useTranslation } from "react-i18next";
const PaymentVerificationPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const loading = useSelector((state) => state.CommonReducer.loading);
  const savePaymentStatus = useSelector(
    (state) => state.CommonReducer.savePaymentStatus
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    let data = Number(location?.search?.split("=")[1]);
    let body = { data, navigate };
    dispatch(actionGetSubscriptionWithStatusApiCall(body));
  }, [location?.search]);

  const showPaymentStatus = () => {
    switch (savePaymentStatus?.payment_status) {
      case "paid":
        navigate("/thankyou");
        break;
      case "failed":
        navigate("/payment-failed", {
          state: {
            status: "failed",
          },
        });
        break;
      case "canceled":
        navigate("/payment-failed", {
          state: {
            status: "canceled",
          },
        });
        break;
      case "expired":
        navigate("/payment-failed", {
          state: {
            status: "expired",
          },
        });
        break;
    }
  };

  return (
    <React.Fragment>
      <Header />
      <AuthLayout>
        <MainContainer>
          <div className={classes.VerificationPage}>
            {loading ? (
              <div className={classes.VerificationContainer}>
                <h3>{t("checking_verification")}</h3>
                <Loader />
              </div>
            ) : (
              <>{showPaymentStatus()}</>
            )}
          </div>
        </MainContainer>
      </AuthLayout>
      <Footer />
    </React.Fragment>
  );
};

export default PaymentVerificationPage;
