import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import SavedFilterMain from "../../components/SavedFilterMain";
import TitleProvider from "../../components/TitleProvider";
import { useTranslation } from "react-i18next";

const SavedFilterPage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TitleProvider title={t("saved_filter")} />
      <Header />
      <SavedFilterMain />
      <Footer />
    </React.Fragment>
  );
};

export default SavedFilterPage;
