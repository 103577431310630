import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import HighlightedListingMain from "../../components/SearchComponents/HighlightedListingMain";
import SearchMain from "../../components/SearchComponents/SearchMain";
import TitleProvider from "../../components/TitleProvider";
import { useTranslation } from "react-i18next";
import PropartySearchMain from "../../components/HomeComponents/PropartySearchMain";
import SearchMainWithoutBg from "../../components/SearchComponents/SearchMainWithoutBg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { actionGetAgencyBannerRecommendationsApiCall } from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const SearchPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state?.CommonReducer);

  useEffect(() => {
    const randomSizes = ["160*600", "300*600"];
    let data = {
      bannerSizes: ["970*250", randomSizes],
    };
    let body = {
      navigate,
      data,
    };
    dispatch(actionGetAgencyBannerRecommendationsApiCall(body));
  }, []);
  return (
    <React.Fragment>
      <Header />
      <TitleProvider title={t("highlighted_listings")} />
      {state?.saveSearchBackground &&
      Object.keys(state?.saveSearchBackground).length > 0 &&
      state?.saveSearchBackground?.image ? (
        <SearchMain />
      ) : (
        <SearchMainWithoutBg />
      )}
      {/* <SearchMainWithoutBg /> */}
      {/* <PropartySearchMain /> */}
      <HighlightedListingMain />
      <Footer />
    </React.Fragment>
  );
};

export default SearchPage;
