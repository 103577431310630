import React, { useEffect, useRef, useState } from "react";
import classes from "./createorgaccountmaintwo.module.scss";
import AuthLayout from "../layouts/AuthLayout";
import MainContainer from "../layouts/MainContainer";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionCreateOrgAccountStep2ApiCall,
  actionSavePdfLink,
} from "../../Redux/Actions";

const CreateOrgAccountMainTwo = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pdfLink = useSelector((state) => state.CommonReducer.pdfLink);
  const [uploadFile, setUploadFile] = useState("");
  const inputRef = useRef(null);
  const loading = useSelector((state) => state.CommonReducer.loading);
  const [otherSoftware, setOtherSoftware] = useState("");

  const dataProviderArray = [
    { label: "Realworks", value: "TIARA" },
    { label: "Kolibri", value: "KOLIBRI" },
    { label: "Skarabee", value: "SKARABEE" },
    { label: "Anders", value: "OTHER" },
    { label: "None", value: "" },
  ];
  const [data, setData] = useState({
    import_type: "-1",
    location_no: "",
  });

  useEffect(() => {
    dispatch(actionSavePdfLink(""));
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setUploadFile(e.target.files[0]);
    let formData = new FormData();
    formData.append("agency_id", params.id);
    formData.append("import_file", e.target.files[0]);
    formData.append("status", "PDF_UPLOADED");

    let body = {
      formData,
      navigate,
      status: "PDF_UPLOADED",
    };
    dispatch(actionCreateOrgAccountStep2ApiCall(body));
  };

  const startAccountHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("agency_id", params.id);
    if (data.location_no) {
      formData.append("location_no", data.location_no);
    }
    if (otherSoftware != "") {
      formData.append("other_type", otherSoftware);
    }
    let importType = data.import_type ? data.import_type : "";
    formData.append("import_type", importType);
    formData.append("status", "PDF_SEND");

    let body = {
      formData,
      navigate,
      status: "PDF_SEND",
    };
    dispatch(actionCreateOrgAccountStep2ApiCall(body));
  };
  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && charCode !== 9 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  const downloadPdfHandler = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("agency_id", params.id);
    if (data.location_no) {
      formData.append("location_no", data.location_no);
    }
    formData.append("import_type", data.import_type);
    formData.append("status", "PDF_DOWNLOADED");

    let body = {
      formData,
      navigate,
      status: "PDF_DOWNLOADED",
    };
    dispatch(actionCreateOrgAccountStep2ApiCall(body));
  };

  return (
    <React.Fragment>
      <AuthLayout>
        <MainContainer>
          <div className={classes.CreateOrgAccountMain}>
            <h1>{t("create_organisation_account")}(2/2)</h1>
            <div className={classes.FormTab}>
              <form>
                <div className={classes.InputFildBox}>
                  <p>{t("company_software")}</p>
                  <select
                    name="import_type"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={data?.import_type}
                  >
                    <option value="-1" disabled selected>
                      {`${t("select")}..`}
                    </option>
                    {dataProviderArray.map((option) => (
                      <option value={option.value} name={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                {data?.import_type == "OTHER" && (
                  <div className={classes.InputFildBox}>
                    <p>{t("other")}</p>
                    <input
                      type="text"
                      name="otherSoftware"
                      onChange={(e) => setOtherSoftware(e.target.value)}
                      value={otherSoftware}
                    />
                  </div>
                )}
                {data?.import_type != "-1" && (
                  <h6>
                    {t("you_have_selected")}{" "}
                    <span>
                      {
                        dataProviderArray?.find(
                          (item) => item.value == data?.import_type
                        ).label
                      }
                    </span>{" "}
                    {t("as_your_company_software")}
                  </h6>
                )}

                <div className={classes.InputFildBox}>
                  <p>{t("location_number")}</p>
                  <input
                    type="text"
                    name="location_no"
                    onChange={handleChange}
                    value={data?.location_no}
                    onKeyDown={handleKeyPressForNumber}
                  />
                </div>

                {data?.import_type == "TIARA" && (
                  <h6>{t("please_download_the_PDF_shown_below")}</h6>
                )}
                <div className={classes.DownloadAndUpload}>
                  {data?.import_type == "TIARA" && data?.location_no != "" && (
                    <button
                      className={classes.PdfDownload}
                      onClick={downloadPdfHandler}
                    >
                      {t("download_PDF")}
                    </button>
                  )}
                  {data?.import_type == "TIARA" && (
                    <>
                      <button
                        className={
                          pdfLink == ""
                            ? classes.PdfDownloadDisable
                            : classes.PdfDownload
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          inputRef.current?.click();
                        }}
                        disabled={pdfLink == "" ? true : false}
                      >
                        {t("upload_pdf")}
                      </button>
                      <input
                        ref={inputRef}
                        style={{ display: "none" }}
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*,.pdf"
                      />
                    </>
                  )}
                </div>
                {data?.import_type == "TIARA" ? (
                  <h6>{t("once_youve_downloaded_the_authorization_pdf")}</h6>
                ) : (
                  <h6>
                    {t(
                      "once_youve_choose_company_software_and_enter_location_number"
                    )}
                  </h6>
                )}

                <button
                  className={
                    data?.import_type == "TIARA"
                      ? data?.import_type &&
                        pdfLink != "" &&
                        uploadFile != "" &&
                        data?.location_no
                        ? classes.StartAccountEnable
                        : classes.StartAccount
                      : data?.import_type == "OTHER"
                      ? data?.import_type && otherSoftware != ""
                        ? classes.StartAccountEnable
                        : classes.StartAccount
                      : data?.import_type != -1
                      ? classes.StartAccountEnable
                      : classes.StartAccount
                  }
                  onClick={startAccountHandler}
                  disabled={
                    data?.import_type == "TIARA"
                      ? data?.import_type &&
                        pdfLink != "" &&
                        uploadFile != "" &&
                        data?.location_no
                        ? false
                        : true
                      : data?.import_type == "OTHER"
                      ? data?.import_type && otherSoftware != ""
                        ? false
                        : true
                      : data?.import_type != -1
                      ? false
                      : true
                  }
                >
                  {t("start_account")}
                </button>
              </form>
            </div>
          </div>
        </MainContainer>
      </AuthLayout>
    </React.Fragment>
  );
};

export default CreateOrgAccountMainTwo;
