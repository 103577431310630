import React from "react";
import ForgotPasswordMain from "../../../components/auth/ForgotPasswordMain";
import Footer from "../../../components/layouts/Footer/Index";
import Header from "../../../components/layouts/Header/Index";

const ForgotPasswordPage = () => {
  return (
    <React.Fragment>
      <Header />
      <ForgotPasswordMain />
      <Footer />
    </React.Fragment>
  );
};

export default ForgotPasswordPage;
