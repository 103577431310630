import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./listingfilters.module.scss";
import { PRIVATE_LISTING, TYPE_OF_PROPERTY } from "../../../Constants";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { actionMyListingApiCall } from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const ListingFilters = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [myListingFilter, setMyListingFilter] = useState({
    listingType: params.get("listingType") ? params.get("listingType") : "",
    isPrivateListing: params.get("isPrivateListing")
      ? params.get("isPrivateListing")
      : "",
    minPrice: params.get("minPrice") ? params.get("minPrice") : "",
    maxPrice: params.get("maxPrice") ? params.get("maxPrice") : "",
  });
  const [hasMounted, setHasMounted] = useState(false);
  const myOrganisationListingData = useSelector(
    (state) => state.CommonReducer.approvedOrganisationData
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    params.get("orgListing") ? params.get("orgListing")?.split(",") : []
  );
  const [matchingIdsData, setMatchingIdsData] = useState([]);
  useEffect(() => {
    const matchingIds = [];
    for (let i = 0; i < selectedCheckboxes.length; i++) {
      const nameToMatch = selectedCheckboxes[i];
      for (let j = 0; j < myOrganisationListingData.length; j++) {
        if (myOrganisationListingData[j].name === nameToMatch) {
          matchingIds.push(myOrganisationListingData[j].id);
          break;
        }
      }
    }
    setMatchingIdsData(matchingIds);
  }, [selectedCheckboxes]);
  useEffect(() => {
    if (hasMounted) {
      FilterDataHandler();
    } else {
      setHasMounted(true);
    }
  }, [myListingFilter, matchingIdsData, hasMounted, props.currentPage]);
  function handleOptionChange(event) {
    event.preventDefault();
    props.changeToDefaultPage();
    switch (event.target.name) {
      case "listingType":
        return setMyListingFilter({
          ...myListingFilter,
          listingType:
            myListingFilter.listingType == event.target.value
              ? ""
              : event.target.value,
        });
      case "isPrivateListing":
        return setMyListingFilter({
          ...myListingFilter,
          isPrivateListing:
            myListingFilter.isPrivateListing == event.target.value
              ? ""
              : event.target.value,
        });
      case "minPrice":
        return setMyListingFilter({
          ...myListingFilter,
          minPrice: event.target.value,
        });
      case "maxPrice":
        return setMyListingFilter({
          ...myListingFilter,
          maxPrice: event.target.value,
        });
      default:
        return;
    }
  }
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCheckboxes([...selectedCheckboxes, value]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== value)
      );
    }
  };
  const FilterDataHandler = () => {
    let data = {
      page: props.currentPage,
      per_page: 10,
      minPrice: myListingFilter.minPrice,
      maxPrice: myListingFilter.maxPrice,
      listingType: myListingFilter.listingType,
      isPrivateListing:
        typeof myListingFilter.isPrivateListing !== ""
          ? myListingFilter.isPrivateListing
          : "",
      agencyIdArr: matchingIdsData,
    };
    let body = { data, navigate };
    dispatch(actionMyListingApiCall(body));
    const queryParams = [
      `page=${props.currentPage}`,
      myListingFilter.minPrice ? `minPrice=${myListingFilter.minPrice}` : "",
      myListingFilter.maxPrice ? `maxPrice=${myListingFilter.maxPrice}` : "",
      myListingFilter.listingType
        ? `listingType=${myListingFilter.listingType}`
        : "",
      myListingFilter.isPrivateListing
        ? `isPrivateListing=${myListingFilter.isPrivateListing}`
        : "",
      selectedCheckboxes.length ? `orgListing=${selectedCheckboxes}` : "",
    ];
    const queryString = queryParams.filter(Boolean).join("&");
    navigate(`/my-listing?${queryString}`);
  };
  const handleValidMaxPrice = () => {
    if (myListingFilter.maxPrice && myListingFilter.minPrice) {
      if (
        Number(myListingFilter.maxPrice) <= Number(myListingFilter.minPrice)
      ) {
        toast.error("Maximum price should be greater than minimum price");
      } else if (
        Number(myListingFilter.minPrice) >= Number(myListingFilter.maxPrice)
      ) {
        toast.error("Minimum price should be less than maximum price");
      }
    }
  };
  return (
    <div className={classes.ListingSideBar}>
      <div className={classes.TypeOfListingWrap}>
        <h5>{t("type_of_listing")}</h5>
        {TYPE_OF_PROPERTY.map((item, index) => {
          return (
            <div className={classes.inputbox}>
              <input
                type="radio"
                id={item.id}
                name="listingType"
                className={classes.radioCustom}
                value={item.value}
                checked={myListingFilter.listingType === item.value}
                onClick={handleOptionChange}
              />
              <label className={classes.radioCustomLabel} htmlFor={item.id}>
                <span>{" " + t(item.name)}</span>
              </label>
            </div>
          );
        })}
      </div>
      <div className={classes.TypeOfListingWrap}>
        <h5>{t("show_private_listings")}</h5>
        {PRIVATE_LISTING.map((item, index) => {
          return (
            <div className={classes.inputbox}>
              <input
                type="radio"
                id={item.id}
                name="isPrivateListing"
                className={classes.radioCustom}
                value={item.value}
                checked={myListingFilter?.isPrivateListing?.includes(
                  item.value
                )}
                onClick={handleOptionChange}
              />
              <label className={classes.radioCustomLabel} htmlFor={item.id}>
                <span>{" " + t(item.name)}</span>
              </label>
            </div>
          );
        })}
      </div>
      <div className={classes.TypeOfListingWrap}>
        <h5>{t("show_org_listings")}</h5>
        {myOrganisationListingData?.length > 0 &&
          myOrganisationListingData?.map((item, index) => {
            return (
              <div className={classes.inputbox}>
                <input
                  type="checkbox"
                  id={item.id}
                  name={item.name}
                  className={classes.radioCustom}
                  value={item.name}
                  onChange={handleCheckboxChange}
                  checked={selectedCheckboxes.includes(item?.name)}
                />
                <label className={classes.radioCustomLabel} htmlFor={item.id}>
                  <span>{" " + item.name}</span>
                </label>
              </div>
            );
          })}
      </div>
      <div className={classes.PriceRange}>
        <h5>{t("price_range")}</h5>
        <div className={classes.inputBox}>
          <input
            type="number"
            placeholder={t("from_minprice")}
            name="minPrice"
            min="1"
            value={myListingFilter?.minPrice}
            onChange={handleOptionChange}
            onBlur={handleValidMaxPrice}
          />
        </div>
        <div className={classes.inputBox}>
          <input
            type="number"
            placeholder={t("till_no_max")}
            name="maxPrice"
            min="1"
            value={myListingFilter?.maxPrice}
            onChange={handleOptionChange}
            onBlur={handleValidMaxPrice}
          />
        </div>
      </div>
    </div>
  );
};
export default ListingFilters;
