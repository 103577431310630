import { useSelector } from "react-redux";
import { seo } from "../../utils/seo";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const TitleDescriptionProvider = (props) => {
  const selectedLanguage = useSelector(
    (state) => state.CommonReducer.selectedLanguage
  );
  const singlePropertyDetails = useSelector(
    (state) => state.CommonReducer.singlePropertyDetails
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(singlePropertyDetails).length > 0) {
      let propertyType = t(
        singlePropertyDetails?.is_sell == 1 &&
          singlePropertyDetails?.is_rent == 1
          ? "both"
          : singlePropertyDetails?.is_sell == 1
          ? "sell"
          : "rent"
      );

      let MainImageData = [];

      const metaTags = [
        { property: "og:type", content: "website" },
        {
          property: "og:title",
          content: `${t("property_meta_title", {
            property_type: propertyType,
            address: singlePropertyDetails.address,
            city: singlePropertyDetails?.city,
          })}`,
        },
        {
          property: "og:description",
          content: `${t("property_meta_description", {
            property_type: propertyType,
            address: singlePropertyDetails.address,
            city: singlePropertyDetails?.city,
          })}`,
        },
      ];
      singlePropertyDetails?.image_list?.map((item) => {
        if (item.sub_type === "MAIN_PHOTO") {
          MainImageData.push(item);
        }
      });
      if (MainImageData?.length > 0) {
        metaTags.push({
          property: "og:image",
          content: MainImageData[0]?.url_medium_file,
        });
      }
      seo({
        title: props.title + " | Woningmarkt.nl",
      });
      seo({
        title: props.title,
        metaDescription: props.metaDescription,
        displayGraph: true,
        metaTags: metaTags,
      });
      return () => {
        seo({
          title: "",
          metaDescription: "",
          displayGraph: true,
          metaTags: "",
        });
      };
    }
  }, [selectedLanguage?.language, props.title, singlePropertyDetails]);

  return <></>;
};
export default TitleDescriptionProvider;
