import React from "react";
import MolliePaymentMain from "../../components/MolliePaymentMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";

const MolliePaymentPage = () => {
	return (
		<React.Fragment>
			<Header />
			<MolliePaymentMain />
			<Footer />
		</React.Fragment>
	);
};

export default MolliePaymentPage;
