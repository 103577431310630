import React from "react";
import ChangeAccountInfoMain from "../../components/ChangeAccountInfoMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import TitleProvider from "../../components/TitleProvider";
import { useTranslation } from "react-i18next";

const ChangeAccountInfoPage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TitleProvider title={t("change_account_information")} />
      <Header />
      <ChangeAccountInfoMain />
      <Footer />
    </React.Fragment>
  );
};

export default ChangeAccountInfoPage;
