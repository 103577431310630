import PriceFormatter from "./PriceFormatter";

const displayPrice = (
  is_sell,
  is_rent,
  rent_price,
  rent_postfix,
  sell_price,
  sell_postfix,
  t
) => {
  return (
    <>
      {is_sell === 1 &&
        `€ ${PriceFormatter(sell_price)} ${
          sell_postfix ? t(sell_postfix) : ""
        }`}
      {is_sell === 1 && is_rent === 1 && <br />}
      {is_rent === 1 &&
        `€ ${PriceFormatter(rent_price)} ${
          rent_postfix ? t(rent_postfix) : ""
        }`}
    </>
  );
};

export default displayPrice;
