import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import MyListingMain from "../../components/MyListingMain";
import { useTranslation } from "react-i18next";
import TitleProvider from "../../components/TitleProvider";

const MyListingPage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TitleProvider title={t("my_listings")} />
      <Header />
      <MyListingMain />
      <Footer />
    </React.Fragment>
  );
};

export default MyListingPage;
