import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nHttpLoader from "i18next-http-backend";
import Axios from "axios";
import APIUrl from "./API/APIUrl";

i18n
  .use(initReactI18next)
  .use(i18nHttpLoader)
  .init({
    compatibilityJSON: "v3",
    interpolation: {
      escapeValue: false,
    },
    lng: "nl",
    fallbackLng: "nl",
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: `${APIUrl.backendUrl}/lang-label-list/{{lng}}/2`,
      parse: (data) => {
        return data;
      },
      request: (options, url, payload, callback) => {
        Axios.get(url)
          .then((res) => {
            let result = {};
            for (const iterator of res.data.data) {
              result[iterator.label_key] = iterator.label_value;
            }
    
            callback(null, {
              data: result,
              status: 200,
            });
          })
          .catch((err) => {
            console.log(err);
            callback(err, null);
          });
      },
    },
  });
export default i18n;
