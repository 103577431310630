import React from "react";
import classes from "./membershipricingmain.module.scss";
import MainContainer from "../layouts/MainContainer";
import "react-multi-carousel/lib/styles.css";

import { useTranslation } from "react-i18next";
import { useState } from "react";
import { subscriptionTabData } from "../../data";
import cardImg from "../../assets/icons/PricingCardImg.svg";
import ListingIcon from "../../assets/icons/PriceCardListngIcon.svg";
import popularMark from "../../assets/icons/MostPopulerMark.svg";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { actionGetSubscriptionApiCall } from "../../Redux/Actions";
import PriceFormatter from "../../utils/PriceFormatter";

const MembershiPricingMain = () => {
  const { t } = useTranslation();
  const [isActiveTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [periodicity, setPeriodicity] = useState(t("Monthly"));
  const [id, setId] = useState();
  const saveSubscriptionData = useSelector(
    (state) => state.CommonReducer.saveSubscriptionData
  );

  useEffect(() => {
    const data = "MONTHLY";
    const body = { navigate, data, title: "periodicity" };
    dispatch(actionGetSubscriptionApiCall(body));
  }, []);

  const settings = {
    centerMode: false,
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 4,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          centerMode: false,
          infinite: false,

          variableWidth: false,
        },
      },
      {
        breakpoint: 925,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode: false,
          variableWidth: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true,
          centerPadding: "10px",
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className={classes.MembershiPricWrap}>
        <MainContainer>
          <div className={classes.MembershiPricSection}>
            <h1>{t("membershi_pricing")}</h1>
            <div className={classes.OptionSelectSection}>
              <h3>{t("please_select_an_option_below")}</h3>
              <div className={classes.ToggleSection}>
                {subscriptionTabData.map((val, index) => {
                  return (
                    <div
                      className={`${classes.Option} ${
                        isActiveTab === index ? classes.active : ""
                      }`}
                      onClick={() => {
                        setPeriodicity(val);
                        setActiveTab(index);
                        const data = val == "Annually" ? "YEARLY" : "MONTHLY";
                        const body = { navigate, data, title: "periodicity" };
                        dispatch(actionGetSubscriptionApiCall(body));
                      }}
                    >
                      <span>{`${t(val)}`}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.OfferSection}>
              <h4>{t("offer_text")}</h4>
              {saveSubscriptionData?.length > 0 && (
                <div className={classes.CardSection}>
                  <Slider
                    className={`${classes.MainSlider} center`}
                    {...settings}
                  >
                    {/* <div className={classes.MainCardWrap}>
                    <div className={classes.CardContentWrap}>
                      <div className={classes.OfferTitleWrap}>
                        <img src={cardImg} alt="Free trial" />
                        <h5>{t("free_trial")}</h5>
                      </div>
                      <h2>{t("free")}</h2>
                      <p>{t("Free trial untill 31-06-2023")}</p>
                      <div className={classes.OfferListWrap}>
                        <ul>
                          <li>
                            <img src={ListingIcon} alt="Listing" />
                            {t("Lorem ipsum")}
                          </li>
                          <li>
                            <img src={ListingIcon} alt="Listing" />
                            {t("Lorem ipsum")}
                          </li>
                        </ul>
                      </div>
                      <div className={classes.OfferPoinWrap}>
                        <ul>
                          <li>
                            <span />
                            {t("Lorem ipsum")}
                          </li>
                          <li>{t("Lorem ipsum")}</li>
                        </ul>
                      </div>
                    </div>
                    <div className={classes.BtnSection}>
                      <div className={classes.DiscountWrap}>
                        <p>20% Discount</p>
                      </div>
                      <button>{t("select_option")}</button>
                    </div>
                  </div> */}
                    {saveSubscriptionData?.length > 0 &&
                      saveSubscriptionData?.map((item, index) => {
                        return (
                          <div
                            className={
                              id === item.id
                                ? `${classes.GoldPlaneWrap} ${classes.GoldPlaneWrapActive}`
                                : classes.GoldPlaneWrap
                            }
                            onClick={() => setId(item.id)}
                          >
                            {item.most_popular == 1 && (
                              <div className={classes.MostPopular}>
                                <p>{t("most_popular")}</p>
                                <img src={popularMark} />
                              </div>
                            )}
                            <div className={classes.CardContentWrap}>
                              <div className={classes.OfferTitleWrap}>
                                <img
                                  src={
                                    item?.logo_image
                                      ? item?.logo_image
                                      : cardImg
                                  }
                                  alt="Free trial"
                                />
                                {/* <h5>{t("free_trial")}</h5> */}
                                <h5>{item.name}</h5>
                              </div>
                              {/* <h2>{t("free")}</h2> */}

                              {item?.payment_option?.length > 0 &&
                                item?.payment_option
                                  .slice(0, 1)
                                  .map((val, index) => {
                                    if (val.periodicity == "MONTHLY") {
                                      return (
                                        <div className={classes.PriceDiv}>
                                          <h2>{`€ ${PriceFormatter(
                                            val.price / val.per,
                                            2
                                          )}-/`}</h2>
                                          <span>{t("per month")}</span>
                                        </div>
                                      );
                                    }
                                    if (val.periodicity == "YEARLY") {
                                      return (
                                        <>
                                          <div className={classes.PriceDiv}>
                                            <h2>
                                              {`€ ${PriceFormatter(
                                                (val.price / 12) * val.per,
                                                2
                                              )}- /`}{" "}
                                            </h2>
                                            <span>{t("per_month")}</span>
                                            <h6>{`${PriceFormatter(
                                              val.price,
                                              2
                                            )} annually`}</h6>
                                          </div>
                                        </>
                                      );
                                    }
                                  })}
                              {/* <p>{t("Free trial untill 31-06-2023")}</p> */}
                              <div
                                className={classes.OfferListWrap}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item?.description &&
                                    `${item?.description.slice(0, 25)}...`,
                                }}
                              >
                                {/* <ul>
                                <li>
                                  <img src={ListingIcon} alt="Listing" />
                                  {t("Lorem ipsum")}
                                </li>
                                <li>
                                  <img src={ListingIcon} alt="Listing" />
                                  {t("Lorem ipsum")}
                                </li>
                              </ul> */}
                              </div>
                              {/* <div className={classes.OfferPoinWrap}>
                              <ul>
                                <li>
                                  <span />
                                  {t("Lorem ipsum")}
                                </li>
                                <li>{t("Lorem ipsum")}</li>
                              </ul>
                            </div> */}
                            </div>
                            <div className={classes.BtnSection}>
                              {/* <div className={classes.DiscountWrap}>
                              <p>20% Discount</p>
                            </div> */}
                              <button
                                onClick={() =>
                                  navigate(`/membership/${item.id}`)
                                }
                              >
                                {t("select_option")}
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              )}
            </div>
          </div>
        </MainContainer>
      </div>
    </React.Fragment>
  );
};

export default MembershiPricingMain;
