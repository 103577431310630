import React, { useState, useEffect } from "react";
import classes from "./paymentfailedmain.module.scss";
import TemplateLayout from "../layouts/TemplateLayout";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionGetHomeBannerApiCall } from "../../Redux/Actions";

const PaymentFailedMain = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const savePaymentFailedBanner = useSelector(
    (state) => state.CommonReducer.saveHomeBanner
  );
  const savePaymentStatus = useSelector(
    (state) => state.CommonReducer.savePaymentStatus
  );
  const [paymentFailedBanner, setPaymentFailedBanner] = useState();

  useEffect(() => {
    dispatch(actionGetHomeBannerApiCall({ navigate }));
  }, []);

  const getStatusMessage = (status) => {
    if (status == "open") {
      return t("payment_status_open_msg");
    } else if (status == "pending") {
      return t("payment_status_pending_msg");
    } else if (status == "canceled") {
      return t("payment_status_canceled_msg");
    } else if (status == "expired") {
      return t("payment_status_expired_msg");
    } else {
      return t("unfortunately_your_payment_was_not_successful");
    }
  };

  useEffect(() => {
    savePaymentFailedBanner?.rows?.length > 0 &&
      savePaymentFailedBanner?.rows?.find((item, index) => {
        if (item.position === "Payment Failed") {
          setPaymentFailedBanner(item.url_original_file);
        }
      });
  }, [savePaymentFailedBanner]);
  return (
    <React.Fragment>
      <TemplateLayout
        title={`${t("payment")} ${t(state?.status)}..`}
        image={paymentFailedBanner}
      >
        <div className={classes.FiledPaymentSection}>
          <div className={classes.FiledPaymentContent}>
            <h1>{t("your_payment_has_failed")}</h1>
            <p>
              {/* {t("unfortunately_your_payment_was_not_successful")}{" "} */}
              {getStatusMessage(savePaymentStatus?.payment_status)}{" "}
              <span onClick={() => navigate("/membership-pricing")}>
                {t("try_again_here")}
              </span>
              .
            </p>
            {/* <p>
              Are you under the impression that something else is going wrong?
              Contact us using the form below.
            </p>
            <div className={classes.FromSection}>
              <form>
                <div className={classes.InputFildBox}>
                  <p>{t("Name")}</p>
                  <input type="text" />
                </div>
                <div className={classes.flexInput}>
                  <div className={classes.InputFildBox}>
                    <p>{t("phonenumber")}</p>
                    <input type="text" />
                  </div>
                  <div className={classes.InputFildBox}>
                    <p>{t("email")}</p>
                    <input type="email" />
                  </div>
                </div>
                <div className={classes.InputFildBox}>
                  <p>{t("message")}</p>
                  <textarea />
                </div>
                <button>{t("send_form")}</button>
              </form>
            </div> */}
          </div>
        </div>
      </TemplateLayout>
    </React.Fragment>
  );
};

export default PaymentFailedMain;
