import React from "react";
import MembershiPricingMain from "../../components/MembershiPricingMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";

const MembershiPricingPage = () => {
  return (
    <React.Fragment>
      <Header />
      <MembershiPricingMain />
      <Footer />
    </React.Fragment>
  );
};

export default MembershiPricingPage;
