import React, { useEffect, useRef, useState } from "react";
import classes from "./viewlistingphotos.module.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../layouts/Header/Index";
import Footer from "../../layouts/Footer/Index";
import { ImCross } from "react-icons/im";
import MainContainer from "../../layouts/MainContainer";
import ReactPlayer from "react-player";
const ViewListingPhotos = () => {
  const { state } = useLocation();
  const singlePropertyDetails = useSelector(
    (state) => state.CommonReducer.singlePropertyDetails
  );
  const carouselRef = useRef(null);
  const inputRefs = useRef([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    if (state.id) {
      let index = singlePropertyDetails.image_list
        .filter((val) => val.type != "BROCHURE")
        .findIndex((item) => item.id === state.id);
      carouselRef.current.goToSlide(index);
    }
  }, [state.id]);
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        window.history.back();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleKeyDown = (event) => {
    if (event.keyCode === 37) {
      carouselRef.current.previous();
      setCurrentSlide(currentSlide - 1);
    } else if (event.keyCode === 39) {
      carouselRef.current.next();
      setCurrentSlide(currentSlide + 1);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentSlide]);
  const handleSlideChange = (previousSlide, { currentSlide, onMove }) => {
    setActiveSlideIndex(previousSlide);
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const videoPlayer = (url, val) => {
    return (
      <ReactPlayer
        url={url}
        controls={true}
        muted={true}
        playing={val}
        width="100%"
        height="100%"
        playIcon={true}
      />
    );
  };
  return (
    <div>
      <Header />
      <div className={classes.ViewImageWrap}>
        <div className={classes.CrosIcon}>
          <ImCross onClick={() => window.history.back()} />
        </div>
        <MainContainer>
          <div className={classes.ViewListingImgWarp}>
            <Carousel
              ref={carouselRef}
              swipeable={true}
              draggable={true}
              responsive={responsive}
              ssr={true}
              infinite={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              transitionDuration={500}
              removeArrowOnDeviceType={["mobile"]}
              beforeChange={handleSlideChange}
            >
              {state.name
                ? singlePropertyDetails.image_list
                    .filter(
                      (val) => val.type != "BROCHURE" && val.type == state.name
                    )
                    .map((item, index) => {
                      return (
                        <div className={classes.ImageWrap}>
                          {item.type == "PHOTO" || item.type == "FLOORPLAN" ? (
                            <img
                              src={
                                screenWidth > 768
                                  ? item.url_normalized_file
                                  : item.url_medium_file
                              }
                            />
                          ) : (
                            videoPlayer(
                              item.url_original_file,
                              activeSlideIndex == index
                            )
                          )}
                        </div>
                      );
                    })
                : singlePropertyDetails.image_list
                    .filter((val) => val.type != "BROCHURE")
                    .map((item, index) => {
                      return (
                        <div className={classes.ImageWrap}>
                          {item.type == "PHOTO" || item.type == "FLOORPLAN" ? (
                            <img
                              src={
                                screenWidth > 768
                                  ? item.url_normalized_file
                                  : item.url_medium_file
                              }
                            />
                          ) : (
                            videoPlayer(
                              item.url_original_file,
                              activeSlideIndex == index
                            )
                          )}
                        </div>
                      );
                    })}
            </Carousel>
          </div>
        </MainContainer>
      </div>
      <Footer />
    </div>
  );
};
export default ViewListingPhotos;
