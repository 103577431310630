import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import IoginBtnIcon from "../../../assets/icons/loginBtnIcon.svg";
import classes from "./ResetPasswordMain.module.scss";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { actionResetPasswordApiCall } from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResetPasswordMain = () => {
  const { handleChange, values, errors, onFocus, onBlur, handleSubmit } =
    useForm();
  const forgotEmail = useSelector(
    (state) => state.CommonReducer.saveForgotEmail
  );

  const saveOtp = useSelector((state) => state.CommonReducer.saveOtp);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loading = useSelector((state) => state.CommonReducer.loading);

  const handleOnClick = () => {
    handleSubmit();
    if (
      values?.password === values?.confirmPassword &&
      values?.password != "" &&
      values?.confirmPassword != "" &&
      errors.password === "" &&
      errors.confirmPassword === ""
    ) {
      let data = {
        otp: saveOtp,
        email: forgotEmail,
        password: values.password,
        password_confirmation: values.confirmPassword,
      };
      let body = { data, navigate };
      dispatch(actionResetPasswordApiCall(body));
    }
  };

  return (
    <AuthLayout>
      <form className={classes.resetPasswordSection}>
        <div className={classes.resetPasswordContaint}>
          <div className={classes.resetPasswordHeading}>
            <h2>{t("reset_password")}</h2>
          </div>
          <div className={classes.inputSection}>
            <label htmlFor="">{t("password")}</label>
            <input
              type="password"
              name="password"
              value={values.password.replace(/\s/g, "")}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
          {errors.password && (
            <p className={`${classes.errorText}`}>{errors.password}</p>
          )}
          <div className={classes.inputSection}>
            <label htmlFor="">{t("confirm_password")}</label>
            <input
              type="password"
              name="confirmPassword"
              value={values.confirmPassword.replace(/\s/g, "")}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  handleOnClick();
                }
              }}
            />
          </div>
          {errors.confirmPassword && (
            <p className={`${classes.errorText}`}>{errors.confirmPassword}</p>
          )}

          <div className={classes.alreadyHaveAccountBtnWrap}>
            <button
              className={classes.loginBtn}
              onClick={(event) => {
                handleOnClick();
                event.preventDefault();
              }}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>
                  {t("reset_password")}
                  <img src={IoginBtnIcon} alt="Reset password" />
                </>
              )}
            </button>
          </div>
          <div className={classes.alreadyHaveAccountWrap}>
            <p>
              {t("i_already_have_an_account")}{" "}
              <span onClick={() => navigate("/")}>{t("log_in_here")}</span>
            </p>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ResetPasswordMain;
