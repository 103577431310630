import React, { useState } from "react";
import classes from "./regioncardselectable.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import NoDataFound from "../../NoDataFound";
import { REGION_RADIUSES, SUPPLIER_BUDGETS } from "../../../Constants";
import SupplierCardSelectable from "../SupplierCardSelectable";
import { RiDeleteBin6Line } from "react-icons/ri";

const RegionCardSelectable = ({
  regions,
  changeRadius,
  handleDeleteRegion,
  suppliers,
  handleChangeSupplier,
  handleChangeBudget,
}) => {
  const location = useLocation().pathname;
  const platform = localStorage.getItem("Platform");
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className={classes.RegionListingWrap}>
        <div className={classes.RegionScrollContainer}>
          <div className={classes.RegionGridScroll}>
            {regions && regions.length > 0
              ? regions.map((item, index) => (
                  <div>
                    <div className={classes.RegionWrap}>
                      <div className={classes.DeleteIcon}>
                        <RiDeleteBin6Line
                          title={t("icons_delete_region")}
                          onClick={(event) => {
                            handleDeleteRegion(event, item.id);
                          }}
                        />
                      </div>
                      <div className={classes.RegionName}>{item.name}</div>
                      <div className={classes.InputFieldBoxWithMargin}>
                        <select
                          id={`region_budget_${item.id}`}
                          name={`region_budget_${item.id}`}
                          defaultValue={index === 0 ? 50 : item.budget}
                          onChange={(event) => {
                            handleChangeBudget(event, item);
                          }}
                        >
                          {SUPPLIER_BUDGETS.map((option) => (
                            <option key={option.id} value={option?.value}>
                              {option?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={classes.InputFieldBoxWithMargin}>
                        <select
                          id={`radius_${item.id}`}
                          name={`radius_${item.id}`}
                          defaultValue={item.radius}
                          onChange={(event) => {
                            changeRadius(event);
                          }}
                        >
                          {REGION_RADIUSES.map((option) => (
                            <option key={option.id} value={option?.value}>
                              {option?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className={classes.RegionCard}></div>
                    {suppliers && (
                      <SupplierCardSelectable
                        region={item.id}
                        suppliers={suppliers[item.id]}
                        changeSupplier={handleChangeSupplier}
                      />
                    )}
                  </div>
                ))
              : ""}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegionCardSelectable;
