import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import default_logo from "../../assets/images/default_logo.png";
import hover_logo from "../../assets/images/hover_logo.png";
import visited_logo from "../../assets/images/visited_logo.png";
import classes from "./displaymarker.module.scss";
import { ENERGY_LABEL } from "../../Constants";
import PriceFormatter from "../../utils/PriceFormatter";
import displayPrice from "../../utils/displayPrice";
import { useTranslation } from "react-i18next";
import { json, useNavigate } from "react-router-dom";
import defaultImage from "../../assets/images/defaultImage.png";
import HomeSizeIcon from "../../assets//icons/HomeSizeIcon.svg";
import BedIson from "../../assets/icons/BedIcon.svg";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const Mark = ({
  markerPosition,
  selectedMarker,
  store,
  icon,
  isMarkerSelected,
  handleMarkerClick,
  handleVisitedMarker,
  handleMarkerClose,
  setHoverMarker,
}) => {
  const IMAGE_BASE_URL = "https://api-test.woningmarkt.nl/uploads/";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const adjustedMarkerPosition = {
    lat: markerPosition.lat,
    lng: markerPosition.lng,
  };


  return (
    <React.Fragment>
      <MarkerF
        key={icon}
        position={markerPosition}
        onClick={() => {
          handleMarkerClick();
          handleVisitedMarker(store.id);
        }}
        onMouseOver={() => {
          setHoverMarker(store.id);
        }}
        onMouseOut={() => {
          setHoverMarker(null);
        }}
        //here we can add the icon whatever we want to use
        icon={{
          url: icon,
          scaledSize: {
            height: 30,
            width: 28,
          },
        }}
      />
      {isMarkerSelected && (
        <InfoWindowF
          position={adjustedMarkerPosition}
          onCloseClick={handleMarkerClose}
          anchor={selectedMarker.anchor}
          options={{ width: 1000 }}
        >
          <>
            <div
              className={classes.PropertyCardSearch}
              onClick={() =>
                navigate(
                  `/view-listing/${
                    store.city &&
                    store.city
                      .toLowerCase()
                      .replace(/ /g, "-")
                      .replace(/[^\w-]+/g, "")
                  }/${
                    store.address
                      ? store.address
                          .toLowerCase()
                          .replace(/ /g, "-")
                          .replace(/[^\w-]+/g, "")
                      : `${store.street ? store.street + "-" : ""}${
                          store.housenumber ? store.housenumber + "-" : ""
                        }${store.postcode ? store.postcode + "-" : ""}${
                          store.district ? store.district : ""
                        }`
                          .toLowerCase()
                          .replace(/[^\w-]+/g, "")
                  }/${store.id}`
                )
              }
            >
              <div className={classes.PropertyImg}>
                {store.image_list?.length > 0 ? (
                  store.image_list.map((val) => {
                    return (
                      <>
                        {store.status &&
                          store.status.toUpperCase() !== "AVAILABLE" && (
                            <div className={classes.PropertyStatus}>
                              {t(store.status)}
                            </div>
                          )}
                        <img
                          src={
                            val.url_thumb_file.includes("http")
                              ? val.url_thumb_file
                              : `${IMAGE_BASE_URL}thumb/${val.url_thumb_file}`
                          }
                          alt={val.original_file_name}
                        />
                      </>
                    );
                  })
                ) : (
                  <img src={defaultImage} alt="Property" />
                )}
              </div>
              <div className={classes.MainContentWrap}>
                <div className={classes.PropertyContent}>
                  <h5>
                    {store?.address
                      ? store?.address
                      : `${store?.street} ${store?.housenumber}`}
                  </h5>
                  <span>
                    {store.postcode} {store.city}
                  </span>
                  <div className={classes.PropertySize}>
                    <div className={classes.PropertySizeCount}>
                      <img src={HomeSizeIcon} alt="Livable area" />
                      <p>{PriceFormatter(store.livable_area)} m<sup>2</sup></p>
                    </div>
                    {store.no_bedroom >= 1 && (
                      <div className={classes.PropertySizeCount}>
                        <img src={BedIson} alt="Bedroom" />
                        <p>{store.no_bedroom}</p>
                      </div>
                    )}
                  </div>
                  <p className={classes.PriceText}>
                                        <p className={classes.PriceText}>
                      € {PriceFormatter(store.sell_price)}
                      </p>
                    {" "}
                    {displayPrice(
                      store.is_sell,
                      store.is_rent,
                      store.rent_price,
                      store.rent_postfix,
                      store.sell_price,
                      store.sell_postfix,
                      t
                    )}
                  </p>
                  {store?.agency_detail?.name && (
                    <p className={classes.CardLink}>
                      {store?.agency_detail?.name}
                    </p>
                  )}
                </div>

                <div className={classes.iconWrap}>
                  {store.is_favourite == 1 ? <AiFillStar /> : <AiOutlineStar />}
                  {store.energy_class != "undefined" &&
                    store.energy_class != "" && (
                      <div
                        style={{
                          backgroundColor: ENERGY_LABEL.filter(
                            (val) => val.name == store.energy_class
                          )[0]?.color,
                        }}
                        className={classes.lable}
                      >
                        {store.energy_class}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </>
        </InfoWindowF>
      )}
    </React.Fragment>
  );
};
const DisplayMarkers = (props) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [visitedMarker, setVisitedMarker] = useState([]);
  const allPropertyData = useSelector(
    (state) => state.CommonReducer.myPropertyListingData?.alldata
  );

  const getAllPropertyData = useSelector(
    (state) => state.CommonReducer.getPropertyAllData
  );

  const [hoverMarker, setHoverMarker] = useState(false);
  const handleMarkerClose = () => {
    setSelectedMarker(null);
  };
  const handleVisitedMarker = (index) => {
    setVisitedMarker((prevVisitedMarker) => [...prevVisitedMarker, index]);
  };



  return getAllPropertyData?.map((store, index) => {
    const markerPosition = {
      lat: +store.latitude,
      lng: +store.longitude,
    };
    const isMarkerSelected =
      selectedMarker &&
      selectedMarker.position.lat === markerPosition.lat &&
      selectedMarker.position.lng === markerPosition.lng;

    const isVisited = visitedMarker.includes(store.id);
    let icon =
      hoverMarker === store.id
        ? hover_logo
        : isVisited
        ? visited_logo
        : default_logo;

    const handleMarkerClick = () => {
      if (isMarkerSelected) {
        handleMarkerClose();
      } else {
        setSelectedMarker({
          position: markerPosition,
          anchor: selectedMarker?.anchor,
        });
      }
    };

    return (
      <Mark
        setHoverMarker={setHoverMarker}
        hoverMarker={hoverMarker}
        store={store}
        isMarkerSelected={isMarkerSelected}
        isVisited={isVisited}
        markerPosition={markerPosition}
        selectedMarker={selectedMarker}
        handleMarkerClick={handleMarkerClick}
        handleVisitedMarker={handleVisitedMarker}
        handleMarkerClose={handleMarkerClose}
        icon={icon}
      />
    );
  });
};

export default DisplayMarkers;
