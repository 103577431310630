import React, { useState, useEffect, useRef } from "react";
import classes from "./highlightedlistingmain.module.scss";
import FiltersSection from "../FiltersSection";
import DisplayMarkers from "../../DisplayMarkers";
import { MdFilterAlt } from "react-icons/md";
import OffcanvasMain from "../../layouts/Offcanvas";
import PropertyCardMain from "../../layouts/PropertyCardDesign";
import ListingIcon from "../../../assets/icons/ListingIcon.svg";
import MapIcon from "../../../assets/icons/MapIcon.svg";
import ListingGrayIcon from "../../../assets/icons/ListingGrayIcon.svg";
import MapWhiteIcon from "../../../assets/icons/MapWhiteIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "../../layouts/MainContainer";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { t } from "i18next";
import {
  actionGetAgencyBannerClickCountApiCall,
  actionGetPropertyAllDataApiCall,
  actionLatestPropertyListingHomeApiCall,
} from "../../../Redux/Actions";
import fromDateMap from "../../../utils/fromDateMap";
import moment from "moment";
import RedirectionTab from "../../../utils/RedirectionTab";

const HighlightedListingMain = () => {
  const [filter, setFilter] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [totalPages, setTotalPages] = useState(0);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [currentPage, setCurrentPage] = useState(
    params.get("page") ? params.get("page") : 1
  );
  const [pageNumber, setPageNumber] = useState();
  const [activePage, setActivePage] = useState(
    params.get("page") ? params.get("page") : 1
  );
  const [activeListMap, setActiveListMap] = useState("List");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ITEMS_PER_PAGE = 12;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBQUfqFiocSQopYEXMdR60dO6Ma5Nn1Vj4",
  });
  const state_id = localStorage.getItem("user_id");
  const platform = localStorage.getItem("Platform");
  // const [selectedMarker, setSelectedMarker] = useState(null);
  const myRef = useRef();
  const bannerSizes = ["160*600", "300*600"];
  const count = useSelector(
    (state) => state.CommonReducer.myPropertyListingData?.count
  );
  const myPropertyListingData = useSelector(
    (state) => state?.CommonReducer?.myPropertyListingData?.rows
  );
  const allPropertyData = useSelector(
    (state) => state.CommonReducer.myPropertyListingData?.alldata
  );

  const getAllPropertyData = useSelector(
    (state) => state.CommonReducer.getPropertyAllData
  );

  const state = useSelector((state) => state.CommonReducer);
  console.log("state?.saveSearchStatus", state?.saveSearchStatus);

  const loading = useSelector((state) => state.CommonReducer.loading);
  const loginInformation = useSelector(
    (state) => state.CommonReducer.loginInfo
  );
  const saveAgencyBannerRecommendations =
    useSelector(
      (state) => state.CommonReducer.saveAgencyBannerRecommendations
    ) || {};
  const bannerData = saveAgencyBannerRecommendations["970*250"];
  const [map, setMap] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const onLoad = React.useCallback(
    function callback(map) {
      const points =
        (getAllPropertyData.length > 0 &&
          getAllPropertyData?.map((item, index) => {
            return { lat: +item.latitude, lng: +item.longitude };
          })) ||
        [];

      const validPoints = points.filter(
        (point) => point.lat !== 0 && point.lng !== 0
      );

      const bounds = new window.google.maps.LatLngBounds();

      for (var i = 0; i < validPoints?.length; i++) {
        bounds.extend(validPoints[i]);
      }

      map.fitBounds(bounds);

      setMap(map);
    },
    [getAllPropertyData]
  );
  useEffect(() => {
    if (isLoaded && map && isLoading) {
      setIsLoading(false);
    }
  }, [isLoaded, map, isLoading]);
  React.useCallback(function callback() {
    setMap(null);
  }, []);
  const saveHomeBanner = useSelector(
    (state) => state.CommonReducer.saveHomeBanner
  );
  const filterShow = () => {
    setFilter(true);
  };
  const handleClose = () => {
    setFilter(false);
  };
  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
  };
  const FilterDataHandler = async () => {
    const today = moment().format("YYYY-MM-DD");
    let data = {
      page: currentPage,
      per_page: 12,
      area: params.get("address") ? params.get("address") : "",
      region: state?.saveSearchBackground?.type,
      region_id: state?.saveSearchBackground?.id,
      distance: params.get("distance")
        ? parseFloat(params.get("distance").replace("km", ""))
        : "",
      minPrice: params.get("minPrice") ? params.get("minPrice") : "",
      maxPrice: params.get("maxPrice") ? params.get("maxPrice") : "",
      listingType: params.get("listingType") ? params.get("listingType") : "",
      energyClass: params.get("energyClass") ? params.get("energyClass") : "",
      description: params.get("description") ? params.get("description") : "",
      bedroom: params.get("bedroom") ? params.get("bedroom") : "",
      sqaureMeter: params.get("sqaureMeter") ? params.get("sqaureMeter") : "",
      status: params.get("status") ? params.get("status") : "",
      fromdate: params.get("days") ? fromDateMap[`${params.get("days")}`] : "",
      todate:
        params.get("days") != "" &&
        params.get("days") != "No preference" &&
        typeof params.get("days") != "object" &&
        today,
      user_id: state_id,
      property_type: params.get("propertyType")
        ? params.get("propertyType")
        : "",
      construction_year: params.get("constructionYear")
        ? params.get("constructionYear")
        : "",
    };

    dispatch(actionLatestPropertyListingHomeApiCall(data));

    const queryParams = [
      `page=${currentPage}`,
      params.get("address") ? `address=${params.get("address")}` : "",
      params.get("Distance") ? `distance=${params.get("Distance")}` : "",
      params.get("minPrice") ? `minPrice=${params.get("minPrice")}` : "",
      params.get("maxPrice") ? `maxPrice=${params.get("maxPrice")}` : "",
      params.get("listingType")
        ? `listingType=${params.get("listingType")}`
        : "",
      params.get("energyClass")
        ? `energyClass=${params.get("energyClass")}`
        : "",
      params.get("bedroom") ? `bedroom=${params.get("bedroom")}` : "",
      params.get("sqaureMeter")
        ? `sqaureMeter=${params.get("sqaureMeter")}`
        : "",
      params.get("status") ? `status=${params.get("status")}` : "",
      params.get("description")
        ? `description=${params.get("description")}`
        : "",
      params.get("days") ? `days=${params.get("days")}` : "",
      params.get("propertyType")
        ? `propertyType=${params.get("propertyType")}`
        : "",
      params.get("constructionYear")
        ? `constructionYear=${params.get("constructionYear")}`
        : "",
    ];
    const queryString = queryParams.filter(Boolean).join("&");
    navigate(`/search?${queryString}`);
  };
  useEffect(() => {
    if (screenWidth <= 768) {
      if (!filter) {
        FilterDataHandler();
      }
    }
  }, [currentPage]);
  useEffect(() => {
    const pageNumbers = [];
    let totalPages = Math.ceil(count / ITEMS_PER_PAGE);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    setTotalPages(totalPages);
    setPageNumber(pageNumbers);
  }, [count]);
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const changeToDefaultPage = () => {
    setCurrentPage(1);
  };
  const pagination = () => {
    let ui = [];
    for (
      let i = activePage <= 4 ? 1 : activePage - 4;
      i <= (activePage <= 4 ? 8 : activePage + 4);
      i++
    ) {
      if (i <= pageNumber?.length) {
        ui.push(
          <div className={classes.page}>
            <span
              onClick={() => {
                handlePageChange(i);
              }}
              className={activePage == i ? classes.Active : classes.InActive}
            >
              {i}
            </span>
          </div>
        );
      }
    }
    myRef?.current?.scrollIntoView({ behavior: "smooth" });
    return ui;
  };

  const BannerClickHandler = (
    agency_banner_id,
    agency_banner_size_id,
    link_target,
    redirection_link
  ) => {
    let RedirectionLinkData = {
      link: redirection_link,
      link_target: link_target,
    };
    RedirectionTab(RedirectionLinkData, navigate);
    let ClickData = {
      agency_banner_id: agency_banner_id,
      agency_banner_size_id: agency_banner_size_id,
    };
    let data = JSON.stringify(ClickData);
    const body = {
      navigate,
      data,
    };
    dispatch(actionGetAgencyBannerClickCountApiCall(body));
  };

  return (
    <React.Fragment>
      <div className={classes.HighlightedListingSection}>
        <MainContainer>
          <div className={classes.HighlightedListingWrap}>
            <div className={classes.LeftSide}>
              {screenWidth > 768 && (
                <>
                  <div className={classes.FiltersPart}>
                    <FiltersSection
                      currentPage={currentPage}
                      changeToDefaultPage={changeToDefaultPage}
                    />
                  </div>
                  {Object.keys(saveAgencyBannerRecommendations)?.length > 0 &&
                    bannerSizes.map((size) => {
                      const banner = saveAgencyBannerRecommendations[size];
                      return (
                        <>
                          {banner && (
                            <div
                              className={classes.AdsWrap}
                              onClick={() => {
                                BannerClickHandler(
                                  banner.agency_banner_id,
                                  banner.id,
                                  banner.agency_banners.link_target,
                                  banner.agency_banners.redirection_link
                                );
                              }}
                            >
                              {banner.url_original_file ? (
                                <img
                                  src={banner.url_original_file}
                                  className={classes[`Banner${banner.size}`]}
                                  alt=""
                                />
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: banner.iframe,
                                  }}
                                ></div>
                              )}
                            </div>
                          )}
                        </>
                      );
                    })}
                </>
              )}
              <div className={classes.BtnWrap}>
                <button onClick={filterShow}>
                  <MdFilterAlt />
                  {t("filter")}
                </button>
              </div>
            </div>
            <div className={classes.RigthSide} ref={myRef}>
              {Object.keys(saveAgencyBannerRecommendations)?.length > 0 &&
                bannerData && (
                  <div
                    className={classes.bannerWrap}
                    onClick={() => {
                      BannerClickHandler(
                        bannerData.agency_banner_id,
                        bannerData.id,
                        bannerData.agency_banners.link_target,
                        bannerData.agency_banners.redirection_link
                      );
                    }}
                  >
                    {bannerData.url_original_file ? (
                      <img src={bannerData.url_original_file} alt="" />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{ __html: bannerData.iframe }}
                      ></div>
                    )}
                  </div>
                )}
              <div className={classes.TitleWrap}>
                <h1>{t("highlighted_listings")}</h1>
                {(platform === "DEV" ||
                  platform === "UAT" ||
                  platform === "PROD" ||
                  loginInformation.user_type === "Admin") && (
                  <div className={classes.ListAndMap}>
                    <button
                      className={
                        activeListMap === "List"
                          ? classes.ListBtn
                          : classes.MapBtn
                      }
                      onClick={() => {
                        setActiveListMap("List");
                      }}
                    >
                      <img
                        src={
                          activeListMap === "List"
                            ? ListingIcon
                            : ListingGrayIcon
                        }
                      />
                      {t("list")}
                    </button>
                    <button
                      className={
                        activeListMap === "Map"
                          ? classes.ListBtn
                          : classes.MapBtn
                      }
                      onClick={() => {
                        setActiveListMap("Map");
                      }}
                    >
                      <img
                        src={activeListMap === "Map" ? MapWhiteIcon : MapIcon}
                      />
                      {t("map")}
                    </button>
                  </div>
                )}
              </div>
              <div>
                {loading === true ? (
                  <>
                    <div className={classes.LoaderMain}>
                      <Loader />
                    </div>
                  </>
                ) : activeListMap === "List" ? (
                  <div className={classes.CardListing}></div>
                ) : (
                  <div className={classes.MapSection}>
                    {isLoaded &&
                    (platform === "DEV" ||
                      platform === "UAT" ||
                      platform === "PROD" ||
                      loginInformation.user_type === "Admin") ? (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        onLoad={onLoad}
                      >
                        {isLoading ? null : <DisplayMarkers />}
                      </GoogleMap>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
              {activeListMap === "List" && (
                <div>
                  {!loading && (
                    <>
                      <PropertyCardMain searchSplit={{ start: 0, end: 6 }} />
                    </>
                  )}
                </div>
              )}

              {Object.keys(saveAgencyBannerRecommendations)?.length > 0 &&
                bannerData && (
                  <div
                    className={classes.MiddleAdContainer}
                    onClick={() => {
                      BannerClickHandler(
                        bannerData.agency_banner_id,
                        bannerData.id,
                        bannerData.agency_banners.link_target,
                        bannerData.agency_banners.redirection_link
                      );
                    }}
                  >
                    {bannerData.url_original_file ? (
                      <img src={bannerData.url_original_file} alt="" />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{ __html: bannerData.iframe }}
                      ></div>
                    )}
                  </div>
                )}
              {activeListMap === "List" && (
                <div>
                  {!loading && (
                    <>
                      <PropertyCardMain
                        searchSplit={{
                          start: 6,
                          end: myPropertyListingData?.length,
                        }}
                        noData={true}
                      />
                      {!loading && (
                        <div className={classes.Pagination}>
                          <div className={classes.PageWrap}>
                            {currentPage > 1 && currentPage <= totalPages && (
                              <div
                                className={
                                  activePage !== 1
                                    ? classes.NextPageActive
                                    : classes.NextPageInActive
                                }
                                onClick={() => {
                                  if (activePage !== 1) {
                                    setCurrentPage(currentPage - 1);
                                    setActivePage(currentPage - 1);
                                  }
                                }}
                              >
                                {"<"} <span>{t("prev_page")}</span>
                              </div>
                            )}
                            {pageNumber?.length >= 1 && (
                              <span className={classes.pageText}>
                                {t("page")}
                              </span>
                            )}
                            {state?.saveSearchStatus == "enter" && pagination()}
                            {pageNumber?.length > 8 && (
                              <div className={classes.page}>
                                {" "}
                                <span className={classes.pageText}>
                                  {t("of")}
                                </span>
                                <span
                                  onClick={() => {
                                    handlePageChange(pageNumber?.length);
                                  }}
                                  className={
                                    activePage === pageNumber?.length
                                      ? classes.Active
                                      : classes.InActive
                                  }
                                >
                                  {pageNumber?.length}
                                </span>
                              </div>
                            )}
                          </div>
                          {currentPage < totalPages && (
                            <div
                              className={
                                activePage !== pageNumber?.length - 1
                                  ? classes.NextPageActive
                                  : classes.NextPageInActive
                              }
                              onClick={() => {
                                if (activePage !== pageNumber?.length) {
                                  setCurrentPage(+currentPage + 1);
                                  setActivePage(+currentPage + 1);
                                }
                              }}
                            >
                              <span>{t("next_page")}</span> {">"}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </MainContainer>

        {/* {AdsData?.length > 0 && <AddsLayoyt adsData={AdsData} />} */}
        {/* <MainContainer>
          <div className={classes.HighlightedListingWrap}>
            <div className={classes.LeftSide}></div>
            {activeListMap === "List" && (
              <div className={classes.RigthSide}>
                <PropertyCardMain
                  searchSplit={{
                    start: 6,
                    end: myPropertyListingData?.length,
                  }}
                  noData={true}
                />
                {!loading && (
                  <div className={classes.Pagination}>
                    <div className={classes.PageWrap}>
                      {currentPage > 1 && currentPage <= totalPages && (
                        <div
                          className={
                            activePage !== 1
                              ? classes.NextPageActive
                              : classes.NextPageInActive
                          }
                          onClick={() => {
                            if (activePage !== 1) {
                              setCurrentPage(currentPage - 1);
                              setActivePage(currentPage - 1);
                            }
                          }}
                        >
                          {"<"} <span>{t("prev_page")}</span>
                        </div>
                      )}
                      {pageNumber?.length >= 1 && (
                        <span className={classes.pageText}>{t("page")}</span>
                      )}
                      {pageNumber?.length > 0 && pagination()}
                      {pageNumber?.length > 8 && (
                        <div className={classes.page}>
                          {" "}
                          <span className={classes.pageText}>{t("of")}</span>
                          <span
                            onClick={() => {
                              handlePageChange(pageNumber.length);
                            }}
                            className={
                              activePage === pageNumber.length
                                ? classes.Active
                                : classes.InActive
                            }
                          >
                            {pageNumber.length}
                          </span>
                        </div>
                      )}
                    </div>
                    {currentPage < totalPages && (
                      <div
                        className={
                          activePage !== pageNumber.length - 1
                            ? classes.NextPageActive
                            : classes.NextPageInActive
                        }
                        onClick={() => {
                          if (activePage !== pageNumber.length) {
                            setCurrentPage(+currentPage + 1);
                            setActivePage(+currentPage + 1);
                          }
                        }}
                      >
                        <span>{t("next_page")}</span> {">"}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </MainContainer> */}
        <OffcanvasMain
          showcanvas={filter}
          handleClose={handleClose}
          title="Filters"
        >
          <FiltersSection
            currentPage={currentPage}
            handleClose={handleClose}
            changeToDefaultPage={changeToDefaultPage}
          />
        </OffcanvasMain>
      </div>
    </React.Fragment>
  );
};
export default React.memo(HighlightedListingMain);
