import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import ThankyouMain from "../../components/ThankyouMain";

const ThankyouPage = () => {
  return (
    <React.Fragment>
      <Header />
      <ThankyouMain />
      <Footer />
    </React.Fragment>
  );
};

export default ThankyouPage;
