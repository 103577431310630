import React from "react";
import AddBannerMain from "../../components/AddBannerMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";

const AddBannerPage = () => {
  return (
    <React.Fragment>
      <Header />
      <AddBannerMain />
      <Footer />
    </React.Fragment>
  );
};

export default AddBannerPage;
