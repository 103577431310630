import React from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import MyAddBannerLayout from "../layouts/MyAddBannerLayout";
import classes from "./addbanner.module.scss";

const AddBannerMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.AddBannerContainer}>
          <div className={classes.AddBannerHeading}>
            <h1>{t("add_banner_title")}</h1>
          </div>
          <MyAddBannerLayout />
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default AddBannerMain;
