import React from "react";
import Header from "../../layouts/Header/Index";
import PropartySearchBar from "../../layouts/PropartySearchBar";
import classes from "./propartysearchmain.module.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import MainContainer from "../../layouts/MainContainer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionMolliePaymentApiCall,
  actionSavePropertyType,
} from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const PropartySearchMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [propertyType, setPropertyType] = useState("both");
  const [homeImage, setHomeImage] = useState("");
  const dispatch = useDispatch();
  const saveHomeBanner = useSelector(
    (state) => state.CommonReducer.saveHomeBanner
  );
  useEffect(() => {
    saveHomeBanner?.rows?.length > 0 &&
      saveHomeBanner?.rows?.find((item, index) => {
        if (item.position === "Home Top") {
          setHomeImage(item.url_original_file);
        }
      });
  }, [saveHomeBanner]);

  useEffect(() => {
    dispatch(actionSavePropertyType("both"));
  }, []);

  return (
    <div
      className={classes.PropartySearchContainer}
      style={{ backgroundImage: `url(${homeImage})` }}
    >
      <div className={classes.BackgroundShadow}>
        <Header />
      </div>
      <MainContainer>
        <div className={classes.SearchSection}>
          <div className={classes.SearchWrap}>
            <div className={classes.ButtonWrap}>
              <button
                className={
                  propertyType === "sell"
                    ? classes.BtnItemActive
                    : classes.BtnItem
                }
                onClick={() => {
                  setPropertyType("sell");
                  dispatch(actionSavePropertyType("sell"));
                }}
              >
                {t("buy")}
              </button>
              <button
                className={
                  propertyType === "rent"
                    ? classes.BtnItemActive
                    : classes.BtnItem
                }
                onClick={() => {
                  setPropertyType("rent");
                  dispatch(actionSavePropertyType("rent"));
                }}
              >
                {t("rent")}
              </button>
              <button
                className={
                  propertyType === "both"
                    ? classes.BtnItemActive
                    : classes.BtnItem
                }
                onClick={() => {
                  setPropertyType("both");
                  dispatch(actionSavePropertyType("both"));
                }}
              >
                {t("both")}
              </button>
            </div>
            <div>
              <PropartySearchBar listingType={propertyType} />
            </div>
          </div>
          <div className={classes.TextWrap}>
            {!localStorage.getItem("authToken") && (
              <p onClick={() => navigate("/create-organisation-account-step1")}>
                {t(
                  "want_to_start_selling_a_listing_start_by_creating_an_account_here"
                )}
              </p>
            )}

            {/* <p onClick={() => navigate("/membership-pricing")}>
              {t(
                "want_to_start_selling_a_listing_start_by_creating_an_account_here"
              )}
            </p> */}
          </div>
        </div>
      </MainContainer>
    </div>
  );
};
export default PropartySearchMain;
