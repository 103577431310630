import React from "react";
import FavouritePropretyMain from "../../components/FavouritePropretyMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import TitleProvider from "../../components/TitleProvider";
import { useTranslation } from "react-i18next";

const FavouritePropretyPage = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <TitleProvider title={t("favourites")} />
      <Header />
      <FavouritePropretyMain />
      <Footer />
    </React.Fragment>
  );
};

export default FavouritePropretyPage;
