import React from "react";
import classes from "./comingsoonmain.module.scss";
import ComimgSoonImg from "../../assets/images/ComingSoonImg.svg";

const ComingSoonMain = (props) => {
  return (
    <React.Fragment>
      <div className={classes.ComingSoonSection}>
        <div className={classes.ImgWrap}>
          <img src={ComimgSoonImg} alt="Coming soon" />
        </div>
        <h1>{props?.data?.data?.title}</h1>
      </div>
    </React.Fragment>
  );
};

export default ComingSoonMain;
