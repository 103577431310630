import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import MainLayout from "../layouts/MainLayout";
import classes from "./organisationmain.module.scss";
const OrganisationMain = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.OrganisationContainer}>
          <div className={classes.AddOrganisationHeading}>
            <h1>
              {location.pathname == "/added-an-organisation"
                ? t("adding_an_organisation")
                : t("joining_an_organisation")}
            </h1>
          </div>
          <div className={classes.AddOrganisationDescription}>
            <p className={classes.soonText}>
              {t("you_will_hear_from_us_soon")}
            </p>
            <p>
              {location.pathname == "/added-an-organisation"
                ? t("the_request_has_been_received_organisation")
                : t("the_request_has_been_received_to_join_this_organisation")}
            </p>
            <p>
              {t("finalising_this_process")} <span>{t("2_hours")}</span>
            </p>
          </div>
          <div className={classes.ReturnBtn}>
            <button
              onClick={() => {
                navigate("/my-organisations");
              }}
            >
              {t("return_to_my_account")}
            </button>
          </div>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};
export default OrganisationMain;
