import React, { useEffect, useState } from "react";
import classes from "./mybannerlayout.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  actionMakeBannerStopApiCall,
  actionViewBannerDetailsApiCall,
  actionSaveBannerDetails,
} from "../../../Redux/Actions";
import { useNavigate, useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import ModalMain from "../../common/Modal";
import PriceFormatter from "../../../utils/PriceFormatter";
import CanvasJSReact from "@canvasjs/react-charts";
import Loader from "../../Loader";

const MyBannerLayout = () => {
  const { t } = useTranslation();
  const params = useParams();
  const state_id = localStorage.getItem("user_id");

  const loading = useSelector((state) => state.CommonReducer.loading);

  const platform = localStorage.getItem("Platform");

  var CanvasJS = CanvasJSReact.CanvasJS;
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;

  // const [spreadType, setSpreadType] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let CJS = {};

  const bannerDetails = useSelector(
    (state) => state.CommonReducer.bannerDetails
  );

  console.log(bannerDetails);

  const [data, setData] = useState([]);

  console.log("bannerDetails", bannerDetails);

  const options = {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: true,
    title: {
      text: "Aantal views per dag",
      fontSize: 20,
    },
    axisY: {
      title: "Views",
    },
    axisX: {
      labelAngle: -20,
    },

    toolTip: {
      shared: true,
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "right",
      reversed: true,
      cursor: "pointer",
    },
    data: bannerDetails?.chart_options?.data,
  };

   const [showInfoScreen, setShowInfoScreen] = useState(false);
  const [showTitle, setShowTitle] = useState();
  const [showContent, setShowContent] = useState();

  const showInfo = (campaignId, banner) => {
    setShowContent(
      `<iframe title="preview" src="https://woningmarkt.s3.amazonaws.com/${
        platform !== "PROD" ? "development" : "production"
      }/templates/${banner.template}/index.html?banner=${getBannerId(
        bannerDetails,
        banner.template
      )}" height="${banner.size[1]}px" width="${banner.size[0]}px"></iframe>`
    );
    setShowTitle(`Code voor Banner`);
    setShowInfoScreen(true);
  };

  const stopBannerHandler = () => {
    let body = {
      data: { id: bannerDetails.id },
      navigate,
    };
    dispatch(actionMakeBannerStopApiCall(body));
    navigate("/my-banners");
  };

  const closeBannerHandler = () => {
    dispatch(actionSaveBannerDetails(null));
    navigate("/my-banners")
  };

  const getBannerId = (bDetails, templateId) => {
    if (bDetails) {
      for (let i = 0; i < bDetails?.campaign_banners?.length; i++) {
        const banner = bDetails?.campaign_banners[i].property_banner;
        if (banner.property_banner_template_id === templateId) {
          return banner.id;
        }
      }
    }
    return 0;
  };

  const sizes = [
    { title: "Leaderboard", size: [728, 90], template: 1 },
    { title: "Billboard", size: [970, 250], template: 1 },
    { title: "Medium rectangle", size: [300, 250], template: 6 },
    { title: "Half page", size: [300, 600], template: 6 },
  ];

  if (!loading) {
    
    console.log("not loading anymore...");
    console.log(bannerDetails);
    console.log("params", params)
    if (parseInt(params.id) != bannerDetails?.id) {
      const data = {id: params.id}
      dispatch(actionSaveBannerDetails(null));
      dispatch(actionViewBannerDetailsApiCall(data))
    }
    console.log(CJS);
  } else {
    console.log("loading ...");
  }
 
  return (
    <div className={classes.MyBannerLayoutMain}>
      {!loading ? (
        <div className={classes.BannerFormMain}>
          <div className={classes.BannerTitle}>
            <p>{bannerDetails?.title}</p>
          </div>
          {bannerDetails?.chart_options?.data.length > 0 && (
            <CanvasJSChart
              options={options}
              onRef={(ref) => (CJS.chart = ref)}
            />
          )}
          {bannerDetails?.chart_options?.data.length === 0 && (
            <p>{t("banner_not_viewed")}</p>
          )}
          <div className={classes.RegionTitle}>
            <p>{t("banner_regions")}</p>
            <div className={classes.InputFieldBox}>
              <div className={classes.Regions}>
                {bannerDetails?.campaign_regions?.map((region) => {
                  return (
                    <>
                      <div className={classes.RegionName}>{region.region}</div>
                      <div className={classes.RegionRadius}>
                        Straal {region.radius} km.
                      </div>
                      <div className={classes.RegionRadius}>
                        Budget € {region.region_budget_details[0].credits},-
                      </div>
                      <div className={classes.RegionRadius}>
                        Rest. €{" "}
                        {(
                          "" +
                          region.region_budget_details[0].remaining_credits /
                            1000
                        )
                          .replace(".", ",")
                          .replace(",00", ",-")}
                        {("" +
                          region.region_budget_details[0].remaining_credits /
                            1000).indexOf(".") < 0 ? ",-" : ""}
                      </div>
                      {region?.region_budget_details[0].budget_supplier_details.map(
                        (sup) => {
                          return (
                            <>
                              <div></div>
                              <div className={classes.Suppliers}>
                                {sup.supplier_details.url}
                              </div>
                              <div>cpm: € {sup.cpm},-</div>
                              <div></div>
                            </>
                          );
                        }
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={classes.BannerTemplateWrap}>
            <div className={classes.InputFieldBox}>
              <p>{t("banner_selected_template")}</p>
            </div>
          </div>
          <div className={classes.BannerTemplatePreviewWrap}>
            <div className={classes.BannerPreviewInnerWrap}>
              {sizes.map((banner) => {
                return (
                  <div className={classes.BannerInnerFrame}>
                    <p>
                      {banner.title}{" "}
                      <FaEye
                        alt={t("edit_banner")}
                        onClick={() => {
                          showInfo(bannerDetails?.id, banner);
                        }}
                        style={{ cursor: "pointer" }}
                      />{" "}
                    </p>
                    <iframe
                      title="preview"
                      src={`https://woningmarkt.s3.amazonaws.com/${
                        platform !== "PROD" ? "development" : "production"
                      }/templates/${
                        banner.template
                      }/index.html?banner=${getBannerId(
                        bannerDetails,
                        banner.template
                      )}`}
                      height={`${banner.size[1]}px`}
                      width={`${banner.size[0]}px`}
                    ></iframe>
                  </div>
                );
              })}
            </div>
          </div>
          {bannerDetails.active && (
            <button className={classes.stopButton} onClick={stopBannerHandler}>
              {t("banner_stop")}
            </button>
          )}
          <button className={classes.closeButton} onClick={closeBannerHandler}>
            {t("banner_close")}
          </button>
        </div>
      ) : (
        <div className={classes.LoaderWrap}>
          <Loader />
        </div>
      )}
      <ModalMain
        showmodel={showInfoScreen}
        handleClose={() => setShowInfoScreen(false)}
        dialogClassName={classes.ModaleSize}
        title={showTitle}
        children={showContent}
      ></ModalMain>
    </div>
  );
};
export default MyBannerLayout;
