import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import classes from "./stopcampaignmain.module.scss";
import TemplateLayout from "../layouts/TemplateLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionMakeBannerStopApiCall,
} from "../../Redux/Actions";

const StopCampaignMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams();

  useEffect(() => {
    let data = {
      cuuid: queryParameters.get("c"),
      uuid: queryParameters.get("u"),
    };
    let body = { data, navigate };
    dispatch(actionMakeBannerStopApiCall(body));
  }, [dispatch, navigate, queryParameters]);
  return (
    <React.Fragment>
      <TemplateLayout title={t("banner_stopped")} >
        <div className={classes.FiledPaymentSection}>
          <div className={classes.FiledPaymentContent}>
            <p>{t("banner_was_successfully_stopped")}</p>
          </div>
        </div>
      </TemplateLayout>
    </React.Fragment>
  );
};

export default StopCampaignMain;
