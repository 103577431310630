import React from "react";
import classes from "./maincontainer.module.scss";

const MainContainer = ({ children, style }) => {
  return (
    <div className={classes.ContainerMain} style={style}>
      {children}
    </div>
  );
};

export default MainContainer;
