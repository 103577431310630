import React from "react";
import AddListingMain from "../../components/AddListingMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";

const AddListingPage = () => {
  return (
    <React.Fragment>
      <Header />
      <AddListingMain />
      <Footer />
    </React.Fragment>
  );
};

export default AddListingPage;
