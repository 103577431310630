import React, { useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import IoginBtnIcon from "../../../assets/icons/loginBtnIcon.svg";
import classes from "../createAccountMain/createAccountMain.module.scss";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { actionRegisterApiCall } from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const CreateAccountMain = () => {
  const { handleChange, values, errors, onFocus, onBlur, handleSubmit } =
    useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [passwordType, setPasswordType] = useState("password");

  const showPassword = (show) => {
    show ? setPasswordType("text") : setPasswordType("password");
  };
  const loading = useSelector((state) => state.CommonReducer.loading);

  const handleOnClick = async (event) => {
    let result = await handleSubmit();
    if (event) event.preventDefault();
    if (result.email == "" && result.password == "") {
      let data = { email: values.email, password: values.password };
      let body = { data, navigate };
      if (values.email && values.password) {
        dispatch(actionRegisterApiCall(body));
      }
    }
  };
  return (
    <AuthLayout>
      <form className={classes.createAccountSection}>
        <div className={classes.createAccountContaint}>
          <div className={classes.createAccountHeading}>
            <h2>{t("create_account")}</h2>
          </div>
          {/* email start here */}
          <div className={classes.inputSection}>
            <label htmlFor="">{t("email")}</label>
            <input
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
          {errors.email && (
            <p className={`${classes.errorText}`}>{errors.email}</p>
          )}
          {/* password start here */}
          <div className={classes.inputSection}>
            <label htmlFor="">{t("password")}</label>
            <input
              type={passwordType}
              name="password"
              value={values.password.replace(/\s/g, "")}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {passwordType === "password" ? (
              <FaEye
                color="#707070"
                className={classes.eyeIcon}
                onClick={() => {
                  showPassword(true);
                }}
              />
            ) : (
              <FaEyeSlash
                color="#707070"
                className={classes.eyeIcon}
                onClick={() => {
                  showPassword(false);
                }}
              />
            )}
          </div>
          {errors.password && (
            <p className={`${classes.errorText}`}>{errors.password}</p>
          )}

          <div className={classes.alreadyHaveAccountBtnWrap}>
            <button className={classes.loginBtn} onClick={handleOnClick}>
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>
                  {t("create")}
                  <img src={IoginBtnIcon} alt="Create account" />
                </>
              )}
            </button>
          </div>
          <div className={classes.alreadyHaveAccountWrap}>
            <p>
              {t("i_already_have_an_account")}{" "}
              <span onClick={() => navigate("/login")}>{t("log_in_here")}</span>
            </p>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default CreateAccountMain;
