import React from "react";
import { Offcanvas } from "react-bootstrap";

const OffcanvasMain = (props) => {
  return (
    <Offcanvas show={props.showcanvas} onHide={props.handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{props.title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{props.children}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMain;
