import React, { useState } from "react";
import classes from "./loginMain.module.scss";
import IoginBtnIcon from "../../../assets/icons/loginBtnIcon.svg";
import AuthLayout from "../../layouts/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { actionLoginApiCall } from "../../../Redux/Actions";
import useForm from "../../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const LoginMain = () => {
  const { handleChange, values, errors, onFocus, onBlur, handleSubmit } =
    useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");
  const loading = useSelector((state) => state.CommonReducer.loading);

  const showPassword = (show) => {
    show ? setPasswordType("text") : setPasswordType("password");
  };
  const handleOnClick = async (event) => {
    let result = await handleSubmit();
    if (event) event.preventDefault();
    if (result.email == "" && result.password == "") {
      let data = { email: values.email, password: values.password };
      let body = { data, navigate };
      if (values.email && values.password) {
        dispatch(actionLoginApiCall(body));
      }
    }
  };
  return (
    <AuthLayout>
      <form className={classes.loginSection}>
        <div className={classes.loginContaint}>
          <div className={classes.loginHeading}>
            <h2>{t("login")}</h2>
          </div>
          <div className={classes.inputSection}>
            <label htmlFor="">{t("email")}</label>
            <input
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
          {errors.email && (
            <p className={`${classes.errorText}`}>{errors.email}</p>
          )}
          <div className={classes.inputSection}>
            <label htmlFor="">{t("password")}</label>
            <input
              type={passwordType}
              name="password"
              value={values.password.replace(/\s/g, "")}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {passwordType === "password" ? (
              <FaEye
                color="#707070"
                className={classes.eyeIcon}
                onClick={() => {
                  showPassword(true);
                }}
              />
            ) : (
              <FaEyeSlash
                color="#707070"
                className={classes.eyeIcon}
                onClick={() => {
                  showPassword(false);
                }}
              />
            )}
          </div>
          {errors.password && (
            <p className={`${classes.errorText}`}>{errors.password}</p>
          )}
          <div className={classes.forgotWrap}>
            <span onClick={() => navigate("/forgot-password")}>
              {t("forgot_password")}
            </span>
          </div>
          <div className={classes.loginBtnWrap}>
            <button className={classes.loginBtn} onClick={handleOnClick}>
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>
                  {t("login")}
                  <img src={IoginBtnIcon} alt="Login" />
                </>
              )}
            </button>
          </div>
          <div className={classes.createAccountWrap}>
            <p>{t("dont_have_an_account_yet")}</p>
            <span onClick={() => navigate("/create-account")}>
              {t("create_one_here")}
            </span>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default LoginMain;
