import React from 'react'
import classes from "./headercontainer.module.scss"
const HeaderContainer = ({children}) => {
  return (
    <div className={classes.headercontainerMain}>
        {children}
    </div>
  )
}

export default HeaderContainer;