import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import MembershipMain from "../../components/MembershipMain";

const MembershipPage = () => {
  return (
    <React.Fragment>
      <Header />
      <MembershipMain />
      <Footer />
    </React.Fragment>
  );
};

export default MembershipPage;
