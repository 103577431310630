import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import MyOrganisationListMain from "../../components/MyOrganisationListMain";
import { useTranslation } from "react-i18next";
import TitleProvider from "../../components/TitleProvider";

const MyOrganisationListPage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TitleProvider title={t("my_organisations")} />
      <Header />
      <MyOrganisationListMain />
      <Footer />
    </React.Fragment>
  );
};

export default MyOrganisationListPage;
