import React from "react";
import classes from "./templatelayout.module.scss";
import MainContainer from "../MainContainer";
import BulingIMG from "../../../assets/icons/WhiteBuldingImg.svg";
import SkylineIMG from "../../../assets/icons/Skyline.svg";

import TitleProvider from "../../TitleProvider";
import { useTranslation } from "react-i18next";

const TemplateLayout = ({ children, title, image, visible, bgImage }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TitleProvider title={t(title)} />
      {!visible && (
        <div
          className={classes.TemplateTitleSection}
          style={{ backgroundImage: `url('${image}')` }}
        >
          <MainContainer>
            <h1>{title}</h1>
          </MainContainer>
          <img src={bgImage ? SkylineIMG : BulingIMG} alt="Building" />
        </div>
      )}
      <MainContainer>{children}</MainContainer>
    </React.Fragment>
  );
};

export default TemplateLayout;
