import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  actionEmptySearchOrganisation,
  actionJoinOrganisationApiCall,
  actionSearchOrganisationApiCall,
} from "../../Redux/Actions";
import MainLayout from "../layouts/MainLayout";
import classes from "./joinorganisationmain.module.scss";

const JoinOrganisationMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState();
  const searchOrganisationData = useSelector(
    (state) => state.CommonReducer.saveSearchOrganisationData
  );
  const loading = useSelector((state) => state.CommonReducer.loading);

  const [organisationDetails, setOrganisationDetails] = useState({
    name: "",
    city: "",
  });
  useEffect(() => {
    dispatch(actionEmptySearchOrganisation());
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setOrganisationDetails({
      ...organisationDetails,
      [name]: value,
    });
  };

  const searchHandler = () => {
    let data = {
      page: 1,
      name: organisationDetails.name,
      city: organisationDetails.city,
    };
    let body = { data, navigate };
    dispatch(actionSearchOrganisationApiCall(body));
  };

  const JoinOrganisationHandler = () => {
    let data = {
      organisationId: id,
    };
    let body = { data, navigate };
    dispatch(actionJoinOrganisationApiCall(body));
  };
  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      event.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.JoinOrganisationContainer}>
          <form>
            <div className={classes.JoinOrganisationHeading}>
              <h1>{t("join_an_organisation")}</h1>
            </div>
            <div className={classes.JoinOrganisationDescription}>
              <div className={classes.InputContainer}>
                <p>{t("name_of_organisation")}</p>
                <input
                  type="text"
                  placeholder={t("frits_kuipers")}
                  name="name"
                  value={organisationDetails.name}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <div className={classes.InputContainer}>
                <p>{t("city")}</p>
                <input
                  type="text"
                  placeholder={t("groningen")}
                  name="city"
                  value={organisationDetails.city}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <div className={classes.JoinOrganisationBtn}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    searchHandler();
                  }}
                  className={classes.SearchButton}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    t("search")
                  )}
                </button>
              </div>
            </div>
          </form>
          <div className={classes.TextContainer}>
            {searchOrganisationData?.rows?.length > 0 && (
              <>
                <div className={classes.SearchData}>
                  <p className={classes.NoResultFound}>
                    {searchOrganisationData.length} {t("result_found")}
                  </p>
                  {searchOrganisationData.rows.map((item) => {
                    return (
                      <div
                        className={
                          id === item.id
                            ? `${classes.ActiveContent} ${classes.searchMainContent}`
                            : classes.searchMainContent
                        }
                        onClick={() => setId(item.id)}
                      >
                        <p>{item.name}</p>
                        <p>{item.address}</p>
                        <p>{`${item.postcode} ${item.city}`}</p>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {searchOrganisationData.count <= 0 && (
              <>
                <p className={classes.NoResultFound}>{t("no_results_found")}</p>
                <p>
                  {t("no_organisation_found")}{" "}
                  <span onClick={() => navigate("/add-an-organisation")}>
                    {t("add_this_organisation")}
                  </span>
                </p>
              </>
            )}
          </div>
          {searchOrganisationData.count > 0 && (
            <div className={classes.JoinOrganisationBtn}>
              <button
                onClick={JoinOrganisationHandler}
                disabled={id ? false : true}
                className={id ? classes.Active : classes.InActive}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  t("join_organisation")
                )}
              </button>
            </div>
          )}
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default JoinOrganisationMain;
