import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import ViewListingMain from "../../components/ViewListingMain";
// import AddLaoutImg from "../../assets/images/AddLayoutImg.svg";
import AdvertisementLayout from "../../components/layouts/AdvertisementLayout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { actionGetAgencyBannerRecommendationsApiCall } from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
const ViewListingPage = () => {
  const [AdsData, setAdsData] = useState([
    {
      id: 1,
      url_original_file: "",
      link: "",
      link_target: "",
      agency_banner_id: "",
    },
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saveAgencyBannerRecommendations = useSelector(
    (state) => state.CommonReducer.saveAgencyBannerRecommendations
  );
  useEffect(() => {
    const randomSizes = ["336*280", "160*600", "300*600"];
    function getRandomSize() {
      const randomIndex = Math.floor(Math.random() * randomSizes.length);
      return randomSizes[randomIndex];
    }
    let data = {
      bannerSizes: ["970*250", randomSizes],
    };
    let body = {
      navigate,
      data,
    };
    dispatch(actionGetAgencyBannerRecommendationsApiCall(body));
  }, []);
  useEffect(() => {
    if (
      saveAgencyBannerRecommendations &&
      saveAgencyBannerRecommendations["970*250"]
    ) {
      setAdsData([
        {
          width: "970px",
          height: "250px",
          id: saveAgencyBannerRecommendations["970*250"].id,
          url_original_file:
            saveAgencyBannerRecommendations["970*250"].url_original_file,
          iframe: saveAgencyBannerRecommendations["970*250"].iframe,
          link: saveAgencyBannerRecommendations["970*250"].agency_banners
            .redirection_link,
          link_target:
            saveAgencyBannerRecommendations["970*250"].agency_banners
              .link_target,
          agency_banner_id:
            saveAgencyBannerRecommendations["970*250"].agency_banner_id,
        },
      ]);
    }
  }, [saveAgencyBannerRecommendations]);

  return (
    <React.Fragment>
      {(AdsData[0].url_original_file || AdsData[0].iframe) && (
        <AdvertisementLayout adsData={AdsData} />
      )}
      <Header />
      <ViewListingMain />
      <Footer />
    </React.Fragment>
  );
};

export default ViewListingPage;
