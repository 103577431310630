import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import classes from "./myorganisationlistmain.module.scss";
import organisationmanagementIocn from "../../assets/icons/organisationmanagementIocn.svg";
import pinkPlus from "../../assets/icons/pinkPlus.svg";
import crosIcon from "../../assets/icons/crosIcon.svg";
import defaultImage from "../../assets/images/defaultImage.png";
import { useDispatch, useSelector } from "react-redux";
import {
  actionMyOrganisationListingApiCall,
  actionRemoveJoinOrganisationApiCall,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import ImageUrl from "../../API/ImageUrl";
import ConfirmationModal from "../layouts/ConfirmationModal";
import { FaEdit } from "react-icons/fa";
import EditIcon from "../../assets/icons/EditIcon.svg";

const MyOrganisationListMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState();
  const [organisationName, setOrganisationName] = useState();
  const loginInformation = useSelector(
    (state) => state.CommonReducer.loginInfo
  );

  const organisationListData = useSelector(
    (state) => state.CommonReducer.myOrganisationListingData
  );
  useEffect(() => {
    let body = { navigate };
    dispatch(actionMyOrganisationListingApiCall(body));
  }, []);

  const RemoveOrganisationHandler = () => {
    let data = {
      id: id,
    };
    let body = { data, navigate };
    dispatch(actionRemoveJoinOrganisationApiCall(body));
  };
  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.ConnectionContainer}>
          <div className={classes.TitleWrap}>
            <h1>{t("my_organisations")}</h1>
          </div>
          <div>
            {organisationListData?.length > 0 &&
              organisationListData.map((item) => {
                return (
                  <div className={classes.OrganizationContainer}>
                    <div
                      className={classes.Container}
                      onClick={() => navigate(`/view-employee/${item.id}`)}
                    >
                      <img
                        src={
                          item?.logo_image
                            ? item?.logo_image.includes("http")
                              ? item?.logo_image
                              : `${ImageUrl.IMAGE_BASE_URL}thumb/${item?.logo_image}`
                            : defaultImage
                        }
                        alt={item?.original_logo_name}
                      />
                      <p>
                        {item.name}, {item.city}
                      </p>
                    </div>
                    <div className={classes.CrosIconContainer}>
                      <img
                        src={crosIcon}
                        onClick={() => {
                          setId(item.id);
                          setShowModel(true);
                          setOrganisationName(item.name);
                        }}
                      />
                      {loginInformation.id == item.created_by && (
                        <FaEdit
                          src={EditIcon}
                          onClick={() =>
                            navigate(`/edit-an-organisation/${item.id}`)
                          }
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={classes.IconWrapper}>
            <div className={classes.IconContainer}>
              <img src={pinkPlus} alt="Add new organisation" />
              <p onClick={() => navigate("/add-an-organisation")}>
                {t("add_a_new_organisation")}
              </p>
            </div>
            <div className={classes.HideContainer}>
              <img
                src={organisationmanagementIocn}
                alt="Join an organisation"
              />
              <p>{t("join_an_organisation")}</p>
            </div>
          </div>
        </div>
      </MainLayout>
      <ConfirmationModal
        showmodel={showModel}
        handleClose={() => setShowModel(false)}
        size={"sm"}
        onClickYesButton={RemoveOrganisationHandler}
        title={`${t("are_you_sure_you_want_to_remove")} ${organisationName}?`}
      />
    </React.Fragment>
  );
};

export default MyOrganisationListMain;
