import React, { useEffect } from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import Loader from "../../components/Loader";
import AuthLayout from "../../components/layouts/AuthLayout";
import MainContainer from "../../components/layouts/MainContainer";
import classes from "./verificationpage.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionVerificationApiCall } from "../../Redux/Actions";
import VerifiedIcon from "../../assets/icons/VerifiedIcon.svg";
import InvalidIcon from "../../assets/icons/InvalidIcon.svg";
import { useTranslation } from "react-i18next";
const VerificationPage = () => {
  const { t } = useTranslation();
  const loading = useSelector((state) => state.CommonReducer.loading);
  const userVerified = useSelector((state) => state.CommonReducer.userVerified);
  let pathname = useLocation().pathname;
  pathname = pathname.substring(pathname.lastIndexOf("/") + 1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    let data = pathname;
    let body = { data, navigate, path: "/home" };
    dispatch(actionVerificationApiCall(body));
  }, []);
  return (
    <React.Fragment>
      <Header />
      <AuthLayout>
        <MainContainer>
          <div className={classes.VerificationPage}>
            {loading ? (
              <div className={classes.VerificationContainer}>
                <h3>{t("checking_verification")}</h3>
                <Loader />
              </div>
            ) : (
              <>
                {userVerified ? (
                  <h3 className={classes.verified}>
                    {t("verified")} <img src={VerifiedIcon} alt="Verified" />
                  </h3>
                ) : (
                  <h3 className={classes.Invalid}>
                    {t("invalid")} <img src={InvalidIcon} alt="Invalid" />
                  </h3>
                )}
              </>
            )}
          </div>
        </MainContainer>
      </AuthLayout>
      <Footer />
    </React.Fragment>
  );
};

export default VerificationPage;
