import { useSelector } from "react-redux";
import { seo } from "../../utils/seo";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const TitleProvider = (props) => {
  const selectedLanguage = useSelector(
    (state) => state.CommonReducer.selectedLanguage
  );

  useEffect(() => {
    seo({
      title: props.title + " | Woningmarkt.nl",
    });
    return () => {
      seo({
        title: "",
      });
    };
  }, [selectedLanguage?.language, props.title]);

  return <></>;
};
export default TitleProvider;
