import React from "react";
import EditListingMain from "../../components/EditListingMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";

const EditListingPage = () => {
	return (
		<React.Fragment>
			<Header />
			<EditListingMain />
			<Footer />
		</React.Fragment>
	);
};

export default EditListingPage;
