import React from "react";
import classes from "./organisationhighlightmain.module.scss";
import { Image } from "react-bootstrap";
import MainContainer from "../../layouts/MainContainer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bannerClickHandler from "../../../utils/bannerClickHandler";

const OrganisationHighlightMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const saveAgencyBannerRecommendations =
    useSelector(
      (state) => state.CommonReducer.saveAgencyBannerRecommendations
    ) || {};
  const OrgHomeMiddle = saveAgencyBannerRecommendations?.["Home Middle"];

  return (
    <React.Fragment>
      {OrgHomeMiddle && (
        <div className={classes.OrganisationHighlightSection}>
          <MainContainer>
            <div className={classes.OrganisationHighlightMain}>
              <div
                className={classes.OrganisationImg}
                onClick={() => {
                  bannerClickHandler(
                    OrgHomeMiddle?.agency_banner_id,
                    OrgHomeMiddle?.id,
                    OrgHomeMiddle?.agency_banners?.link_target,
                    OrgHomeMiddle?.agency_banners?.redirection_link,
                    navigate,
                    dispatch
                  );
                }}
              >
                {OrgHomeMiddle?.url_original_file ? (
                  <Image
                    fluid={true}
                    src={OrgHomeMiddle?.url_original_file}
                    alt={OrgHomeMiddle?.original_file_name}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{ __html: OrgHomeMiddle.iframe }}
                  ></div>
                )}
              </div>
            </div>
          </MainContainer>
        </div>
      )}
    </React.Fragment>
  );
};

export default OrganisationHighlightMain;
