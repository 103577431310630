const RedirectionTab = (HomeLeft, navigate) => {
  let url = new URL(window.location.href);
  let baseurl = `${url.protocol}//${url.host}`;
  if (HomeLeft?.link && HomeLeft?.link_target) {
    if (HomeLeft?.link_target.toLowerCase() === "same tab") {
      navigate(`/${HomeLeft?.link}`);
    } else if (HomeLeft?.link_target.toLowerCase() === "new tab") {
      window.open(`${baseurl}/${HomeLeft?.link}`);
    } else {
      window.open(
        `${baseurl}/${HomeLeft?.link}`,
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
  }
};

export default RedirectionTab;
