const PriceFormatter = (price, fractionDigit) => {
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).resolvedOptions().maximumFractionDigits;
  return (+price).toLocaleString("de-DE", {
    maximumFractionDigits: formatter,
    minimumFractionDigits: fractionDigit ? fractionDigit : 0, // Ensures at least 2 decimal places
  });
};
export default PriceFormatter;
