import React from "react";
import AccountCreatedMain from "../../../components/auth/AccountCreatedMain";
import Footer from "../../../components/layouts/Footer/Index";
import Header from "../../../components/layouts/Header/Index";

const AccountCreatedPage = () => {
  return (
    <React.Fragment>
      <Header />
      <AccountCreatedMain />
      <Footer />
    </React.Fragment>
  );
};

export default AccountCreatedPage;
