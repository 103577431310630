import React from "react";
import classes from "./confirmationmodal.module.scss";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ConfirmationModal = (props) => {
  const { t } = useTranslation();
  const select = useSelector((state) => state.CommonReducer);
  const onClickofShowAcc = () => {};

  return (
    <Modal
      show={props.showmodel}
      onHide={props.handleClose}
      size={props.size}
      centered
    >
      <Modal.Body>
        {select?.usedEmailFlag ? (
          <div className={classes.UsedEmailWrap}>
            <h5>{t("This e-mail is already in use..")}</h5>
            <p>{t("Please select an option")}</p>
            <button onClick={onClickofShowAcc}>
              {t("Show account with this e-mail")}
            </button>
          </div>
        ) : (
          <div className={classes.ConfirmationWap}>
            <h5>{props.title}</h5>
            <div className={classes.ConfirmationBtnWrap}>
              <button
                onClick={() => {
                  props.onClickYesButton();
                  props.handleClose();
                }}
              >
                {t("Yes")}
              </button>
              <button onClick={props.handleClose}>{t("No")}</button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
