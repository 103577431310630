import AuthLayout from "../../layouts/AuthLayout";
import OtpInput from "react-otp-input";
import classes from "./otpMain.module.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionForgotPasswordApiCall,
  actionOtpApiCall,
} from "../../../Redux/Actions";
import "../../../assets/style/variable.scss";
import { useTranslation } from "react-i18next";

const OtpMain = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [otp, setOtp] = useState(params.otp ? params.otp : "");
  const handleChange = (otp) => {
    setOtp(otp);
  };
  const forgotEmail = useSelector(
    (state) => state.CommonReducer.saveForgotEmail
  );
  const loading = useSelector((state) => state.CommonReducer.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleVerifyOtp = (event) => {
    event.preventDefault();

    if (otp.length == 6) {
      let data;

      if (params.token) {
        data = {
          token: params.token,
          otp: Number(otp),
        };
      } else {
        data = {
          email: forgotEmail,
          otp: Number(otp),
        };
      }
      let body = { data, navigate };
      dispatch(actionOtpApiCall(body));
    } else {
      toast.error(t("please_enter_otp"));
    }
  };
  const renderInput = (inputProps) => {
    return <input {...inputProps} />;
  };

  return (
    <AuthLayout>
      <form className={classes.OtpSection}>
        <div className={classes.OtpContaint}>
          <div className={classes.OtpHeading}>
            <h2>
              {t("we_have_send_the_code_verification_to_your_email_address")}
            </h2>
          </div>
          <div className={classes.otpInput}>
            <OtpInput
              isInputNum={true}
              value={otp}
              onChange={handleChange}
              numInputs={6}
              separator={<span></span>}
              inputStyle={{ width: "100%", margin: "0 0.5rem" }}
              renderInput={renderInput}
            />
          </div>
          <div className={classes.VerifyBtnWrap}>
            <button className={classes.VerifyBtn} onClick={handleVerifyOtp}>
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                t("verify_otp")
              )}
            </button>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};
export default OtpMain;
