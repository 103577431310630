import React from "react";
import SubscriptionCard from "../SubscriptionCard";
import classes from "./subscriptioncardwrapmain.module.scss";

const SubscriptionCardWrapMain = (props) => {
  return (
    <div className={classes.SubscriptionCardWrap}>
      <SubscriptionCard
        SetDescription={props.SetDescription}
        DataHandler={props.DataHandler}
      />
    </div>
  );
};

export default SubscriptionCardWrapMain;
