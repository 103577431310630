import React from "react";
import ViewPropertyBannerMain from "../../components/ViewPropertyBannerMain";
import "./viewpropertybannerpage.scss";

const ViewPropertyBannerPage = () => {
	return (
    <React.Fragment>
        <meta
          name="GCD"
          content="YTk3ODQ3ZWZhN2I4NzZmMzBkNTEwYjJl93e9d195f161fef0b04eae9c99801763"
        />
        <meta charset="utf-8" />
        <meta name="generator" content="Google Web Designer 15.2.1.0306" />
        <meta name="template" content="Banner 3.0.0" />
        <meta name="environment" content="gwd-genericad" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="../../assets/style/gwdpage_style.css"
          rel="stylesheet"
          data-version="13"
          data-exports-type="gwd-page"
        />
        <link
          href="../../assets/style/gwdpagedeck_style.css"
          rel="stylesheet"
          data-version="14"
          data-exports-type="gwd-pagedeck"
        />
        <link
          href="../../assets/style/gwdimage_style.css"
          rel="stylesheet"
          data-version="17"
          data-exports-type="gwd-image"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Passion+One:700,regular|Mulish:800,700"
          rel="stylesheet"
          type="text/css"
        ></link>
        <script
          data-source="gwd_webcomponents_v1_min.js"
          data-version="2"
          data-exports-type="gwd_webcomponents_v1"
          src="http://woningmarkt.s3.amazonaws.com/development/assets/gwd_webcomponents_v1_min.js"
        ></script>
        <script
          data-source="gwdpage_min.js"
          data-version="13"
          data-exports-type="gwd-page"
          src="http://woningmarkt.s3.amazonaws.com/development/assets/gwdpage_min.js"
        ></script>
        <script
          data-source="gwdpagedeck_min.js"
          data-version="14"
          data-exports-type="gwd-pagedeck"
          src="http://woningmarkt.s3.amazonaws.com/development/assets/gwdpagedeck_min.js"
        ></script>
        <script
          data-source="gwdgenericad_min.js"
          data-version="8"
          data-exports-type="gwd-genericad"
          src="http://woningmarkt.s3.amazonaws.com/development/assets/gwdgenericad_min.js"
        ></script>
        <script
          data-source="gwdimage_min.js"
          data-version="17"
          data-exports-type="gwd-image"
          src="http://woningmarkt.s3.amazonaws.com/development/assets/gwdimage_min.js"
        ></script>
      <ViewPropertyBannerMain />
    </React.Fragment>
  );
};

export default ViewPropertyBannerPage;
