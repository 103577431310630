import React from 'react'
import LoginMain from '../../../components/auth/LoginMain'
import Footer from '../../../components/layouts/Footer/Index'
import Header from '../../../components/layouts/Header/Index'

const LoginPage = () => {
  return (
    <React.Fragment>
        <Header/>
        <LoginMain/>
        <Footer/>
    </React.Fragment>
  )
}

export default LoginPage