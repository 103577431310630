import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import classes from "./viewlistingmain.module.scss";
import ListingIma from "../../assets/images/MainListingImg.png";
import defaultImage from "../../assets/images/defaultImage.png";
import { useTranslation } from "react-i18next";
import HomeSizeIcon from "../../assets/icons/HomeSizeIcon.svg";
import BedIson from "../../assets/icons/BedIcon.svg";
import ContactIcon from "../../assets/icons/ContactIcon.svg";
import EmainIcon from "../../assets/icons/EmainIcon.svg";
import ListingViews from "../../assets/icons/ListingViews.svg";
import ListingDate from "../../assets/icons/ListingDate.svg";
import ListingFav from "../../assets/icons/ListingFav.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionSaveSinglePropertyDetails,
  actionViewSinglePropertyDetailsApiCall,
} from "../../Redux/Actions";
import ViewListingPhotos from "./ViewListingPhotos";
import descriptionArrowIcon from "../../assets/icons/descriptionArrowIcon.svg";
import MainContainer from "../layouts/MainContainer";
import moment from "moment";
import PriceFormatter from "../../utils/PriceFormatter";
import RedirectionTab from "../../utils/RedirectionTab";
import Loader from "../Loader";
import AddsLayoyt from "../layouts/AddsLayout";
import { ENERGY_LABEL } from "../../Constants";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import displayPrice from "../../utils/displayPrice";
import SquareMeterIcon from "../../assets/icons/SquareMeterIcon.svg";
const ViewPropertyBannerMain = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const singlePropertyDetails = useSelector(
    (state) => state.CommonReducer.singlePropertyDetails
  );
  const [mainImage, setMainImage] = useState([]);
  const [remainingImage, setRemainingImage] = useState([]);
  const [index, setIndex] = useState();
  const navigate = useNavigate();
  const [isReadMore, setIsReadMore] = useState(true);
  const IMAGE_BASE_URL = "https://api-test.woningmarkt.nl/uploads/";
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBQUfqFiocSQopYEXMdR60dO6Ma5Nn1Vj4",
  });
  const saveHomeBanner = useSelector(
    (state) => state.CommonReducer.saveHomeBanner
  );
  const loading = useSelector((state) => state.CommonReducer.loading);
  useEffect(() => {
    window.scrollTo(0, 0);
    let data = {
      property_id: params.id,
    };
    dispatch(actionSaveSinglePropertyDetails(""));
    dispatch(actionViewSinglePropertyDetailsApiCall(data));
  }, []);
  useEffect(() => {
    let MainImageData = [];
    let RemainingData = [];
    singlePropertyDetails?.image_list?.map((item) => {
      if (item.sub_type === "MAIN_PHOTO") {
        MainImageData.push(item);
      } else {
        RemainingData.push(item);
      }
    });
    setMainImage(MainImageData);
    setRemainingImage(RemainingData);
  }, [singlePropertyDetails]);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const AdsData = saveHomeBanner?.rows
    ?.filter(
      (item, index, self) =>
        (item.position === "Listing View Ad 1" ||
          item.position === "Listing View Ad 2" ||
          item.position === "Listing View Ad 3" ||
          item.position === "Listing View Ad 4") &&
        index === self.findIndex((t) => t.position === item.position)
    )
    .sort(function (a, b) {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
  return (
    <>
      {loading === true ? (
        <div className={classes.LoaderMain}>
          <Loader />
        </div>
      ) : (
        <div className={classes.ViewListingMain}>
          <gwd-genericad id="gwd-ad">
            <a href="https://ditisnewz.nl">
              <gwd-pagedeck class="gwd-page-container" id="pagedeck">
                <gwd-page
                  id="page1"
                  class="gwd-page-wrapper gwd-page-size gwd-lightbox"
                  data-gwd-width="100%"
                  data-gwd-height="100%"
                >
                  <div class="gwd-page-content gwd-page-size">
                    <gwd-image
                      id="afbeelding"
                      source="assets/afbeelding.jpg"
                      scaling="stretch"
                      class="gwd-image-1axp"
                    ></gwd-image>
                    <h2 class="gwd-p-13jk">Te koop</h2>
                    <h2 class="gwd-h2-et6u">
                      €299.500 k.k.
                      <br />
                    </h2>
                    <h2 class="gwd-h2-16ee">Doorenlaan 200 Assen</h2>
                    <gwd-image
                      id="afbeelding_2"
                      source="assets/afbeelding.jpg"
                      scaling="stretch"
                      class="gwd-image-1huh"
                    ></gwd-image>
                    <h2 class="gwd-h2-b5os">
                      €299.500 k.k.
                      <br />
                    </h2>
                    <h2 class="gwd-h2-p4xx">Doorenlaan 200 Assen</h2>
                    <h3 class="gwd-h2-uc4l">woningmarkt.nl</h3>
                    <gwd-image
                      id="jouwlogo"
                      source="assets/jouwlogo.jpg"
                      scaling="stretch"
                      class="gwd-image-uhx8"
                    ></gwd-image>
                  </div>
                </gwd-page>
              </gwd-pagedeck>
            </a>
          </gwd-genericad>
          <script gwd-served-sizes="">["728x90"]</script>
        </div>
      )}
    </>
  );
};
export default ViewPropertyBannerMain;
