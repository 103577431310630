import React, { useEffect, useState } from "react";
import classes from "./mylistinglayout.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const MolliePaymentLayout = () => {
  const { t } = useTranslation();
  const params = useParams();
  const invoiceData = useSelector(
    (state) => state.CommonReducer.myInvoiceData
  );

  console.log(invoiceData);

  const loading = useSelector((state) => state.CommonReducer.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState();
  useEffect(() => {
  }, [params]);
  return (
    <div className={classes.MyListingLayoutMain}>
      {invoiceData && (
        <div className={classes.AddListingOption}>
        </div>
      )}
    </div>
  );
};
export default MolliePaymentLayout;
