import { actionGetAgencyBannerClickCountApiCall } from "../Redux/Actions";
import RedirectionTab from "./RedirectionTab";

export const bannerClickHandler = (
  agency_banner_id,
  agency_banner_size_id,
  link_target,
  redirection_link,
  navigate,
  dispatch
) => {
  let RedirectionLinkData = {
    link: redirection_link,
    link_target: link_target,
  };
  RedirectionTab(RedirectionLinkData, navigate);
  let ClickData = {
    agency_banner_id: agency_banner_id,
    agency_banner_size_id: agency_banner_size_id,
  };
  let data = JSON.stringify(ClickData);
  const body = {
    navigate,
    data,
  };
  dispatch(actionGetAgencyBannerClickCountApiCall(body));
};

export default bannerClickHandler;
