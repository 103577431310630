import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import classes from "./myinvoicesmain.module.scss";
import organisationmanagementIocn from "../../assets/icons/organisationmanagementIocn.svg";
import pinkPlus from "../../assets/icons/pinkPlus.svg";
import crosIcon from "../../assets/icons/crosIcon.svg";
import defaultImage from "../../assets/images/defaultImage.png";
import { AiFillFilePdf, AiFillEuroCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  actionMyInvoicesApiCall,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import ImageUrl from "../../API/ImageUrl";
import { FaEdit } from "react-icons/fa";
import EditIcon from "../../assets/icons/EditIcon.svg";
import PriceFormatter from "../../utils/PriceFormatter";
import {  actionSaveInvoiceData } from "../../Redux/Actions"
import { MakePaymentApiCall } from "../../API/API";
import axios from "axios";
import { DateTime } from "luxon";

const MyInvoicesMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const platform = localStorage.getItem("Platform");
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState();
  const [organisationName, setOrganisationName] = useState();
  const loginInformation = useSelector(
    (state) => state.CommonReducer.loginInfo
  );

  const user_id = localStorage.getItem("user_id");

  const invoicesData = useSelector(
    (state) => state.CommonReducer.myInvoicesData
  );
  useEffect(() => {
    let body = { id: user_id };
    dispatch(actionMyInvoicesApiCall(body));
  }, []);

  const handlePayment = async (invoice) => {
    console.log("in handlePayment");
    dispatch(actionSaveInvoiceData(invoice));
    const body = {invoice_id: invoice.id};
    const payment = await MakePaymentApiCall(body);
    console.log(payment.data.url);
    window.location = payment.data.url;
  };

  console.log("invoicesData", invoicesData);

   return (
     <React.Fragment>
       <MainLayout>
         <div className={classes.ConnectionContainer}>
           <div className={classes.TitleWrap}>
             <h1>{t("my_invoices")}</h1>
           </div>
           <div>
              {invoicesData?.count > 0 &&
               invoicesData?.invoices.map((item) => {
                 return (
                   <div className={classes.InvoiceContainer}>
                     <div>
                       <p>{t("invoice_number")}</p>
                       <p>{item.number}</p>
                     </div>
                     <div>
                       <p>{t("invoice_date")}</p>
                       <p>
                         {item.invoice_date === null
                           ? t("invoice_unknown_date")
                           : DateTime.fromISO(item.invoice_date).toFormat(
                               "dd-LL-yyyy"
                             )}
                       </p>
                     </div>
                     <div>
                       <p>{t("invoice_status")}</p>
                       <p className={item?.total_amount === item.amount_paid ? classes.paid : ""}>
                         {item.kind === "credit" ? "credit" : item?.total_amount !== item.amount_paid
                           ? t("payment_waiting")
                           : t("payment_paid_through") +
                             " " +
                             (typeof item.payment_details != "undefined" && item.payment_details.length > 0 &&
                             item.payment_details[0].provider !== null
                               ? item.payment_details[0].provider
                               : t("payment_provider_unknown"))}
                       </p>
                     </div>
                     <div>
                       <p>{t("invoice_total")}</p>
                       <p>{`€ ${PriceFormatter(item?.total_amount, 2).replace(
                         ",00",
                         ",-"
                       )}`}</p>
                     </div>
                     <div className={classes.IconWrapper}>
                       {item.total_amount !== item.amount_paid && item.kind !== "credit" ? (
                         <button
                           className={classes.pay}
                           onClick={() => handlePayment(item)}
                         >
                           {t("invoice_pay")}
                         </button>
                       ) : (
                         ""
                       )}{" "}
                       <a
                         href={`https://woningmarkt.s3.amazonaws.com/${
                           platform === "DEV" || platform === "UAT"
                             ? "development"
                             : "production"
                         }/invoices/${item.number}.pdf`}
                         target="blank"
                       >
                         <button className={classes.download}>
                           {t("invoice_download")}
                         </button>
                       </a>
                     </div>
                   </div>
                 );
               })}
           </div>
           <div className={classes.IconWrapper}></div>
         </div>
       </MainLayout>
     </React.Fragment>
   );
};

export default MyInvoicesMain;
