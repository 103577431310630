import React from "react";
import OtpMain from "../../../components/auth/OtpMain";
import Footer from "../../../components/layouts/Footer/Index";
import Header from "../../../components/layouts/Header/Index";

const OtpPage = () => {
  return (
    <React.Fragment>
      <Header />
      <OtpMain />
      <Footer />
    </React.Fragment>
  );
};

export default OtpPage;
