import React from "react";
import ConnectionMain from "../../components/ConnectionMain/index";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";

const ConnectionPage = () => {
  return (
    <React.Fragment>
      <Header />
      <ConnectionMain />
      <Footer />
    </React.Fragment>
  );
};

export default ConnectionPage;