import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import classes from "./viewlistingmain.module.scss";
import defaultImage from "../../assets/images/defaultImage.png";
import { useTranslation } from "react-i18next";
import HomeSizeIcon from "../../assets/icons/HomeSizeIcon.svg";
import BedIson from "../../assets/icons/BedIcon.svg";
import ContactIcon from "../../assets/icons/ContactIcon.svg";
import EmainIcon from "../../assets/icons/EmainIcon.svg";
import ListingViews from "../../assets/icons/ListingViews.svg";
import ListingDate from "../../assets/icons/ListingDate.svg";
import ListingFav from "../../assets/icons/ListingFav.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionContactUsInfoApiCall,
  actionSaveSinglePropertyDetails,
  actionViewSinglePropertyDetailsApiCall,
} from "../../Redux/Actions";
import descriptionArrowIcon from "../../assets/icons/descriptionArrowIcon.svg";
import uparrowIcon from "../../assets/icons/uparrowIcon.svg";
import MainContainer from "../layouts/MainContainer";
import moment from "moment";
import Loader from "../Loader";
import { ENERGY_LABEL } from "../../Constants";
import { useJsApiLoader } from "@react-google-maps/api";
import displayPrice from "../../utils/displayPrice";
import SquareMeterIcon from "../../assets/icons/SquareMeterIcon.svg";
import Validate from "../../utils/Validation";
import PicturesIcon from "../../assets/icons/PicturesIcon.svg";
import VideoIcon from "../../assets/icons/VideoIcon.svg";
import DownloadIcon from "../../assets/icons/DownloadIcon.svg";
import FloorPlanIcon from "../../assets/icons/FloorPlanIcon.svg";
import VideoPlayIcon from "../../assets/icons/VideoPlayIcon.svg";
import TitleDescriptionProvider from "../TitleDescriptionProvider";
import PageNotFoundMain from "../../components/PageNotFoundMain";
import ReactPlayer from "react-player";
import SendContactAndDisplayAds from "../SendContactAndDisplayAds";
import PriceFormatter from "../../utils/PriceFormatter";

const ViewListingMain = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const singlePropertyDetails = useSelector(
    (state) => state.CommonReducer.singlePropertyDetails
  );
  const [mainImage, setMainImage] = useState([]);
  const [remainingImage, setRemainingImage] = useState([]);
  const navigate = useNavigate();
  const [isReadMore, setIsReadMore] = useState(true);
  const [isCollapse, setIsCollapse] = useState(true);
  const [queryParameters] = useSearchParams();

  const [contactInfo, setContactInfo] = useState({
    contactName: "",
    email: "",
    phone: "",
  });
  const [error, setError] = useState();
  const [downloadBrochure, setDownloadBrochure] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyBQUfqFiocSQopYEXMdR60dO6Ma5Nn1Vj4",
  // });
  const saveHomeBanner = useSelector(
    (state) => state.CommonReducer.saveHomeBanner
  );
  const loading = useSelector((state) => state.CommonReducer.loading);
  const isPropertyAvailable = useSelector(
    (state) => state.CommonReducer.isPropertyAvailable
  );

  const TRANSFER = [
    {
      key: displayPrice(
        singlePropertyDetails?.is_sell,
        singlePropertyDetails?.is_rent,
        singlePropertyDetails?.rent_price,
        singlePropertyDetails?.rent_postfix,
        singlePropertyDetails?.sell_price,
        singlePropertyDetails?.sell_postfix,
        t
      ),
      value:
        singlePropertyDetails?.is_sell === 1
          ? singlePropertyDetails?.sell_prefix
            ? `${t(singlePropertyDetails?.sell_prefix)}`
            : t("price")
          : singlePropertyDetails?.is_rent === 1 &&
            singlePropertyDetails?.rent_prefix
          ? ` ${t(singlePropertyDetails?.rent_prefix)}`
          : t("price"),
    },
    {
      key:
        singlePropertyDetails?.is_sell === 1
          ? `€ ${PriceFormatter(
              singlePropertyDetails?.sell_price /
                Number(singlePropertyDetails?.livable_area),
              2
            )}`
          : singlePropertyDetails?.is_rent === 1 &&
            `€ ${PriceFormatter(
              singlePropertyDetails?.rent_price /
                Number(singlePropertyDetails?.livable_area),
              2
            )}`,
      value: t("price_per_m"),
    },
    {
      key: moment(singlePropertyDetails?.created_at).format("DD-MM-YYYY"),
      value: t("on_woningmarkt_nl_since"),
    },
  ];
  const CONSTRUCTION = [
    { key: t(singlePropertyDetails?.property_type), value: t("type_of_house") },
    {
      key: singlePropertyDetails?.construction_type,
      value: t("type_of_construction"),
    },
    { key: singlePropertyDetails?.construction_year, value: t("year") },
    { key: t(singlePropertyDetails?.roof_type), value: t("type_of_roof") },
  ];
  const BUILDING_AREA = [
    {
      key: Math.trunc(singlePropertyDetails?.livable_area),
      value: t("living_area"),
    },
    {
      key: Math.trunc(singlePropertyDetails?.building_related_outdoor_space),
      value: t("building_releated_outdoor_space"),
    },
    {
      key: Math.trunc(singlePropertyDetails?.external_storage_space),
      value: t("external_storage_space"),
    },
    { key: Math.trunc(singlePropertyDetails?.lot), value: t("lot") },
    {
      key: Math.trunc(singlePropertyDetails?.content),
      value: t("house_content"),
    },
  ];

  const OUTSIDE_AREA = [
    { key: singlePropertyDetails?.location, value: t("location") },
    { key: singlePropertyDetails?.garden, value: t("garden") },
    { key: singlePropertyDetails?.backyard, value: t("backyard") },
    {
      key: singlePropertyDetails?.garden_location,
      value: t("location_garden"),
    },
  ];
  const CLASSIFICATION = [
    { key: singlePropertyDetails?.no_rooms, value: t("number_of_rooms") },
    {
      key: singlePropertyDetails?.no_bathrooms,
      value: t("number_of_bathrooms"),
    },
    {
      key: singlePropertyDetails?.bathroom_facilities,
      value: t("bathroom_facility"),
    },
    { key: singlePropertyDetails?.no_storeys, value: t("number_of_storeys") },
    { key: singlePropertyDetails?.facilities, value: t("facilities") },
    { key: singlePropertyDetails?.internet, value: t("internet") },
  ];
  const ENERGY_DETAILS = [
    { key: singlePropertyDetails?.energy_class, value: t("energy_label") },
    { key: singlePropertyDetails?.insulation, value: t("insulation") },
    { key: singlePropertyDetails?.heating, value: t("heating") },
    { key: singlePropertyDetails?.warm_water, value: t("warm_water") },
    {
      key: singlePropertyDetails?.central_heating,
      value: t("central_heating_boiler"),
    },
  ];
  const GARAGE = [
    { key: singlePropertyDetails?.garage_type, value: t("garage") },
    { key: singlePropertyDetails?.garage_capacity, value: t("capacity_two") },
  ];
  const PARKING = [
    { key: singlePropertyDetails?.parking_type, value: t("type_of_parking") },
  ];
  // useEffect(() => {
  //   const handleFocus = () => {
  //     // Re-fetch data or reset the loading state to trigger a refetch
  //     dispatch(actionViewSinglePropertyDetailsApiCall(body)); // Adjust the action as needed
  //     setShowLoader(true); // Reset the loading state
  //   };
  
  //   window.addEventListener('focus', handleFocus);
  
  //   return () => {
  //     window.removeEventListener('focus', handleFocus);
  //   };
  // }, []);
  useEffect(() => {
    // Function to fetch product details
    const fetchProductDetails = () => {
      window.scrollTo(0, 0);
      // Clear previous product details and start the loader immediately
      dispatch(actionSaveSinglePropertyDetails(""));
      setShowLoader(true);
  
      // Fetch new product details
      const data = {
        property_id: params.id,
        source: queryParameters.get("source"),
      };
      const body = { data, navigate };
      dispatch(actionViewSinglePropertyDetailsApiCall(body));
    };
  
    // Fetch product details on initial load
    fetchProductDetails();
  
    // Handle focus events to refetch data if the user returns to the page
    const handleFocus = () => {
      console.log("loader true inside handlefocus");
      fetchProductDetails(); // Re-fetch product details on window focus
    };
  
    window.addEventListener('focus', handleFocus);
  
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [params.id, navigate, dispatch, queryParameters]);
  
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   let data = {
  //     property_id: params.id,
  //     source: queryParameters.get("source"),
  //   };
  //   let body = { data, navigate };
  //   dispatch(actionSaveSinglePropertyDetails(""));
  //   dispatch(actionViewSinglePropertyDetailsApiCall(body));
  // }, []);

  useEffect(() => {
    if (loading === false && singlePropertyDetails && Object.keys(singlePropertyDetails).length > 0) {
      console.log("photos inside if");
      
      let MainImageData = [];
      let RemainingData = [];
  
      singlePropertyDetails?.image_list?.forEach((item) => {
        if (item.sub_type === "MAIN_PHOTO") {
          MainImageData.push(item);
        } else if (item.type !== "BROCHURE") {
          RemainingData.push(item);
        }
      });
  
      const floorplanData = RemainingData.filter(
        (item) => item.type === "FLOORPLAN"
      );
      const nonFloorplanData = RemainingData.filter(
        (item) => item.type !== "FLOORPLAN"
      );
      const updatedData = [...nonFloorplanData, ...floorplanData];
  
      setMainImage(MainImageData);
      setRemainingImage(updatedData);
      setDownloadBrochure(
        singlePropertyDetails?.image_list?.filter(
          (val) => val.type === "BROCHURE"
        )
      );
  
      // Stop loader after a short delay to ensure smooth transition
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 1000); // Adjust the delay as necessary
  
      // Cleanup timeout on component unmount or when data changes
      return () => clearTimeout(timer);
    }
  }, [loading, singlePropertyDetails]);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const toggleCollapse = () => {
    setIsCollapse(!isCollapse);
    // document.getElementsByClassName('LeftFeatureWrap').setAttribute("style","height:500px");
  };
  const setHeight = () => {
    if (isCollapse) {
      document.getElementById("LeftFeatureWrap").style.height = "auto";
      document.getElementById("RightFeatureWrap").style.height = "auto";
    } else {
      document.getElementById("LeftFeatureWrap").style.height = "400px";
      document.getElementById("RightFeatureWrap").style.height = "400px";
      document.getElementById("LeftFeatureWrap").style.overflow = "hidden";
      document.getElementById("RightFeatureWrap").style.overflow = "hidden";
    }
  };
  // const AdsData = saveHomeBanner?.rows
  //   ?.filter(
  //     (item, index, self) =>
  //       (item.position === "Listing View Ad 1" ||
  //         item.position === "Listing View Ad 2" ||
  //         item.position === "Listing View Ad 3" ||
  //         item.position === "Listing View Ad 4") &&
  //       index === self.findIndex((t) => t.position === item.position)
  //   )
  //   .sort(function (a, b) {
  //     if (a.position < b.position) {
  //       return -1;
  //     }
  //     if (a.position > b.position) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // const handleChange = (e) => {
  //   let { name, value } = e.target;
  //   setContactInfo({
  //     ...contactInfo,
  //     [name]: value,
  //   });
  //   let error = Validate(name, value);
  //   setError(error);
  // };
  // const handleKeyPressForNumber = (event) => {
  //   const charCode = event.which ? event.which : event.keyCode;
  //   if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
  //     event.preventDefault();
  //   }
  // };
  const contactInfoHandler = (e) => {
    e.preventDefault();
    let error = Validate("", "", true, contactInfo);
    setError(error);
    if (Object.values(error).every((x) => x == null || x == "")) {
      let data = {
        name: contactInfo.contactName,
        email: contactInfo.email,
        phone: contactInfo.phone,
        property_id: params.id,
      };
      let body = { data, navigate };
      dispatch(actionContactUsInfoApiCall(body));
      setContactInfo({
        contactName: "",
        email: "",
        phone: "",
      });
    }
  };
  const downloadBrochureHandler = () => {
    if (downloadBrochure && downloadBrochure.length > 0) {
      downloadBrochure.forEach((url) => window.open(url.url_original_file));
    }
  };
  function generateListingPhotoURL(details, id, stateValue) {
    if (details) {
      const url = stateValue
        ? `/view-listing-photo/${
            details.city &&
            details.city
              .toLowerCase()
              .replace(/ /g, "-")
              .replace(/[^\w-]+/g, "")
          }/${
            details.address &&
            details.address
              .toLowerCase()
              .replace(/ /g, "-")
              .replace(/[^\w-]+/g, "")
          }/${details.id}`
        : `/view-listing-photo/${
            details.city && details.city.replace(/\s+/g, "-")
          }/${details.address && details.address.replace(/\s+/g, "-")}/${
            details.id
          }`;

      const state = id;

      return { url, state };
    } else {
      // Handle the case where some required properties are null or undefined
      console.error(
        "Error: One or more required properties are null or undefined."
      );
      return null;
    }
  }

  const videoPlayer = (url, id, index) => {
    return (
      <>
        <div
          className={classes.VideoWrap}
          onClick={() => {
            const navigationDetails = generateListingPhotoURL(
              singlePropertyDetails,
              { id: id }
            );

            if (navigationDetails) {
              navigate(navigationDetails.url, {
                state: navigationDetails.state,
              });
            }
          }}
        >
          <ReactPlayer
            url={url}
            muted={true}
            playing={index ? true : false}
            width="100%"
            height="100%"
            controls={index ? true : false}
          />
          {!index && (
            <span
              onClick={() => {
                const navigationDetails = generateListingPhotoURL(
                  singlePropertyDetails,
                  { id: id }
                );

                if (navigationDetails) {
                  navigate(navigationDetails.url, {
                    state: navigationDetails.state,
                  });
                }
              }}
            >
              <img src={VideoPlayIcon} alt="Video" />
            </span>
          )}
        </div>
      </>
    );
  };
  const FeaturesDiv = ({ Array, name, widthpass, tablewidth }) => {
    const width = widthpass ? widthpass : "";
    const tblwidth = tablewidth ? tablewidth : "";
    let value = Array.some((item) => !!item.key);
    return (
      <>
        {value && (
          <table
            style={{
              width: "100%",
            }}
          >
            <th>
              <h6>{name}</h6>
            </th>
            {Array.map((item) => {
              return (
                <>
                  {!!item.key && (
                    <tr>
                      <td
                        style={{
                          width: "50%",
                        }}
                      >
                        <p className={classes.ContentHeading}>{item.value}</p>
                      </td>
                      {item.value == t("energy_label") ? (
                        <div
                          style={{
                            backgroundColor: ENERGY_LABEL.filter(
                              (val) =>
                                val.name == singlePropertyDetails?.energy_class
                            )[0]?.color,
                          }}
                          className={classes.lable}
                        >
                          {singlePropertyDetails?.energy_class}
                        </div>
                      ) : (
                        <td
                          style={{
                            width: "50%",
                            lineBreak: "anywhere",
                          }}
                        >
                          <p className={classes.ContentValue}>
                            {item?.value == t("price_per_m") ? (
                              <>
                                {" "}
                                {item.key} m<sup> 2</sup>{" "}
                              </>
                            ) : item.key == 0 ? (
                              ""
                            ) : (
                              item.key
                            )}
                          </p>
                        </td>
                      )}
                    </tr>
                  )}
                </>
              );
            })}
          </table>
        )}
      </>
    );
  };
  return (
    <>
      <TitleDescriptionProvider
        title={`${t("property_meta_title", {
          property_type: t(
            singlePropertyDetails?.is_sell == 1 &&
              singlePropertyDetails?.is_rent == 1
              ? "both"
              : singlePropertyDetails?.is_sell == 1
              ? "sell"
              : "rent"
          ),
          address: singlePropertyDetails?.address,
          city: singlePropertyDetails?.city,
        })}`}
        metaDescription={`${t("property_meta_description", {
          property_type: t(
            singlePropertyDetails?.is_sell == 1 &&
              singlePropertyDetails?.is_rent == 1
              ? "both"
              : singlePropertyDetails?.is_sell == 1
              ? "sell"
              : "rent"
          ),
          address: singlePropertyDetails?.address,
          city: singlePropertyDetails?.city,
        })}`}
      />
      {showLoader ? (
        <div className={classes.LoaderMain}>
          <Loader />
        </div>
      ) : isPropertyAvailable ? (
        <div className={classes.ViewListingMain}>
          <MainContainer>
            <div className={classes.ImgSection}>
              <div className={classes.ListingImages}>
                <div className={classes.MainImg}>
                  <div className={classes.MainImgWrap}>
                    {mainImage?.length > 0 ? (
                      mainImage[0]?.type == "VIDEO" ? (
                        <>
                          {videoPlayer(
                            mainImage?.length > 0
                              ? mainImage[0]?.url_original_file
                              : remainingImage[0]?.url_original_file,
                            mainImage?.length > 0
                              ? mainImage[0].id
                              : remainingImage[0].id,
                            1
                          )}
                        </>
                      ) : (
                        <img
                          src={
                            mainImage?.length > 0
                              ? mainImage[0]?.url_normalized_file
                              : remainingImage[0]?.url_normalized_file
                          }
                          onClick={() => {
                            const navigationDetails = generateListingPhotoURL(
                              singlePropertyDetails,
                              {
                                id:
                                  mainImage?.length > 0
                                    ? mainImage[0].id
                                    : remainingImage?.length > 0
                                    ? remainingImage[0].id
                                    : null,
                              }
                            );

                            if (navigationDetails) {
                              navigate(navigationDetails.url, {
                                state: navigationDetails.state,
                              });
                            }
                          }}
                          alt={mainImage[0]?.original_file_name}
                        />
                      )
                    ) : remainingImage[0]?.type == "VIDEO" ? (
                      <>
                        {videoPlayer(
                          remainingImage[0].url_original_file,
                          mainImage?.length > 0
                            ? mainImage[0].id
                            : remainingImage[0].id,
                          1
                        )}
                      </>
                    ) : (
                      <img
                        src={remainingImage[0]?.url_normalized_file}
                        onClick={() => {
                          const navigationDetails = generateListingPhotoURL(
                            singlePropertyDetails,
                            {
                              id:
                                mainImage?.length > 0
                                  ? mainImage[0].id
                                  : remainingImage[0].id,
                            }
                          );

                          if (navigationDetails) {
                            navigate(navigationDetails.url, {
                              state: navigationDetails.state,
                            });
                          }
                        }}
                        alt={mainImage[0]?.original_file_name}
                      />
                    )}
                    {singlePropertyDetails?.status &&
                      singlePropertyDetails.status.toUpperCase() !==
                        "AVAILABLE" && (
                        <div className={classes.UnderOption}>
                          {t(singlePropertyDetails?.status)}
                        </div>
                      )}
                  </div>
                </div>
                <div className={classes.OtherImg}>
                  <div className={classes.OtherImgWarp}>
                    {remainingImage?.length > 0 &&
                      remainingImage.map((item, index) => {
                        if (mainImage.length <= 0) {
                          if (index !== 0 && index <= 4) {
                            return (
                              <>
                                <div className={classes.ImgWrap}>
                                  {item.type == "PHOTO" ||
                                  item.type == "FLOORPLAN" ? (
                                    <img
                                      src={item.url_original_file}
                                      onClick={() => {
                                        const navigationDetails =
                                          generateListingPhotoURL(
                                            singlePropertyDetails,
                                            { id: item.id }
                                          );

                                        if (navigationDetails) {
                                          navigate(navigationDetails.url, {
                                            state: navigationDetails.state,
                                          });
                                        }
                                      }}
                                      alt={item.original_file_name}
                                    />
                                  ) : (
                                    <>
                                      {videoPlayer(
                                        item.url_original_file,
                                        item.id
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            );
                          }
                        } else {
                          if (index <= 3) {
                            return (
                              <>
                                <div className={classes.ImgWrap}>
                                  {item.type == "PHOTO" ||
                                  item.type == "FLOORPLAN" ? (
                                    <img
                                      src={item.url_thumb_file}
                                      onClick={() => {
                                        const navigationDetails =
                                          generateListingPhotoURL(
                                            singlePropertyDetails,
                                            { id: item.id }
                                          );

                                        if (navigationDetails) {
                                          navigate(navigationDetails.url, {
                                            state: navigationDetails.state,
                                          });
                                        }
                                      }}
                                      alt={item.original_file_name}
                                    />
                                  ) : (
                                    <>
                                      {videoPlayer(
                                        item.url_original_file,
                                        item.id
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            );
                          }
                        }
                      })}
                  </div>
                </div>
              </div>
              <div className={classes.ImageContent}>
                {singlePropertyDetails?.image_list?.filter(
                  (val) => val.type == "PHOTO"
                ).length > 0 && (
                  <div
                    className={classes.Content}
                    onClick={() => {
                      const navigationDetails = generateListingPhotoURL(
                        singlePropertyDetails,
                        {
                          name: "PHOTO",
                        },
                        true
                      );

                      if (navigationDetails) {
                        navigate(navigationDetails.url, {
                          state: navigationDetails.state,
                        });
                      }
                    }}
                  >
                    <img src={PicturesIcon} alt="pictures" />
                    <p>
                      {t("pictures")}{" "}
                      <span>
                        {
                          singlePropertyDetails?.image_list?.filter(
                            (val) => val.type == "PHOTO"
                          ).length
                        }
                      </span>
                    </p>
                  </div>
                )}
                {singlePropertyDetails?.image_list?.filter(
                  (val) => val.type == "FLOORPLAN"
                ).length > 0 && (
                  <div
                    className={classes.Content}
                    onClick={() => {
                      const navigationDetails = generateListingPhotoURL(
                        singlePropertyDetails,
                        {
                          name: "FLOORPLAN",
                        },
                        true
                      );

                      if (navigationDetails) {
                        navigate(navigationDetails.url, {
                          state: navigationDetails.state,
                        });
                      }
                    }}
                  >
                    <img src={FloorPlanIcon} alt="floorplan" />
                    <p>{t("floorplan")} </p>
                    <span>
                      {
                        singlePropertyDetails?.image_list?.filter(
                          (val) => val.type == "FLOORPLAN"
                        ).length
                      }
                    </span>
                  </div>
                )}
                {singlePropertyDetails?.image_list?.filter(
                  (val) => val.type == "VIDEO"
                ).length > 0 && (
                  <div
                    className={classes.Content}
                    onClick={() => {
                      const navigationDetails = generateListingPhotoURL(
                        singlePropertyDetails,
                        {
                          name: "VIDEO",
                        },
                        true
                      );

                      if (navigationDetails) {
                        navigate(navigationDetails.url, {
                          state: navigationDetails.state,
                        });
                      }
                    }}
                  >
                    <img src={VideoIcon} alt="video" />
                    <p>{t("video")} </p>
                    <span>
                      {
                        singlePropertyDetails?.image_list?.filter(
                          (val) => val.type == "VIDEO"
                        ).length
                      }
                    </span>
                  </div>
                )}
                {singlePropertyDetails?.image_list?.filter(
                  (val) => val.type == "BROCHURE"
                ).length > 0 && (
                  <div
                    className={classes.Content}
                    onClick={downloadBrochureHandler}
                  >
                    <img src={DownloadIcon} alt="download brochure" />
                    <p>{t("download_brochure")} </p>
                    <span>
                      {
                        singlePropertyDetails?.image_list?.filter(
                          (val) => val.type == "BROCHURE"
                        ).length
                      }
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.ListingDetailsSection}>
              <div className={classes.ListingDetail}>
                <h1>
                  {singlePropertyDetails?.address
                    ? singlePropertyDetails?.address
                    : `${singlePropertyDetails?.street} ${singlePropertyDetails?.housenumber}`}
                </h1>
                <p>
                  {singlePropertyDetails?.postcode}{" "}
                  {singlePropertyDetails?.city}
                </p>
                <div className={classes.PropertySize}>
                  {Number(singlePropertyDetails?.livable_area) > 0 && (
                    <div className={classes.Size}>
                      <img src={HomeSizeIcon} alt="livable area" />
                      <p>
                        {singlePropertyDetails?.livable_area} m<sup>2</sup>
                      </p>
                    </div>
                  )}
                  {singlePropertyDetails?.no_bedroom >= 1 && (
                    <div className={classes.Capacity}>
                      <img src={BedIson} alt="bedroom" />
                      <p>{singlePropertyDetails?.no_bedroom}</p>
                    </div>
                  )}
                  <div className={classes.SquareMeterIcon}>
                    <img src={SquareMeterIcon} alt="home plot area" />
                    <p>{singlePropertyDetails?.home_plot_area}</p>
                  </div>
                </div>
                <div className={classes.PriceWarp}>
                  <p>
                    {displayPrice(
                      singlePropertyDetails?.is_sell,
                      singlePropertyDetails?.is_rent,
                      singlePropertyDetails?.rent_price,
                      singlePropertyDetails?.rent_postfix,
                      singlePropertyDetails?.sell_price,
                      singlePropertyDetails?.sell_postfix,
                      t
                    )}
                  </p>
                </div>
                {singlePropertyDetails?.energy_class != "undefined" &&
                  singlePropertyDetails?.energy_class != "" && (
                    <div
                      style={{
                        backgroundColor: ENERGY_LABEL.filter(
                          (val) =>
                            val.name == singlePropertyDetails?.energy_class
                        )[0]?.color,
                      }}
                      className={classes.lable}
                    >
                      {singlePropertyDetails?.energy_class}
                    </div>
                  )}
                {singlePropertyDetails?.quote && (
                  <div className={classes.NoteWrap}>
                    <p>{singlePropertyDetails?.quote}</p>
                  </div>
                )}
              </div>

              <div className={classes.mainOrgWrap}>
                {singlePropertyDetails?.agency_detail && (
                  <div className={classes.ContactWrap}>
                    <div
                      className={classes.OrgMainWrap}
                      onClick={() => {
                        navigate(
                          `/view-organisation/${singlePropertyDetails?.agency_detail?.city
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(
                              /[^\w-]+/g,
                              ""
                            )}/${singlePropertyDetails?.agency_detail?.name
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, "")}/${
                            singlePropertyDetails?.agency_detail?.id
                          }`
                        );
                      }}
                    >
                      {singlePropertyDetails?.agency_detail && (
                        <div className={classes.OrgMgWrap}>
                          <img
                            src={
                              singlePropertyDetails?.agency_detail?.logo_image
                                ? singlePropertyDetails?.agency_detail
                                    ?.logo_image
                                : defaultImage
                            }
                          />
                        </div>
                      )}
                    </div>

                    {singlePropertyDetails?.agency_detail && (
                      <div className={classes.OrgContent}>
                        <h5>{singlePropertyDetails?.agency_detail?.name}</h5>
                        <div className={classes.linkWrap}>
                          <a
                            href={`tel:${singlePropertyDetails?.agency_detail?.phone}`}
                          >
                            {singlePropertyDetails?.agency_detail?.phone}
                          </a>
                          <a
                            href={`mailto:${singlePropertyDetails?.agency_detail?.email}`}
                          >
                            {singlePropertyDetails?.agency_detail?.email}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {(singlePropertyDetails?.user_detail?.full_name ||
                  singlePropertyDetails?.phone ||
                  singlePropertyDetails?.email) && (
                  <div className={classes.ContactDetails}>
                    <p>
                      {singlePropertyDetails?.user_detail?.full_name
                        ? singlePropertyDetails?.user_detail?.full_name
                        : ""}
                    </p>
                    <div className={classes.UserInfoWrap}>
                      {singlePropertyDetails?.phone && (
                        <img src={ContactIcon} alt="Phone" />
                      )}
                      <a href={`tel:${singlePropertyDetails?.phone}`}>
                        {singlePropertyDetails?.phone}
                      </a>
                    </div>
                    <div className={classes.UserInfoWrap}>
                      {singlePropertyDetails?.email && (
                        <img src={EmainIcon} alt="Email" />
                      )}
                      <a href={`mailto:${singlePropertyDetails?.email}`}>
                        {singlePropertyDetails?.email}
                      </a>
                    </div>
                  </div>
                )}
              </div>

              <div className={classes.MapSection}>
                <iframe
                  src={
                    singlePropertyDetails?.latitude
                      ? `https://maps.google.com/maps?q=${singlePropertyDetails?.latitude},${singlePropertyDetails?.longitude}&t=&z=17&ie=UTF8&iwloc=&output=embed`
                      : `https://maps.google.com/maps?q=${singlePropertyDetails?.address}&t=&z=17&ie=UTF8&iwloc=&output=embed`
                  }
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </div>
          </MainContainer>
          <SendContactAndDisplayAds />
          <MainContainer>
            <div className={classes.SubListingDetailSection}>
              <div className={classes.DescriptionAndContactWrap}>
                <div className={classes.DescriptionWrap}>
                  <h6>{t("description")}</h6>
                  {isReadMore ? (
                    <div
                      className={classes.Content}
                      dangerouslySetInnerHTML={{
                        __html: singlePropertyDetails?.description?.slice(
                          0,
                          300
                        ),
                      }}
                    ></div>
                  ) : (
                    <div className={classes.Content}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: singlePropertyDetails?.description,
                        }}
                      ></p>
                    </div>
                  )}
                  {singlePropertyDetails?.description?.length > 300 && (
                    <p onClick={toggleReadMore} className={classes.ReadOrHide}>
                      <img src={descriptionArrowIcon} alt="description" />
                      &nbsp;
                      {isReadMore ? t("read_full_description") : t("show_less")}
                    </p>
                  )}
                </div>
                <div className={classes.FeatureContainer}>
                  <h6>{t("features")}</h6>
                  <div className={classes.MainFeaturesWrap}>
                    <div
                      className={classes.LeftFeatureWrap}
                      id="LeftFeatureWrap"
                    >
                      <div className={classes.contentWrap}>
                        <FeaturesDiv Array={TRANSFER} name={t("transfer")} />
                      </div>
                      <div className={classes.contentWrap}>
                        <FeaturesDiv
                          Array={CONSTRUCTION}
                          name={t("construction")}
                        />
                      </div>
                      <div className={classes.contentWrap}>
                        <FeaturesDiv
                          Array={BUILDING_AREA}
                          name={t("building_area")}
                        />
                      </div>
                      <div className={classes.contentWrap}>
                        <FeaturesDiv
                          Array={OUTSIDE_AREA}
                          name={t("outside_area")}
                        />
                      </div>
                    </div>
                    <div
                      className={classes.RightFeatureWrap}
                      id="RightFeatureWrap"
                    >
                      <div className={classes.contentWrap}>
                        <FeaturesDiv
                          Array={CLASSIFICATION}
                          name={t("classifications")}
                        />
                      </div>
                      <div className={classes.contentWrap}>
                        <FeaturesDiv
                          Array={ENERGY_DETAILS}
                          name={t("energy_details")}
                        />
                      </div>
                      <div className={classes.contentWrap}>
                        <FeaturesDiv Array={GARAGE} name={t("garage")} />
                      </div>
                      <div className={classes.contentWrap}>
                        <FeaturesDiv Array={PARKING} name={t("parking")} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classes.CollapseFeatures}
                onClick={() => {
                  setHeight();
                  toggleCollapse();
                }}
              >
                <img
                  src={isCollapse ? descriptionArrowIcon : uparrowIcon}
                  alt="description"
                />
                &nbsp;
                <span>
                  {isCollapse ? t("show_all_features") : t("collapse_features")}
                </span>
              </div>

              {localStorage.getItem("user_type") &&
                localStorage.getItem("user_type").toLowerCase() === "admin" && (
                  <div className={classes.ActivitySection}>
                    <h6>{t("activity")}</h6>
                    <div className={classes.ActivityWrap}>
                      <div className={classes.ListingActivity}>
                        <img src={ListingViews} />
                        <p> X {singlePropertyDetails?.view_count}</p>
                      </div>
                      <div className={classes.ListingActivity}>
                        <img src={ListingFav} alt="Favourite count" />
                        <p> X {singlePropertyDetails?.favourite_count}</p>
                      </div>
                      <div className={classes.ListingActivity}>
                        <img src={ListingDate} alt="Listing date" />
                        <p>
                          {" "}
                          {moment(singlePropertyDetails?.available_date).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className={classes.Goback}>
                      <span onClick={() => window.history.back()}>
                        {"<"} {t("goback")}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          </MainContainer>
        </div>
      ) : (
        <PageNotFoundMain />
      )}
    </>
  );
};
export default ViewListingMain;
