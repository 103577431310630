import moment from "moment";
const fromDateMap = {
  "Since 3 days": moment().subtract(3, "days").format("YYYY-MM-DD"),
  "Since 5 days": moment().subtract(5, "days").format("YYYY-MM-DD"),
  "Since 10 days": moment().subtract(10, "days").format("YYYY-MM-DD"),
  "No preference": "",
  "Sinds 3 dagen": moment().subtract(3, "days").format("YYYY-MM-DD"),
  "Sinds 5 dagen": moment().subtract(5, "days").format("YYYY-MM-DD"),
  "Sinds 10 dagen": moment().subtract(10, "days").format("YYYY-MM-DD"),
  "Geen voorkeur": "",
};
export default fromDateMap;
