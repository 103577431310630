import React, { useState, useEffect, useRef } from "react";
import classes from "./mylistingmain.module.scss";
import AccountMain from "../layouts/Account";
import AuthLayout from "../layouts/AuthLayout";
import PropertyCardMain from "../layouts/PropertyCardDesign";
import ListingFilters from "../layouts/ListingFilters";
import OffcanvasMain from "../layouts/Offcanvas";
import { MdPersonOutline } from "react-icons/md";
import { MdFilterAlt } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  actionApprovedOrganisationsApiCall,
  actionMyListingApiCall,
} from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "../layouts/MainContainer";
import Loader from "../Loader";
const MyListingMain = () => {
  const [account, setAccount] = useState(false);
  const [filter, setFilter] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CommonReducer.loading);
  const count = useSelector(
    (state) => state.CommonReducer.myPropertyListingData?.count
  );
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    params.get("page") ? params.get("page") : 1
  );
  const [pageNumber, setPageNumber] = useState();
  const [activePage, setActivePage] = useState(
    params.get("page") ? params.get("page") : 1
  );
  const [myListingFilter, setMyListingFilter] = useState({
    listingType: params.get("listingType") ? params.get("listingType") : "",
    isPrivateListing: params.get("isPrivateListing")
      ? params.get("isPrivateListing")
      : "",
    minPrice: params.get("minPrice") ? params.get("minPrice") : "",
    maxPrice: params.get("maxPrice") ? params.get("maxPrice") : "",
  });
  const approvedOrganisationData = useSelector(
    (state) => state.CommonReducer.approvedOrganisationData
  );

  const myRef = useRef();
  const ITEMS_PER_PAGE = 10;
  const PLATFORM = localStorage.getItem("Platform");
  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const pageNumbers = [];
    let totalPages = Math.ceil(count / ITEMS_PER_PAGE);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    setTotalPages(totalPages);
    setPageNumber(pageNumbers);
  }, [count]);
  const accountShow = () => {
    setAccount(true);
  };
  const filterShow = () => {
    setFilter(true);
  };
  const handleClose = () => {
    setAccount(false);
    setFilter(false);
  };
  const changeToDefaultPage = () => {
    setCurrentPage(1);
    setActivePage(1);
  };
  const ListingApiCall = () => {
    let data = {
      page: currentPage,
      per_page: 10,
      minPrice: myListingFilter.minPrice,
      maxPrice: myListingFilter.maxPrice,
      listingType: myListingFilter.listingType,
      isPrivateListing:
        typeof myListingFilter.isPrivateListing !== ""
          ? myListingFilter.isPrivateListing
          : "",
    };
    let body = { data, navigate };
    dispatch(actionMyListingApiCall(body));
    const queryParams = [
      `page=${currentPage}`,
      params.get("minPrice") ? `minPrice=${params.get("minPrice")}` : "",
      params.get("maxPrice") ? `maxPrice=${params.get("maxPrice")}` : "",
      params.get("listingType")
        ? `listingType=${params.get("listingType")}`
        : "",
      params.get("isPrivateListing")
        ? `isPrivateListing=${params.get("isPrivateListing")}`
        : "",
      params.get("orgListing") ? `orgListing=${params.get("orgListing")}` : "",
    ];
    const queryString = queryParams.filter(Boolean).join("&");
    navigate(`/my-listing?${queryString}`);
  };
  useEffect(() => {
    if (screenWidth <= 768) {
      if (!filter) {
        ListingApiCall();
      }
    }
  }, [currentPage]);
  useEffect(() => {
    let org = { navigate };
    dispatch(actionApprovedOrganisationsApiCall(org));
  }, []);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
  };
  const pagination = () => {
    let ui = [];
    for (
      let i = activePage <= 4 ? 1 : activePage - 4;
      i <= (activePage <= 4 ? 8 : activePage + 4);
      i++
    ) {
      if (i <= pageNumber.length) {
        ui.push(
          <div className={classes.page}>
            <span
              onClick={() => {
                handlePageChange(i);
              }}
              className={activePage == i ? classes.Active : classes.InActive}
            >
              {i}
            </span>
          </div>
        );
      }
    }
    myRef?.current?.scrollIntoView({ behavior: "smooth" });
    return ui;
  };
  return (
    <React.Fragment>
      <AuthLayout>
        <MainContainer>
          <div className={classes.MyLsitingSection}>
            <div className={classes.MyListingWrap}>
              <div className={classes.LeftSide}>
                {screenWidth > 768 && (
                  <div className={classes.AccountMainWrap}>
                    <AccountMain />
                  </div>
                )}
                {screenWidth > 768 && (
                  <div className={classes.AccountMainWrap}>
                    <div className={classes.AddListingBtn}>
                      <button

                        onClick={() => {
                          navigate("/add-listing");
                        }}
                        className={
                          `${classes.BtnActive} ${classes.CommonButtonStyles}`
                        }
                      >
                        {t("add_listing")}
                      </button>
                    </div>
                  </div>
                )}
                {screenWidth > 768 && (
                  <div className={classes.AccountMainWrap}>
                    <ListingFilters
                      currentPage={currentPage}
                      changeToDefaultPage={changeToDefaultPage}
                    />
                  </div>
                )}
                <div className={classes.BtnWrap}>
                  <div className={classes.BtnSection}>
                    <button onClick={accountShow}>
                      <MdPersonOutline />
                      {t("my_account")}
                    </button>
                    <button onClick={filterShow}>
                      <MdFilterAlt />
                      {t("filter")}
                    </button>
                  </div>
                  <div className={classes.AddListingBtn}>
                    <button

                      onClick={() => {
                        navigate("/add-listing");
                      }}
                      className={
                        `${classes.BtnActive} ${classes.CommonButtonStyles}`
                      }
                    >
                      {t("add_listing")}
                    </button>
                  </div>
                </div>
              </div>
              <div className={classes.Rightside} ref={myRef}>
                <div className={classes.LsitingSection}>
                  <div className={classes.TitleWrap}>
                    <h1>{t("my_listings")}</h1>
                    <p>
                      {t("show")} {ITEMS_PER_PAGE} {t("results_per_page")}
                    </p>
                  </div>
                  {loading === true ? (
                    <div className={classes.LoaderWrap}>
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <PropertyCardMain />
                      {!loading && (
                        <div className={classes.Pagination}>
                          <div className={classes.PageWrap}>
                            {currentPage > 1 && currentPage <= totalPages && (
                              <div
                                className={
                                  activePage !== 1
                                    ? classes.NextPageActive
                                    : classes.NextPageInActive
                                }
                                onClick={() => {
                                  if (activePage !== 1) {
                                    setCurrentPage(currentPage - 1);
                                    setActivePage(currentPage - 1);
                                  }
                                }}
                              >
                                {"<"} <span>{t("prev_page")}</span>
                              </div>
                            )}
                            {pageNumber?.length >= 1 && (
                              <span className={classes.pageText}>
                                {t("page")}
                              </span>
                            )}
                            {pageNumber?.length > 0 && pagination()}
                            {pageNumber?.length > 8 && (
                              <div className={classes.page}>
                                {" "}
                                <span className={classes.pageText}>
                                  {t("of")}
                                </span>
                                <span
                                  onClick={() => {
                                    handlePageChange(pageNumber.length);
                                  }}
                                  className={
                                    activePage === pageNumber.length
                                      ? classes.Active
                                      : classes.InActive
                                  }
                                >
                                  {pageNumber.length}
                                </span>
                              </div>
                            )}
                          </div>
                          {currentPage < totalPages && (
                            <div
                              className={
                                activePage !== pageNumber.length - 1
                                  ? classes.NextPageActive
                                  : classes.NextPageInActive
                              }
                              onClick={() => {
                                if (activePage !== pageNumber.length) {
                                  setCurrentPage(+currentPage + 1);
                                  setActivePage(+currentPage + 1);
                                }
                              }}
                            >
                              <span>{t("next_page")}</span> {">"}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <OffcanvasMain showcanvas={account} handleClose={handleClose}>
              <AccountMain />
            </OffcanvasMain>
            <OffcanvasMain
              showcanvas={filter}
              handleClose={handleClose}
              title="Filters"
            >
              <ListingFilters
                currentPage={currentPage}
                changeToDefaultPage={changeToDefaultPage}
              />
            </OffcanvasMain>
          </div>
        </MainContainer>
      </AuthLayout>
    </React.Fragment>
  );
};
export default MyListingMain;
