import React from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import MyUpdateBannerLayout from "../layouts/MyUpdateBannerLayout";
import classes from "./editbannermain.module.scss";

const EditBannerMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.EditBannerContainer}>
          <div className={classes.EditBannerHeading}>
            <h1>{t("edit_banner")}</h1>
          </div>
          <MyUpdateBannerLayout />
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default EditBannerMain;
