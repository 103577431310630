import React, { useState } from "react";
import classes from "./createorgaccountmainone.module.scss";
import AuthLayout from "../layouts/AuthLayout";
import MainContainer from "../layouts/MainContainer";
import { useTranslation } from "react-i18next";
import Validate from "../../utils/Validation";
import { actionGetEmployeeJobTitleApiCall } from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { actionCreateOrgAccountWithoutLoginApiCall } from "../../Redux/Actions";

const CreateOrgAccountMainOne = (props) => {
  const { t } = useTranslation();
  const [organisationDetails, setOrganisationDetails] = useState({
    name: "",
    address: "",
    postcode: "",
    city: "",
    website: "",
    fullName: "",
    jobTitle: "",
    contactEmail: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const platform = localStorage.getItem("Platform");

  const [error, setError] = useState();
  const [file, setFile] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [bannerFile, setBannerFile] = useState();
  const [bannerImageUrl, setBannerImageUrl] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saveEmployeeJobTitle = useSelector(
    (state) => state.CommonReducer.saveEmployeeJobTitle
  );
  const loading = useSelector((state) => state.CommonReducer.loading);
  useEffect(() => {
    dispatch(actionGetEmployeeJobTitleApiCall({ navigate }));
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setOrganisationDetails({
      ...organisationDetails,
      [name]: value,
    });

    let error;
    if (name == "password") {
      error = Validate(
        name,
        value,
        false,
        {},
        organisationDetails.confirmPassword
      );
      setError(error);
    } else if (name == "confirmPassword") {
      error = Validate(name, value, false, {}, organisationDetails.password);
      setError(error);
    } else {
      error = Validate(name, value);
      setError(error);
    }
  };
  const handleFileChange = (e) => {
    const src = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setImageUrl(src);
    let error = Validate("upload", e.target.files[0]);
    setError(error);
  };

  const handleBannerFileChange = (e) => {
    const src = URL.createObjectURL(e.target.files[0]);
    setBannerFile(e.target.files[0]);
    setBannerImageUrl(src);
    let error = Validate("banner", e.target.files[0]);
    setError(error);
  };

  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  const AddOrganisationClickHandler = (e) => {
    e.preventDefault();
    let validateData = {
      name: organisationDetails.name,
      address: organisationDetails.address,
      postcode: organisationDetails.postcode,
      city: organisationDetails.city,
      website: organisationDetails.website,
      contactName: organisationDetails.fullName,
      jobTitle: organisationDetails.jobTitle,
      contactEmail: organisationDetails.contactEmail,
      phone: organisationDetails.phone,
      email: organisationDetails.email,
      password: organisationDetails.password,
      confirmPassword: organisationDetails.confirmPassword,
    };
    let error = Validate("", "", true, validateData);
    setError(error);
    if (Object.values(error).every((x) => x == null || x == "")) {
      let formData = new FormData();
      formData.append("org_name", organisationDetails.name);
      formData.append("contact_email", organisationDetails.contactEmail);
      formData.append("address", organisationDetails.address);
      formData.append("city", organisationDetails.city);
      formData.append("postcode", organisationDetails.postcode);
      formData.append("website", organisationDetails.website);
      formData.append("phone", organisationDetails.phone);
      formData.append("full_name", organisationDetails.fullName);
      formData.append("job_title", organisationDetails.jobTitle);
      formData.append("login_email", organisationDetails.email);
      formData.append("password", organisationDetails.password);
      formData.append("confirm_password", organisationDetails.confirmPassword);
      // formData.append("import_id", "");
      let data = {
        id: props.id,
        productId: props.productId,
        discountExtraId: props.discountExtraId,
      };
      let body = { formData, data, navigate };
      dispatch(actionCreateOrgAccountWithoutLoginApiCall(body));
    }
  };

  return (
    <React.Fragment>
      <div className={classes.CreateOrgAccountMain}>
        <h1>{t("subscription_form")}</h1>
        <div className={classes.FormTab}>
          <form>
            <div className={classes.SectionPart}>
              <h3>{t("organisation")}</h3>
              <div className={classes.InputFildBox}>
                <p>{t("name_of_organisation")}</p>
                <input
                  type="text"
                  name="name"
                  value={organisationDetails.name}
                  onChange={handleChange}
                  maxLength={100}
                  placeholder={t("placeholder_of_name_of_organisation")}
                />
                {error?.name && (
                  <span className={`${classes.errorText}`}>{error.name}</span>
                )}
              </div>
              <div className={classes.InputFildBox}>
                <p>{t("address")}</p>
                <input
                  type="text"
                  name="address"
                  value={organisationDetails.address}
                  onChange={handleChange}
                  placeholder={t("placeholder_of_address")}
                />
                {error?.address && (
                  <span className={`${classes.errorText}`}>
                    {error.address}
                  </span>
                )}
              </div>
              <div className={classes.flexInput}>
                <div className={classes.InputFildBox}>
                  <p>{t("postal_code")}</p>
                  <input
                    type="text"
                    name="postcode"
                    value={organisationDetails.postcode}
                    onChange={handleChange}
                    maxLength={6}
                    placeholder={t("placeholder_of_postal_code")}
                  />
                  {error?.postcode && (
                    <span className={`${classes.errorText}`}>
                      {error.postcode}
                    </span>
                  )}
                </div>
                <div className={classes.InputFildBox}>
                  <p>{t("city")}</p>
                  <input
                    type="text"
                    name="city"
                    value={organisationDetails.city}
                    onChange={handleChange}
                    placeholder={t("placeholder_of_city")}
                  />
                  {error?.city && (
                    <span className={`${classes.errorText}`}>{error.city}</span>
                  )}
                </div>
              </div>
              <div className={classes.InputFildBox}>
                <p>{t("website")}</p>
                <input
                  type="text"
                  name="website"
                  value={organisationDetails.website}
                  onChange={handleChange}
                  placeholder={t("placeholder_of_website")}
                />
                {error?.website && (
                  <span className={`${classes.errorText}`}>
                    {error.website}
                  </span>
                )}
              </div>
            </div>
            <div className={classes.SectionPart}>
              <h3>{t("primary_contact")}</h3>
              <div className={classes.InputFildBox}>
                <p>{t("full_name")}</p>
                <input
                  type="text"
                  name="fullName"
                  value={organisationDetails.fullName}
                  onChange={handleChange}
                  placeholder={t("full_name")}
                />
                {error?.contactName && (
                  <span className={`${classes.errorText}`}>
                    {error.contactName}
                  </span>
                )}
              </div>
              {platform != "PROD" && (
                <div className={classes.InputFildBox}>
                  <p>{t("job_title")}</p>
                  <select
                    placeholder={t("salesperson")}
                    name="jobTitle"
                    value={organisationDetails.jobTitle}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      {`${t("select")}`}
                    </option>
                    {saveEmployeeJobTitle?.length > 0 &&
                      saveEmployeeJobTitle.map((option) => (
                        <option key={option.id} value={option?.value}>
                          {option?.translation}
                        </option>
                      ))}
                  </select>
                  {error?.jobTitle && (
                    <span className={`${classes.errorText}`}>
                      {error.jobTitle}
                    </span>
                  )}
                </div>
              )}
              <div className={classes.InputFildBox}>
                <p>{t("contact_e_mail")}</p>
                <input
                  type="text"
                  name="contactEmail"
                  value={organisationDetails.contactEmail}
                  onChange={handleChange}
                  placeholder={t(
                    "placeholder_of_contact_email_of_organisation"
                  )}
                />
                {error?.contactEmail && (
                  <span className={`${classes.errorText}`}>
                    {error.contactEmail}
                  </span>
                )}
              </div>
              <div className={classes.InputFildBox}>
                <p>{t("phonenumber")}</p>
                <input
                  type="text"
                  name="phone"
                  value={organisationDetails.phone}
                  onChange={handleChange}
                  maxLength="10"
                  minLength="8"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={handleKeyPressForNumber}
                  placeholder={t("placeholder_of_phonenumber")}
                />
                {error?.phone && (
                  <span className={`${classes.errorText}`}>{error.phone}</span>
                )}
              </div>
            </div>
            <div className={classes.SectionPart}>
              <h3>{t("login")}</h3>
              <div className={classes.InputFildBox}>
                <p>
                  {t("login")}({t("e_mail")})
                </p>
                <input
                  type="email"
                  name="email"
                  value={organisationDetails.email}
                  onChange={handleChange}
                  placeholder={t(
                    "placeholder_of_contact_email_of_organisation"
                  )}
                />
                {error?.email && (
                  <span className={`${classes.errorText}`}>{error.email}</span>
                )}
              </div>
              <div className={classes.InputFildBox}>
                <p>
                  {t("password")}({t("password_condition")})
                </p>
                <input
                  type="password"
                  name="password"
                  value={organisationDetails.password}
                  onChange={handleChange}
                  placeholder={t("password")}
                />
                {error?.password && (
                  <span className={`${classes.errorText}`}>
                    {error.password}
                  </span>
                )}
              </div>
              <div className={classes.InputFildBox}>
                <p>{t("retype_password")}</p>
                <input
                  type="password"
                  name="confirmPassword"
                  value={organisationDetails.confirmPassword}
                  onChange={handleChange}
                  placeholder={t("retype_password")}
                />
                {error?.confirmPassword && (
                  <span className={`${classes.errorText}`}>
                    {error.confirmPassword}
                  </span>
                )}
              </div>
            </div>
            <p>{t("mail_receive")}</p>
            <button
              onClick={AddOrganisationClickHandler}
              disabled={props.id ? false : true}
              className={props.id ? classes.ActivePayNow : classes.PayNow}
            >
              {t("pay_now")}
              {/* {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>{t("pay_now")}</>
              )} */}
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateOrgAccountMainOne;
