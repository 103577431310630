import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import CreateOrgAccountMainTwo from "../../components/CreateOrgAccountMainTwo";
import { useLocation } from "react-router-dom";
import CreateOrganisationAccountMainOne from "../../components/CreateOrganisationAccountMainOne";

const CreateOrganisationAccountPage = () => {
  const location = useLocation();
  return (
    <React.Fragment>
      <Header />
      {location.pathname == "/create-organisation-account-step1" && (
        <CreateOrganisationAccountMainOne />
      )}
      {location.pathname.includes("/create-org-account-step2") && (
        <CreateOrgAccountMainTwo />
      )}
      <Footer />
    </React.Fragment>
  );
};

export default CreateOrganisationAccountPage;
