import React from "react";
import classes from "./errormain.module.scss";
import ErrorImg from "../../assets/images/ErroeImg.svg";
import { useNavigate } from "react-router-dom";

const ErrorMain = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className={classes.ErroeMain}>
        <h1>404!</h1>
        <div className={classes.ErrorImgWrap}>
          <img src={ErrorImg} alt="Error" />
        </div>
        <div className={classes.ContentWrap}>
          <h4>
            <span>Opps!</span> - PAGE NOT FOUND
          </h4>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <button onClick={() => navigate("/home")}>GO TO HOMEPAGE</button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ErrorMain;
