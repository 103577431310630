import React from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import classes from "./accountcreatedmain.module.scss";

const AccountCreatedMain = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <form className={classes.ForgotSection}>
        <div className={classes.ForgotContaint}>
          <div className={classes.ForgotHeading}>
            <h2>Account created</h2>
          </div>
          <div className={classes.MainReceiveTect}>
            <p>You will receive an e-mail!</p>
          </div>
          <p>
            Thank you for creating an account on woningmarkt.nl. You will
            receive an e-mail shortly with an activation-link.
          </p>
          <div className={classes.AccountCreatBtnWrap}>
            <button
              className={classes.AccountCreatBtn}
              onClick={(e) => {
                e.preventDefault();
                navigate("/home");
              }}
            >
              Start browsing
            </button>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default AccountCreatedMain;
