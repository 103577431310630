import React from 'react';
export const DropDownSelector = React.forwardRef((props, ref) => {
    return (
        <>
            <div>
                <p>{props.heading}</p>
                <select
                    type="text"
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    className={props.className ? props.className : ''}
                    ref={ref}
                    style={{ width: props.width }}
                >
                    <option disabled value="">
                        {props.heading}
                    </option>
                    {props?.data?.length > 0 &&
                        props.data.map((item, index) => {
                            return <option key={index} value={props.name == "city" ? item.id : item.name}>
                                {item.name ? item.name : (item.translation ? item.translation : 'Empty Value')}
                            </option>;
                        })}
                </select>
            </div>
        </>

    );
});