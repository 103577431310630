import React, { useEffect } from "react";
import classes from "./membershipmain.module.scss";
import TemplateLayout from "../layouts/TemplateLayout";
import { useTranslation } from "react-i18next";
import SubscriptionCardWrapMain from "../common/SubscriptionCardWrapMain";
import CreateOrgAccountMainOne from "../CreateOrgAccountMainOne";
import { useState } from "react";
import {
  actionCreateUserSubscriptionApiCall,
  actionGetHomeBannerApiCall,
  actionSelfOrganisationListingForSubscriptionApiCall,
} from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubscriptionSignup from "../SubscriptionSignup";

const MembershipMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [id, setId] = useState();
  const [productId, setProductId] = useState();
  const [discountExtraId, setDiscountExtraID] = useState();

  const [membershipBanner, setMembershipBanner] = useState();
  const [selectedOrganisation, SetSelectedOrganisation] = useState();

  const SetDescription = (data) => {
    return setData(data);
  };
  const saveMembershipBanner = useSelector(
    (state) => state.CommonReducer.saveHomeBanner
  );
  const saveSelfOrganisationforSubscription = useSelector(
    (state) => state.CommonReducer.saveSelfOrganisationforSubscription
  );
  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      // if (localStorage.getItem("orgId")) {
      //   localStorage.removeItem("orgId");
      // }
      if (localStorage.getItem("user_id")) {
        localStorage.removeItem("user_id");
      }
    }
    let data = localStorage.getItem("user_id");
    let body = { data, navigate };
    dispatch(actionGetHomeBannerApiCall({ navigate }));
    dispatch(actionSelfOrganisationListingForSubscriptionApiCall(body));
  }, []);

  useEffect(() => {
    saveMembershipBanner?.rows?.length > 0 &&
      saveMembershipBanner?.rows?.find((item, index) => {
        if (item.position === "Membership") {
          setMembershipBanner(item.url_original_file);
        }
      });
  }, [saveMembershipBanner]);

  const DataHandler = (optionId, productId, discountId) => {
    setId(optionId);
    setProductId(productId);
    setDiscountExtraID(discountId);
  };

  const paymentHandler = () => {
    const data = {
      product_id: productId,
      product_payment_option_id: id,
      user_id: Number(localStorage.getItem("user_id")),
      discount_and_extra_id: discountExtraId,
      agency_id: Number(selectedOrganisation),
    };
    const body = { data, navigate };
    dispatch(actionCreateUserSubscriptionApiCall(body));
  };
  return (
    <React.Fragment>
      <TemplateLayout
        title={t("membership")}
        image={membershipBanner}
        bgImage="true"
      >
        <div className={classes.MembershipSection}>
          <div className={classes.SubscriptionOverview}>
            <h1>{t("subscription_overview")}</h1>
            <p className={classes.SelectRunTimeText}>{t("select_runtime")}</p>
            <span>{t("make_a_choice")}</span>
            <SubscriptionCardWrapMain
              SetDescription={SetDescription}
              DataHandler={DataHandler}
            />
            {/* <p
              dangerouslySetInnerHTML={{
                __html: data,
              }}
            > */}
            {/* {t(
                "Nam egestas lorem eu est pellentesque luctus. Aliquam erat volutpat. Sed sed lacus placerat, pulvinar nisl ac, euismod diam. Suspendisse mi arcu, congue sed mollis in, mattis eget elit. In faucibus egestas tincidunt. Donec aliquam justo velit. Sed mollis tempor lectus et interdum."
              )} */}
            {/* </p> */}
          </div>
          {/* <div className={classes.SubscriptionForm}>
            <h1>{t("subscription_form")}</h1>
            <form>
              <div className={classes.InputFildBox}>
                <p>{t("name_of_organisation")}</p>
                <input type="text" />
              </div>
              <div className={classes.InputFildBox}>
                <p>{t("address")}</p>
                <input type="text" />
              </div>
              <div className={classes.flexInput}>
                <div className={classes.InputFildBox}>
                  <p>{t("postal_code")}</p>
                  <input type="text" />
                </div>
                <div className={classes.InputFildBox}>
                  <p>{t("city")}</p>
                  <input type="text" />
                </div>
              </div>
              <div className={classes.InputFildBox}>
                <p>{t("website")}</p>
                <input type="text" />
              </div>
              <div className={classes.flexInput}>
                <div className={classes.InputFildBox}>
                  <p>{t("phonenumber")}</p>
                  <input type="text" />
                </div>
                <div className={classes.InputFildBox}>
                  <p>{t("primary_contact")}</p>
                  <input type="text" />
                </div>
              </div>
              <div className={classes.InputFildBox}>
                <p>{t("contact_e-mail_of_organisation")}</p>
                <input type="email" />
              </div>
              <div className={classes.InputFildBox}>
                <p>{t("company_software")}</p>
                <select>
                  <option></option>
                </select>
              </div>
              <div className={classes.InputFildBox}>
                <p>{t("what_other_company_software?")}</p>
                <input type="text" />
              </div>
              <button>{t("pay_now")}</button>
            </form>
          </div> */}
          {localStorage.getItem("authToken") && (
            <div className={classes.selectorg}>
              <p>
                {t("select")} {t("organisation").toLocaleLowerCase()}
              </p>
              <select
                name="selectedOrganisation"
                value={selectedOrganisation}
                onChange={(e) => SetSelectedOrganisation(e.target.value)}
              >
                <option value="" disabled selected>
                  {`${t("select")}`}
                </option>
                {saveSelfOrganisationforSubscription?.length > 0 &&
                  saveSelfOrganisationforSubscription.map((option) => (
                    <option key={option.id} value={option?.id}>
                      {option?.name}
                    </option>
                  ))}
                {/* <option>sdsadwdw</option>
                <option>eretrewte</option> */}
              </select>
            </div>
          )}
          {localStorage.getItem("authToken") ? (
            <button
              className={
                id && selectedOrganisation
                  ? classes.PayNowActive
                  : classes.PayNow
              }
              onClick={paymentHandler}
              disabled={id && selectedOrganisation ? false : true}
            >
              {t("pay_now")}
            </button>
          ) : (
            <CreateOrgAccountMainOne
              id={id}
              productId={productId}
              discountExtraId={discountExtraId}
            />
            // <SubscriptionSignup
            //   id={id}
            //   productId={productId}
            //   discountExtraId={discountExtraId}
            // />
          )}
        </div>
      </TemplateLayout>
    </React.Fragment>
  );
};

export default MembershipMain;
